import {Vue, Component, Prop, ModelSync} from 'vue-property-decorator';
import RecordModel from '@/models/record.model';
import ProjectModel from '@/models/project.model';
import Identity from '@/modules/sdk/core/identity';
import ProjectStatusReasonModel from '@/models/project-status-reason.model';
import { useStatusStore } from '@/stores/statusStore';

export interface IStatus {
  status: string,
  color: string,
  loading: boolean,
}

@Component
export default class StatusMixin extends Vue {

  @ModelSync('vModel', 'change', { type: RecordModel }) record!: RecordModel;
  @Prop({ type: ProjectModel, default: () => new ProjectModel() }) project!: ProjectModel;
  @Prop({ default: () => 'screening' }) readonly stage!: 'screening' | 'indepth' | 'final' | string;
  @Prop({ default: () => false }) disabled!: boolean;
  @Prop() userType?: string;

  recordStatusList: Array<IStatus> = [
    {status: 'pass', color: 'success', loading: false},
    {status: 'pending', color: 'warning', loading: false},
    {status: 'halt', color: 'error', loading: false},
  ];

  statusReasonModal: {
    visible: boolean,
    reasons: ProjectStatusReasonModel[] | null,
    recordStatus: IStatus,
    stage: string | null,
  } = {
    visible: false,
    reasons: null,
    stage: null,
    recordStatus: this.recordStatusList[2],
  }

  get changeStatusLabel(): ['warning' | 'success' | 'info', string] {
    const reasons: {[key: string]: ['warning' | 'success' | 'info', string]} = {
      disabled: ['warning', 'This field is disabled.'],
      unknown: ['warning', 'Can\'t change because of unknown reason.'],
      userMode: ['warning', 'The current user mode is not allowed to change status.'],
      stage: ['info', 'The project stage is already completed.'],
      alreadyReviewed: ['success', 'Someone with the same mode already reviewed.'],
      ownerStatus: ['success', 'The current user has already reviewed the record with a similar user mode.'],
      ownerNotArbitrator: ['warning', 'The current user has already reviewed the record with another user mode and is not an arbitrator.'],
    }
    const valid = this.canChangeStatusReason;
    return reasons[valid === true ? 'unknown' : valid];
  }

  get canChangeStatus(): boolean {
    return this.canChangeStatusReason === true;
  }

  /**
   * User Can Review if:
   * - is primary researcher + and another primary researcher hasn't reviewed
   * - is secondary researcher + another secondary researcher hasn't reviewed
   * - is arbitrator + arbitrator hasn't reviewed
   * - if himself has set a review status, he can change its own status
   * - if the current stage equal the project state
   * - if current user has already reviewed with a similar user mode
   *   - else if current user has already reviewed with a *different* status and is *not* arbitrator
   */
  get canChangeStatusReason(): string | true {

    const userList = useStatusStore().userList;

    if (this.disabled) {
      return 'disabled';
    }

    if (!['researcher', 'secondary-researcher', 'arbitrator'].includes(this.userType || '')) {
      return 'userMode';
    }

    if (this.stage !== this.project.data.stage) {
      return 'stage';
    }

    const currentUserAndModeAlreadyAppliedStatus = !!(userList.find(user => user.data.id === Identity.getCurrentUserId() && user.data.userType === this.userType));
    if (currentUserAndModeAlreadyAppliedStatus) {
      return true;
    }
    else {
      const currentUserDifferentModeAlreadyAppliedStatus = !!(userList.find(user => user.data.id === Identity.getCurrentUserId() && user.data.userType !== this.userType));
      if (currentUserDifferentModeAlreadyAppliedStatus && this.userType !== 'arbitrator') {
        return 'ownerNotArbitrator';
      }
    }

    const alreadyHasPrimaryResearcherReview = !!(userList.find(user => user.data.id !== Identity.getCurrentUserId() && user.data.userType === 'researcher' && this.userType === 'researcher' && user.data.type === this.stage));
    const alreadyHasSecondaryResearcherReview = !!(userList.find(user => user.data.id !== Identity.getCurrentUserId() && user.data.userType === 'secondary-researcher' && this.userType === 'secondary-researcher' && user.data.type === this.stage));
    const alreadyHasArbitratorReview = !!(userList.find(user => user.data.id !== Identity.getCurrentUserId() && user.data.userType === 'arbitrator' && this.userType === 'arbitrator' && user.data.type === this.stage));
    if (
      alreadyHasPrimaryResearcherReview
      || alreadyHasSecondaryResearcherReview
      || alreadyHasArbitratorReview
    ) {
      return 'alreadyReviewed';
    }

    if (userList.length === 0) {
      return true;
    }

    return true;
  }

}
