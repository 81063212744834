<template>
  <v-row>

    <!-- SUGGESTIONS DIALOG -->
    <KeywordLookupModal
      v-model="suggestionsDialog.visible"
      :keyword="_model"
      :default-query="suggestionsDialog.query"
      :default-color="defaultColor"
    />

    <!-- PROJECT -->
    <v-col cols="12" v-if="!$route.params.projectId">
      <v-autocomplete
        v-model="_model.data.projectId"
        :error-messages="parentFormErrors.projectId"
        :loading="loadingProjects"
        :search-input="projectListSearch"
        :items="projectList"
        label="Project"
        item-text="data.label"
        item-value="data.id"
        hide-details="auto"
        outlined
        clearable
        @input="delete parentFormErrors.projectId"
      ></v-autocomplete>
    </v-col>

    <!-- CATEGORY -->
    <v-col cols="12">

      <KeywordChip
        ref="currentChip"
        v-model="_model"
        class="d-none"
        filled
      />

      <v-combobox
        v-model="_model.data.label"
        :error-messages="parentFormErrors.label"
        :rules="[_rules.required]"
        :items="categoryList"
        :loading="loadingCategoryList"
        label="Keyword Category"
        type="text"
        hide-details="auto"
        outlined
        clearable
        required
        @input="delete parentFormErrors.label"
      />
      <div class="d-flex align-center mt-2" style="gap: 0.5rem">
        <v-btn small outlined @click="onLookupClick(_model.data.label)">
          <v-icon left>mdi-magnify</v-icon>
          <span>Lookup</span>
        </v-btn>
        <v-btn small outlined @click="onParameterClick(_model)">
          <v-icon :color="_model.data.color" left>mdi-palette</v-icon>
          <span>Parameters</span>
        </v-btn>
        <v-btn :loading="importingSynonyms" :disabled="importingSynonyms" small outlined @click="importKeywords(_model)">
          <v-icon left>mdi-database-import</v-icon>
          <span>Import keywords</span>
        </v-btn>
      </div>
    </v-col>

    <!-- SYNONYMS -->
    <v-col cols="12">
      <v-combobox
        v-model="synonymList"
        :hide-no-data="!synonymSearch"
        :items="getSelectSynonymList()"
        :search-input.sync="synonymSearch"
        :error-messages="parentFormErrors.synonymlist"
        :rules="[]"
        item-text="data.label"
        item-color="data.color"
        label="Keywords"
        hide-details="auto"
        hint="do not remove"
        persistent-hint
        outlined
        counter
        multiple
        clearable
        chips
        deletable-chips
        hide-selected
        return-object
        @input="delete parentFormErrors.synonymlist"
      >
        <template v-if="!parentFormErrors.synonymlist" #message>
          Please <b class="px-1" style="background-color: yellow">use lowercase</b> when entering new keywords, and reserve uppercase only for cases where it's truly necessary, such as acronyms, abbreviations, proper names, or other specific instances that require it.
        </template>
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <KeywordChip
            v-if="item instanceof SynonymModel"
            v-show="item.data.label"
            v-bind="attrs"
            :ref="'synonym_' + item.data.id"
            :value="item"
            :input-value="selected"
            :auto-edit="autoEditId === item.data.id"
            removable
            editable
            filled
            @remove="parent.selectItem(item)"
          />
        </template>
      </v-combobox>
    </v-col>

    <!-- SEND TO AI -->
    <v-col cols="12">
      <v-checkbox
        v-model="_model.data.sendToAi"
        label="Send to AI"
        hide-details="auto"
        class="ma-0 pa-0"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Prop, PropSync, Watch, VModel, Emit } from 'vue-property-decorator';
import KeywordChip from '@/components/KeywordChip.vue';
import KeywordLookupModal from '@/components/KeywordLookupModal.vue';
import SynonymModel from '@/models/synonym.model';
import KeywordModel from '@/models/keyword.model';
import KeywordService from '@/services/keyword.service';
import ProjectService from '@/services/project.service';
import Service from '@/modules/sdk/core/service';
import Rules from '@/modules/sdk/core/rules';

let projectListSearchTimeout: any;

@Component({
  components: {
    KeywordLookupModal,
    KeywordChip: () => import('@/components/KeywordChip.vue'),
  }
})
export default class KeywordFormInner extends Vue {

  @VModel({ default: () => new KeywordModel() }) model!: KeywordModel
  @Prop({ type: Number, default: null }) id!: number
  @Prop({ type: Number, default: null }) projectId!: number
  @Prop({ type: Boolean, default: false }) autoAddSynonym!: boolean
  @Prop({ type: [Number, Boolean] }) autoEditId!: number | null | boolean | undefined
  @Prop({ type: Boolean, default: true }) detailed!: boolean
  @Prop({ type: Service, default: null }) service!: Service
  @Prop({ type: Object, default: () => ({}) }) parentFormErrors!: any
  @PropSync('rules', { type: Object, default: () => ({
    required: (value: string) => Rules.required(value) || 'This field is required',
  }) }) _rules!: any

  suggestionsDialog: {
    visible: boolean,
    query: string | null,
  } = {
    visible: false,
    query: null,
  };

  loading = false;
  loadingProjects = false;
  synonymSearch = '';
  SynonymModel = SynonymModel;
  projectList = [];
  projectListSearch = '';
  defaultColor = '#E0E0E0FF';
  loadingCategoryList = false;
  importingSynonyms = false;
  categoryList: Array<any> = []

  @Watch('loading')
  onLoadingChanged(loading: boolean) {
    this.$emit('loading', loading);
  }

  @Watch('model.data.projectId')
  onProjectIdChange(projectId: number) {
    for (const model of this._model.data.synonymlist) {
      model.data.projectId = projectId;
    }
  }

  search(search: string | null = this.projectListSearch) {
    clearTimeout(projectListSearchTimeout);
    projectListSearchTimeout = setTimeout(() => {
      this.loadingProjects = true;
      ProjectService.getInstance().getAll({ search })
        .then(response => this.projectList = response.data.view.list)
        .finally(() => this.loadingProjects = false);
    }, 500);
  }

  @Emit()
  async load(id: number) {
    this.loading = true;
    return this.service.get({id})
      .then(response => this._model = response.data.view.single)
      .then(response => {
        this.init();
        return response;
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  @Emit()
  init() {
    if (this.autoAddSynonym) {
      this.autoEditId = null;
    }
    if (this.autoEditId || this.autoEditId === null) {
      const autoEditItem = this.synonymList.find(synonym => synonym.data.id === this.autoEditId);
      if (autoEditItem) {
        setTimeout(() => {
          const ref = (this.$refs['synonym_' + autoEditItem.data.id] as KeywordChip);
          if (ref) {
            ref.editItem(ref._model, 'parameters');
          }
        }, 600)
      }
    }
  }

  get _model(): KeywordModel {
    return this.model;
  }

  set _model(model: KeywordModel) {
    this.$emit('input', model);
  }

  get synonymList(): Array<SynonymModel> {
    return this._model.data.synonymlist.filter((model: SynonymModel) => !model.data.deleted);
  }

  set synonymList(synonymList: Array<SynonymModel>) {

    // prepare array
    if (!Array.isArray(this._model.data.synonymlist)) {
      this._model.data.synonymlist = [];
    }

    // merge models or strings to list
    for (const index in synonymList) {

      // transform strings into models
      if (typeof synonymList[index] === 'string') {
        synonymList[index] = new SynonymModel({
          label: synonymList[index],
          projectId: this._model.data.projectId,
          color: this._model.data.color || this.defaultColor,
          caseSensitive: this._model.data.caseSensitive,
          wordOnly: this._model.data.wordOnly,
          regexp: this._model.data.regexp,
          deleted: 0,
        });
      }

      // Vue.js transforming our model to an observable object
      if (!(synonymList[index] instanceof SynonymModel) && synonymList[index].data) {
        synonymList[index] = new SynonymModel(synonymList[index].data);
      }

      // merge or add the model
      if (synonymList[index] instanceof SynonymModel) {
        const model = synonymList[index];
        model.data.deleted = 0;
        model.assignToArrayByProperty(this._model.data.synonymlist, {label: model.data.label});
      }
    }

    // keep intersect only
    this._model.data.synonymlist.map((model: SynonymModel) => {
      if (!synonymList.some((model2: SynonymModel) => model.data.label === model2.data.label)) {
        model.data.deleted = 1;
      }
      return model;
    });
  }

  onLookupClick(query: string): void {
    Object.assign(this.suggestionsDialog, {
      visible: true,
      query,
    });
  }

  onParameterClick(model: KeywordModel | SynonymModel): void {
    const keywordChip = (this.$refs.currentChip as KeywordChip);
    if (keywordChip) {
      keywordChip.editItem(model, 'parameters');
    }
  }

  importKeywords(model: KeywordModel) {
    this.importingSynonyms = true;
    KeywordService.getInstance().getAll({
      filters: [{
        field: 'label',
        operator: 'equals',
        value: model.data.label,
      }, {
        field: 'deleted',
        operator: 'not equal',
        value: '1',
      }]
    })
      .then(response => {
        let someAdded = false;
        response.data.view.list.forEach((keyword: KeywordModel) => {
          keyword.data.synonymlist.forEach((synonym: SynonymModel) => {
            const found = this._model.data.synonymlist.find((item: SynonymModel) => item.data.label === synonym.data.label);
            if (!found) {
              this._model.data.synonymlist.push(synonym);
              someAdded = true;
            } else {
              found.data.deleted = 0;
            }
          })
        })
        if (someAdded) {
          this.$root.$globalSnack.success({
            message: 'New synonyms has been found and added to your keywords!'
          });
        } else {
          this.$root.$globalSnack.info({
            message: 'No new synonyms has been found.'
          });
        }
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.importingSynonyms = false);
  }

  getSelectSynonymList(): Array<SynonymModel> {
    return this.model.data.synonymlist?.filter((model: SynonymModel) => !!model.data.deleted);
  }

  loadCategoryList() {
    this.loadingCategoryList = true;
    KeywordService.getInstance().getAll({
      filters: [{field: 'deleted', value: 1, operator: 'does not equal'}],
      group: 'label', // Distinct
      order: 'label ASC',
    })
      .then(response => this.categoryList = response.data.view.list.map((item: any) => item.data.label))
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loadingCategoryList = false);
  }

  created() {
    if (this.id) {
      this.load(this.id).then(() => this.search());
    } else {
      this.search();
    }
    this.init();
    this.loadCategoryList();
  }
}
</script>
