<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :search-input.sync="query"
    :items="_items"
    :loading="loading || internalLoading"
    :disabled="(loading && !loaded) || internalLoading"
    placeholder="Select a project"
    label="Project"
    ref="projectRef"
    item-text="data.label"
    item-value="data.id"
    cache-items
    deletable-chips
    @update:search-input="onSearchInputUpdate"
  >
    <template #append>

      <!-- PROJECT INFORMATION -->
      <v-btn
        v-if="project"
        text
        small
        style="margin-top: -0.15rem"
        @mousedown.stop="showDetails = true"
      >
        <v-icon left>mdi-book-information-variant</v-icon>
        <span>Info</span>
      </v-btn>

      <ModalDialog
        v-if="project"
        v-model="showDetails"
        :popup-communicator-route="{ name: 'MultitaskingProjectInformation', params: { id: project.data.id } }"
        :popup-communicator-props="{ project }"
        id="project_information"
        title="Project Information"
        icon="mdi-information-outline"
        max-width="1000"
        background-color="backgroundVeryLight"
        scrollable
        draggable
      >
        <template #body>
          <ProjectInformationInner
            v-model="project"
            class="pt-4"
          />
        </template>
        <template #buttons>
          <v-btn
            outlined
            large
            @click="showDetails = false"
          >
            Close
          </v-btn>
        </template>
      </ModalDialog>
    </template>

    <template #item="{ item }">
      <div class="w-100 d-flex align-center justify-space-between" style="gap: 1rem; height: 60px">
        <v-icon style="flex: 0">mdi-book-outline</v-icon>
        <div style="flex: 1">
          <div>{{ item.data.label }}</div>
          <div class="caption text--disabled">Created at: {{ item.data.createdAt }}</div>
        </div>

        <!-- STAGE -->
        <v-chip class="ma-2" label small>
          {{ $t('stage.' + item.data.stage) }}
        </v-chip>
      </div>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Ref, PropSync, Prop } from 'vue-property-decorator';
import { stageList } from '@/enums/global';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import ProjectService from '@/services/project.service';
import ProjectModel from '@/models/project.model';
import ProjectInformationInner from '@/components/ProjectInformationInner.vue';
// @ts-ignore
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete/VAutocomplete'
import Identity from '@/modules/sdk/core/identity';

let projectSearchTimeout: any;

@Component({
  components: {
    ProjectInformationInner,
    ModalDialog
  },
})
export default class ProjectAutocomplete extends Vue {

  @Ref() readonly projectRef!: VAutocomplete;
  @Prop({ type: Boolean, default: false }) skipLoading!: boolean;
  @Prop({ type: Boolean, default: false }) autoFocus!: boolean;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: Array, default: [] }) filters!: Array<any>;
  @PropSync('items', { default: () => ([]) }) _items!: Array<ProjectModel>;

  query = null;
  internalLoading = false;
  loaded = false;
  stageList = stageList
  showDetails = false;

  get project(): ProjectModel | null {
    return this._items.find(item => item.data.id === this.$attrs.value) || null;
  }

  onSearchInputUpdate(query: string) {
    this.search(query);
  }

  search(query: string, timeout = 1000) {
    clearTimeout(projectSearchTimeout);
    projectSearchTimeout = setTimeout(() => {

      if (this._items.find(item => item.data.label === query)) {
        return;
      }
      this.internalLoading = true;

      const filters = this.filters || [];

      filters.push({
        field: 'UserNode.userId',
        operator: 'equals',
        value: Identity.getCurrentUserId(),
      }, {
        field: 'UserNode.type',
        operator: 'not in',
        value: ['ai-manager', 'leader'],
      })

      // cache-items will not load the first item for pre-selected project id
      if (this.projectRef && this.projectRef.value) {
        filters.push({
          field: 'id',
          operator: 'equals',
          value: this.projectRef.value
        });
      }

      ProjectService.getInstance().getAll({ search: query, filters, order: 'createdAt DESC', })
        .then(response => {
          if (!this.loaded && this.projectRef) {
            if (this.autoFocus) {
              this.projectRef.isMenuActive = true;
              this.projectRef.isFocused = true;
            }
            this.loaded = true;
          }
          this.$emit('load', response.data.view.list);
          this._items = response.data.view.list;
        })
        .finally(() => this.internalLoading = false);
    }, timeout);
  }

  created() {
    this.search('', 0);
  }
}
</script>
