import Model from '@/modules/sdk/core/model';

export default class AiPromptModel extends Model {

  columnCast() {
    return {
      isDefault: 'bool',
    };
  }

  getActionLabel() {
    return this.data.action?.replace('_', ' ');
  }

  getProjectTypeLabel() {
    return this.data.projectType?.replace('_', ' ');
  }

}
