<template>
  <v-container fluid class="pa-4" id="fullscreen-wrapper">

    <!-- TOOLBAR ITEMS -->
    <portal to="toolbar_right" :order="2">
      <MenuTooltip
        :menu-attrs="{ closeOnContentClick: false }"
        :btn-attrs="{ text: true }"
        btn-text="Context"
        chevron-down
      >
        <v-card style="width: 20rem">
          <v-card-text>
            <v-checkbox
              v-model="keepViewState"
              label="Keep view state in memory"
              class="ma-0 pa-0"
              hide-details
            />
          </v-card-text>
        </v-card>
      </MenuTooltip>
    </portal>

    <!-- PROJECT SELECTOR -->
    <v-card class="w-100 mb-3 fullscreen-hide">
      <v-card-text>
        <div class="d-flex align-start pt-1 pb-1" style="gap: 1rem; position: relative">
          <ProjectAutocomplete
            v-model="projectId"
            :items.sync="projectList"
            :loading="loadingProject"
            :filters="[{field: 'status', operator: 'equals', value: ['new', 'progress']}]"
            :rules="[loadingProject || !projectId || !!mode || 'You must choose a mode first']"
            :auto-focus="!showModeSelection"
            :persistent-hint="loadingProject"
            v-test-id="'project_selector'"
            hint="Loading selected project..."
            hide-details="auto"
            outlined
            clearable
            @change="onProjectChange"
            @load="onProjectLoad"
          />

          <v-autocomplete
            v-if="showModeSelection"
            v-model="mode"
            :label="$t('reviewView.mode')"
            :items="modeList.filter(item => item.value !== 'ai-manager' && item.value !== 'leader')"
            :rules="[!!mode || '']"
            v-test-id="'mode_selector'"
            ref="modeRef"
            auto-focus
            prepend-inner-icon="mdi-account"
            style="max-width: 20rem"
            hide-details="auto"
            outlined
            return-object
            @input="onModeChange"
          />
        </div>
      </v-card-text>
    </v-card>

    <!-- FILTERS: ADVANCED SEARCH -->
    <v-expansion-panels
      v-if="ready"
      v-model="panelList"
      v-test-id="'review_panels'"
      class="header-not-clickable fullscreen-hide"
      multiple
      readonly
      accordion
    >
      <v-expansion-panel v-show="showFilters" v-test-id="'review_panel_filters'">
        <v-expansion-panel-header ref="filters_panel" expand-icon="mdi-menu-down">
          <div class="d-flex align-center justify-space-between w-100" style="gap: 1rem">
            <div class="d-flex align-center" style="flex: 1; gap: 1rem">
              <div class="text-truncate">
                <span>Advanced Search Builder</span>
              </div>

              <v-tooltip bottom>
                <template #activator="{ attrs, on }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    v-model="queryLocked"
                    :color="queryLocked ? 'warning' : null"
                    icon
                    @click="queryLocked = !queryLocked"
                  >
                    <v-icon>mdi-lock</v-icon>
                  </v-btn>
                </template>
                <span v-if="queryLocked">Unlock to modify query</span>
                <span v-else>Lock query</span>
              </v-tooltip>

              <v-btn
                v-test-id="'review_clear_query_builder'"
                :disabled="isAdvancedQueryBuilderPristine || queryLocked"
                small
                outlined
                @click="clearAdvancedQueryBuilder"
              >
                <v-icon small left>mdi-filter-off</v-icon>
                <span>Clear</span>
              </v-btn>
            </div>

            <PresetManager
              ref="presetManager"
              v-if="projectId"
              :value="filterList"
              :default-item="defaultPresetItem"
              :loading="loadingPresets"
              :saving="savingPreset"
              :default-item-args="{
                projectId,
              }"
              :load-callback="loadPresets"
              :save-callback="savePresets"
              :remove-callback="removePreset"
              :disabled="queryLocked"
              id="review_filters"
              label="Presets"
              hide-details="auto"
              outlined
              dense
              clearable
              style="width: 25rem; max-width: 35rem"
              class="mr-4"
              @input="onApplyPresetFilters"
            />
          </div>

          <template #actions>
            <v-btn icon @click="onExpansionPanelHeaderClick('filters')">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>

          <!-- BUILDER -->
          <v-card color="backgroundVeryLight" class="pa-4 mb-4" flat>
            <v-row>
              <v-col cols="12">
                <ProjectQueryBuilder
                  v-if="ready"
                  ref="builderComponent"
                  v-model="filterList"
                  :project-id="projectId"
                  :mode="mode"
                  :advanced="advanced"
                  :project="currentProject"
                  :keywords="keywordList"
                  :disabled="queryLocked"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- QUERY -->
          <v-form v-model="formQueryValid">

            <!-- QUERY FORM -->
            <v-row>
              <v-col cols="12" md="4" lg="2">
                <v-autocomplete
                  v-model="advanced.stage"
                  :items="filteredStageList"
                  :rules="[rules.required]"
                  hide-details="auto"
                  label="Stage"
                  outlined
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" lg="2" style="position: relative">
                <v-autocomplete
                  v-model="advanced.status"
                  :items="filteredStatusList"
                  :disabled="!advanced.stage"
                  :label="getOfficialStatusStageLabel()"
                  hide-details="auto"
                  outlined
                  dense
                  clearable
                  disable-lookup
                >
                  <template #item="{item}">
                    <span class="text-truncate">
                      <v-icon :color="item.color" small left>mdi-circle</v-icon>
                      <span class="text-truncate">{{ item.text }}</span>
                    </span>
                  </template>
                  <template #selection="{item}">
                    <span class="text-truncate">
                      <v-icon :color="item.color" small left>mdi-circle</v-icon>
                      <span class="text-truncate">{{ item.text }}</span>
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-autocomplete
                  v-model="advanced.reviewed"
                  :items="reviewedByList"
                  :disabled="!advanced.stage"
                  :label="getReviewedByStageLabel()"
                  hide-details="auto"
                  outlined
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="9" lg="3">
                <v-autocomplete
                  v-model="advanced.order"
                  :items="orderList"
                  label="Sort by"
                  hide-details="auto"
                  outlined
                  dense
                  clearable
                  multiple
                >
                  <template #selection="{ item, index }">
                    <div :tick="tick" class="d-flex align-center mr-3" style="gap: 0.5rem">
                      <span>{{ item.text }}</span>
                      <v-btn outlined x-small @click.stop="onToggleSort(index)">
                        <v-icon v-if="advanced.orderAsc[index]" small>mdi-sort-ascending</v-icon>
                        <v-icon v-else small>mdi-sort-descending</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" lg="2">
                <v-combobox
                  v-model="limit"
                  :items="limitList"
                  label="Limit"
                  hide-details="auto"
                  outlined
                  dense
                  clearable
                >
                </v-combobox>
              </v-col>
            </v-row>

            <ExportComponent
              v-if="currentProject"
              v-model="currentProject"
              :visible.sync="exportCsvModal.visible"
              :loading="exportCsvModal.loading"
              :user-type="currentMode || ''"
              ref="exportComponent"
              @export="exportCsv"
            />

            <!-- ACTION BUTTONS -->
            <div class="d-flex flex-wrap align-center mt-4" style="gap: 1rem">
              <v-btn
                v-test-id="'review_show_records'"
                :disabled="!canSearch"
                color="primary"
                style="flex: 2"
                @click="timeoutCallback(() => search())"
              >
                <v-icon left>
                  mdi-magnify
                </v-icon>
                Search
              </v-btn>

              <v-btn
                v-if="currentMode === 'arbitrator'"
                v-test-id="'review_export'"
                :disabled="!formQueryValid || recordListLoading"
                color="default"
                outlined
                style="flex: 2"
                @click="timeoutCallback(() => exportComponent.open())"
              >
                <v-icon left>
                  mdi-export
                </v-icon>
                Export
              </v-btn>
            </div>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card v-if="showTabs && loadedTabIdx[tab]">

      <!-- TABS -->
      <v-tabs v-model="tab" class="mt-3 fullscreen-hide" grow>
        <v-tab>
          <v-icon left>mdi-database-outline</v-icon>
          Records
        </v-tab>
        <v-tab>
          <v-icon left>mdi-chart-bar-stacked</v-icon>
          Metrics
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" style="overflow: visible"> <!-- overflow fix for Sticky -->

        <!-- RECORDS -->
        <v-tab-item transition="none">
          <v-expansion-panels
            v-if="ready"
            v-model="recordPanelList"
            v-test-id="'review_panels'"
            class="header-not-clickable"
            multiple
            readonly
            accordion
          >
            <!-- KEYWORDS -->
            <v-expansion-panel v-show="showKeywords" v-test-id="'review_panel_keywords'" class="fullscreen-hide">
              <v-expansion-panel-header ref="keywords_panel" :color="isPanelOpen('keywords', recordPanelList) ? 'backgroundVeryLight' : null" expand-icon="mdi-menu-down">
                <div class="d-flex align-center justify-space-between mr-3" style="gap: 1rem">
                  Keywords ({{ availableKeywordList.filter(keyword => keyword.data.id).length }})

                  <div class="d-flex align-center" style="gap: 0.5rem">
                    <v-btn
                      v-if="availableKeywordList.length > 1"
                      text
                      small
                      @click="onSelectAllKeywordsClick"
                    >
                      <v-icon left>
                        <template v-if="availableKeywordAllSelected">mdi-checkbox-marked-outline</template>
                        <template v-else>mdi-checkbox-blank-outline</template>
                      </v-icon>
                      <span v-text="$t('btn.selectAll')"></span>
                    </v-btn>

                    <v-btn text small @click="onAddKeywordClick">
                      <v-icon left>mdi-plus</v-icon>
                      <span>Add new keyword</span>
                    </v-btn>
                  </div>
                </div>
                <template #actions>
                  <v-btn icon @click="onExpansionPanelHeaderClick('keywords')">
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager><!-- NEEDS TO BE EAGER LOADED BECAUSE OF onAddKeywordClick -->
                <div class="text-center">
                  <v-progress-linear
                    v-if="keywordLoading"
                    color="primary"
                    indeterminate
                  ></v-progress-linear>
                  <v-alert
                    v-else-if="!hasKeywords"
                    border="bottom"
                    colored-border
                    type="info"
                    color="primary"
                    elevation="2"
                  >
                    No keyword found
                  </v-alert>
                </div>
                <v-row v-if="hasKeywords" no-gutters>
                  <v-col cols="12" class="d-flex align-center flex-wrap" style="gap: 0.5rem">
                    <KeywordChip
                      v-model="availableKeywordList[keywordIdx]"
                      v-for="(keyword, keywordIdx) in availableKeywordList"
                      :ref="'keyword_' + keywordIdx"
                      :key="keyword.data.id"
                      :class="{
                        'd-none': !keyword.data.id
                      }"
                      selectable
                      editable
                      deletable
                      detailed
                      @apply="onApplyKeyword"
                      @cancel="() => !keyword.data.id && availableKeywordList.splice(keywordIdx, 1)"
                      @deleted="() => availableKeywordList.splice(keywordIdx, 1)"
                    />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" class="backgroundVeryLight lighten-4 pa-4">
                    <div class="d-flex align-center justify-space-between mb-2">
                      <v-subheader style="height: auto" class="pl-0">Synonyms</v-subheader>

                      <v-menu offset-y max-height="300">
                        <template #activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            text
                            small
                          >
                            <span>Add new synonym</span>
                            <v-icon right>mdi-chevron-down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(keyword, keywordIdx) in availableKeywordList.filter(item => item.data.id)"
                            :key="keywordIdx"
                            @click="() => onAddSynonymClick(keyword, keywordIdx)"
                          >
                            <v-list-item-title>{{ keyword.data.label }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <v-divider class="mb-4" />
                    <div class="d-flex align-center flex-wrap" style="gap: 0.5rem">
                      <template v-for="(keyword, keywordIdx) in availableKeywordList">
                        <template v-for="(synonym, synonymIdx) in keyword.data.synonymlist">
                          <KeywordChip
                            v-show="!synonym.data.deleted"
                            v-model="availableKeywordList[keywordIdx]"
                            :synonym="keyword.data.synonymlist[synonymIdx]"
                            :key="synonym.autoIncrementId"
                            :ref="'keyword_' + keyword.data.id + '_' + synonymIdx"
                            :class="{
                            'd-none': !synonym.data.id
                          }"
                            selectable
                            editable
                            deletable
                            detailed
                            @apply="onApplyKeyword"
                            @cancel="() => !synonym.data.id && keyword.data.synonymlist.splice(synonymIdx, 1)"
                            @deleted="() => keyword.data.synonymlist.splice(synonymIdx, 1)"
                          />
                        </template>
                      </template>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- OPTIONS -->
            <v-expansion-panel v-show="showOptions" v-test-id="'review_panel_options'" class="fullscreen-hide">
              <v-expansion-panel-header ref="options_panel" :color="isPanelOpen('options', recordPanelList) ? 'backgroundVeryLight' : null" expand-icon="mdi-menu-down">
                Options

                <template #actions>
                  <v-btn icon @click="onExpansionPanelHeaderClick('options')">
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>

                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="recordOptions.recordFields"
                      :items="recordFieldList"
                      :label="$t('reviewView.recordFieldList')"
                      :filled="recordOptions.recordFields.length > 0"
                      hide-details="auto"
                      multiple
                      clearable
                      chips
                      deletable-chips
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="toggleRecordFields"
                        >
                          <v-list-item-action>
                            <v-icon :color="recordOptions.recordFields.length > 0 ? 'indigo darken-4' : ''">
                              {{ recordFieldsIcon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Select All
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <div class="d-flex align-center flex-wrap mt-6" style="gap: 3rem">
                  <v-switch
                    class="mt-0"
                    v-model="recordOptions.highlightKeywords"
                    inset
                    label="Highlight keywords"
                    hide-details="auto"
                  ></v-switch>
                  <v-switch
                    class="mt-0"
                    v-model="recordOptions.highlightFilters"
                    inset
                    label="Highlight filters"
                    hide-details="auto"
                  ></v-switch>
                  <v-switch
                    class="mt-0"
                    v-model="recordOptions.splitSection"
                    inset
                    label="Split sections"
                    hide-details="auto"
                  ></v-switch>
                  <v-switch
                    class="mt-0"
                    v-model="recordOptions.addSpaces"
                    inset
                    label="Add spaces"
                    hide-details="auto"
                  ></v-switch>
                  <v-switch
                    class="mt-0"
                    v-model="recordOptions.removeUseless"
                    inset
                    label="Remove useless"
                    hide-details="auto"
                  ></v-switch>
                </div>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- DATA -->
          <v-card v-if="currentProject" v-test-id="'review_panel_records'" v-show="showRecords" class="fullscreen-keep" tile flat>

            <project-progress-component
              ref="projectProgressComponent"
              :stage="projectStage"
              :type="percentageType"
              :project-id="projectId"
              :user-id="userId"
              :user-mode="currentMode"
              :filters="submittedFilters"
              :advanced="submittedAdvanced"
              :limit="getLimit()"
            />

            <v-card-title ref="records_panel">
              <div class="d-flex align-center" style="gap: 0.5rem; flex: 1">
                <v-btn
                  v-if="canCreateRecord()"
                  :disabled="!createRecordIsActive()"
                  outlined
                  small
                  @click="onCreateRecordClick"
                >
                  <v-icon left>mdi-plus</v-icon>
                  <span v-text="$t('recordComponent.addRecord')"></span>
                </v-btn>
                <span v-else>Records</span>
              </div>

              <!-- PAGINATION -->
              <div class="d-flex align-center justify-center text-body-1" style="flex: 2">
                <v-tooltip bottom>
                  <template #activator="{ attrs, on }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!canGoFirst"
                      fab
                      small
                      text
                      @click="firstPage"
                    >
                      <v-icon>mdi-chevron-double-left</v-icon>
                    </v-btn>
                  </template>
                  <span>First</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ attrs, on }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!canGoPrevious"
                      fab
                      small
                      text
                      @click="previousPage"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                  </template>
                  <span>Previous</span>
                </v-tooltip>

                <span class="mx-4 grey--text d-flex align-center" style="gap: 0.5rem">
                      Page
                      <v-text-field
                        v-model.number="recordListPagePagination"
                        :rules="[rules.minMaxPage]"
                        :disabled="recordListLoading"
                        dense
                        outlined
                        hide-details
                        type="number"
                        class="text-center"
                        style="max-width: 5rem"
                        @input="onPageInput"
                      />
                      of {{ numberOfPages }}
                    </span>

                <v-tooltip bottom>
                  <template #activator="{ attrs, on }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!canGoNext"
                      fab
                      small
                      text
                      @click="nextPage"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </template>
                  <span>Next</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ attrs, on }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!canGoLast"
                      fab
                      small
                      text
                      @click="lastPage"
                    >
                      <v-icon>mdi-chevron-double-right</v-icon>
                    </v-btn>
                  </template>
                  <span>Last</span>
                </v-tooltip>
              </div>

              <div class="d-flex align-center justify-end pr-3" style="gap: 1rem; flex: 1">

                <!-- MASS ASSIGN TO PILOT -->
                <template v-if="canMassAssignUser">
                  <v-btn
                    :disabled="!advanced.stage"
                    outlined
                    small
                    @click="onMassAssignClick"
                  >
                    <v-icon left>mdi-account-star</v-icon>
                    Mass assign to pilot
                  </v-btn>

                  <ModalDialog
                    v-model="massAssignToUserListVisible"
                    title="Mass assign to pilot"
                    icon="mdi-account-star"
                    max-width="450"
                    persistent
                    scrollable
                  >
                    <template #body>
                      <div
                        v-for="(type, typeIdx) in primSec"
                        :key="type"
                        :class="{
                          'mt-3': typeIdx > 0
                        }"
                      >
                        <h3 class="mb-3">{{ keyedUserTypeList[type] }}(s):</h3>
                        <ListBuilder
                          v-model="selectedMassAssignTo[type]"
                          :default-model="defaultMassAssignTo"
                          :default-data="defaultMassAssignToData"
                          :can-add="selectedMassAssignTo[type].length < 2"
                          group="mass_assign_primary"
                          hide-empty
                          dense
                          @input="() => applyMassAssignPercentage(selectedMassAssignTo[type].length === 2 ? 50 : 100, 0, selectedMassAssignTo[type])"
                        >
                          <template #item="props">
                            <div class="d-flex align-center mb-2" style="gap: 0.5rem">
                              <v-select
                                v-model="props.item.data.userentity"
                                :items="getMassAssignUserList(type, props.index)"
                                :style="{
                                  maxWidth: selectedMassAssignTo[type].length > 1 ? '250px' : null
                                }"
                                item-text="fullName"
                                label="User"
                                prepend-inner-icon="mdi-account"
                                dense
                                outlined
                                hide-details
                                clearable
                                return-object
                                @input="user => onAssignMassUserInput(user, type, props.index)"
                              />
                              <v-text-field
                                v-if="selectedMassAssignTo[type].length > 1"
                                v-model.number="props.item.data.percentage"
                                type="number"
                                suffix="%"
                                style="min-width: 6rem; max-width: 6rem"
                                class="ml-2"
                                hide-details
                                outlined
                                dense
                                @input="value => applyMassAssignPercentage(value, props.index, selectedMassAssignTo[type])"
                                @blur="() => applyMassAssignPercentage(props.item.data.percentage, props.index, selectedMassAssignTo[type])"
                              />
                            </div>
                          </template>
                        </ListBuilder>
                      </div>

                      <v-switch
                        v-model="overwriteAssignation"
                        label="Overwrite existing assignation"
                        hide-details
                        inset
                      />
                    </template>
                    <template #buttons>
                      <v-btn
                        large
                        color="primary"
                        :loading="massAssigningToPilot"
                        :disabled="!canClickMassAssign"
                        @click="onMassAssignRecordClick"
                      >
                        <span>Mass assign</span>
                      </v-btn>
                      <v-btn
                        large
                        text
                        :disabled="massAssigningToPilot"
                        @click="massAssignToUserListVisible = false"
                      >
                        <span v-text="$t('btn.cancel')"></span>
                      </v-btn>
                    </template>
                  </ModalDialog>

                  <v-divider inset vertical class="mx-4" />
                </template>

                <!-- PERCENTAGE TOGGLE -->
                <v-btn-toggle v-model="percentageTypeIdx" color="primary">
                  <v-btn :disabled="recordListLoading" x-small>% {{ percentageTypes[0] }}</v-btn>
                  <v-btn :disabled="recordListLoading" x-small>% {{ percentageTypes[1] }}</v-btn>
                </v-btn-toggle>

                <div class="d-flex align-center" style="gap: 0.5rem">

                  <!-- FULLSCREEN -->
                  <fullscreen-component />
                </div>
              </div>
            </v-card-title>
            <div>
              <div class="text-center">
                <v-progress-linear
                  v-if="!recordListLoaded || (recordListLoading && !hasRecords)"
                  color="primary"
                  indeterminate
                ></v-progress-linear>
                <div v-else-if="!hasRecords" class="pa-4">
                  <v-alert
                    outlined
                    type="info"
                    color="warning"
                    class="mb-0"
                  >
                    No record found
                  </v-alert>
                </div>
              </div>

              <div tabindex="0">
                <v-data-iterator
                  v-if="hasRecords"
                  :items="recordList"
                  :page="recordListPage"
                  :loading="recordListLoading"
                  :items-per-page="itemsPerPage"
                  item-key="data.id"
                  hide-default-footer
                >
                  <template #default="{ items }">
                    <v-container fluid>
                      <v-row class="background">
                        <v-progress-linear
                          v-if="recordListLoading"
                          color="primary"
                          indeterminate
                        ></v-progress-linear>
                        <v-col
                          v-for="record in items"
                          :key="record.data.id"
                          cols="12"
                        >
                          <record-component
                            :v-model="record"
                            :record-list="recordList"
                            :tag-list="tagList"
                            :index="recordListPagePagination - 1"
                            :project="currentProject"
                            :user-type="currentMode"
                            :stage="submittedAdvanced.stage || currentProject?.data.stage"
                            :keyword-list="keywordList"
                            :options="recordOptions"
                            :filters="filterList"
                            :side-panel.sync="recordSidePanel"
                            :show-user-list="showUserList"
                            :row-sizes="recordRowSizes"
                            :disabled="recordListLoading"
                            :project-user-list="currentProject?.data.usernode"
                            :last-search-date="lastSearchDate"
                            :style="{
                              opacity: recordListLoading ? 0.5 : 1
                            }"
                            ref="recordComponent"
                            @status="setStatus"
                            @first="firstPage"
                            @last="lastPage"
                            @previous="previousPage"
                            @next="nextPage"
                            @save-status-success="onSaveStatusSuccess"
                            @load-project-progress="loadProjectProgress"
                            @next-page="nextPage"
                            @set-page="setPage"
                            @pid-change="onPidChange"
                            @delete="search"
                            @insert-tag="onInsertTag"
                          ></record-component>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </v-data-iterator>
              </div>
            </div>
          </v-card>
        </v-tab-item>

        <!-- METRICS -->
        <v-tab-item transition="none">
          <v-card-text>
            <metrics-component
              :loading="metricsLoading"
              :metrics="metrics"
              title="Official Metrics"
            >
            </metrics-component>

            <!-- USER SELECTION -->
            <template v-if="mode && currentMode === 'arbitrator'">
              <h3 class="mt-6">Metrics by user</h3>
              <v-divider class="mt-2 mb-4" />
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="metricFilters.projectUserList"
                    :items="currentProject?.data.usernode"
                    item-text="label"
                    label="Users"
                    hide-details="auto"
                    multiple
                    outlined
                    dense
                    chips
                    small-chips
                    deletable-chips
                    clearable
                    return-object
                    @change="searchMetricsProjectUserList"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </template>

            <MetricsComponent
              v-for="(userMetrics, userMetricsIndex) in usersMetrics"
              :key="userMetricsIndex"
              :title="userMetrics.projectUser.label"
              :loading="userMetrics.loading"
              :metrics="userMetrics.metrics"
              class="mt-6"
            />
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Watch, Ref} from 'vue-property-decorator';
import moment from 'moment';
import {saveAs} from 'file-saver';
import contentDisposition from 'content-disposition';
import Identity from '@/modules/sdk/core/identity';
import Rules from '@/modules/sdk/core/rules';
import UserModel from '@/modules/sdk/models/user.model';
import ProjectModel from '@/models/project.model';
import RecordModel from '@/models/record.model';
import RecordService from '@/services/record.service';
import KeywordModel from '@/models/keyword.model';
import KeywordService from '@/services/keyword.service';
import SynonymModel from '@/models/synonym.model';
import ProjectQueryBuilder from '@/components/ProjectQueryBuilder.vue';
import RecordComponent from '@/views/Admin/Component/RecordComponent.vue';
import MetricsComponent from '@/views/Admin/Component/MetricsComponent.vue';
import ProjectProgressComponent from '@/views/Admin/Component/ProjectProgressComponent.vue';
import ExportComponent from '@/views/Admin/Component/ExportComponent.vue';
import PresetManager from '@/modules/common/components/PresetManager.vue';
import KeywordChip from '@/components/KeywordChip.vue';
import MenuTooltip from '@/modules/common/components/MenuTooltip.vue';
import ProjectAutocomplete from '@/components/ProjectAutocomplete.vue';
import ProjectFilterStateService from '@/services/project-filter-state.service';
import ProjectFilterStateModel from '@/models/project-filter-state.model';
import FullscreenComponent from '@/views/Admin/Component/Record/FullscreenComponent.vue';
import SynonymService from '@/services/synonym.service';
import Query from '@/modules/sdk/core/query';
import ProjectUserModel from '@/models/project-user.model';
import ProjectService from '@/services/project.service';
import { queryMapper } from '@/utils/query-mapper';
import { IQueryAdvanced } from '@/modules/sdk/core/interfaces';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import { SharedQuery } from '@/utils/shared-query';
import TagModel from '@/models/tag.model';
import SurveyModel from '@/modules/sdk/models/survey.model';
import RecordUserModel from '@/models/record-user.model';
import {
  orderList,
  reviewedByList,
  statusList,
  recordFieldList,
  projectUserTypeList,
  keyedUserTypeList,
  stageList,
  officialQueryStatusList
} from '@/enums/global'

let recordListPagePaginationChangeTimeout: any;

interface IMetricFilters {
  projectUserList: Array<ProjectUserModel>,
}

@Component({
  components: {
    ExportComponent,
    MetricsComponent,
    RecordComponent,
    ProjectQueryBuilder,
    ProjectProgressComponent,
    PresetManager,
    KeywordChip,
    FullscreenComponent,
    ProjectAutocomplete,
    MenuTooltip,
    ModalDialog,
  }
})
export default class ReviewView extends Vue {
  @Ref() readonly projectProgressComponent!: ProjectProgressComponent;
  @Ref() readonly exportComponent!: ExportComponent;
  @Ref() readonly recordComponent!: Array<RecordComponent>;
  @Ref() readonly builderComponent!: ProjectQueryBuilder;
  @Ref() readonly presetManager!: PresetManager;
  @Ref() readonly modeRef!: any;

  // View & panels definition
  tab = 0
  primSec: ('primary' | 'secondary')[] = ['primary', 'secondary']
  loadedTabIdx: [boolean, boolean] = [false, false]
  ready = false
  loadingProject = false
  massAssignToUserListVisible = false
  massAssigningToPilot = false
  percentageTypeIdx = 0
  percentageTypes = ['project', 'filtered']
  mode: any = null
  panelList: Array<number> = [0];
  recordPanelList: Array<number> = [2];
  expansionPanel: {[key: string]: number} = {
    filters: 0,
    keywords: 0,
    options: 1,
    records: 2,
  }

  // Project
  project: ProjectModel | null = null;
  currentProject: ProjectModel | null = null;
  projectId = 0;
  projectList = [];
  queryLocked = false;
  tagList: Array<TagModel> = [];
  stageList = stageList
  keyedUserTypeList = keyedUserTypeList

  // Keywords
  keywordList: Array<KeywordModel> = [];
  keywordLoading = false;

  // Metrics
  metrics = {};
  metricsLoading = false;
  usersMetrics: Array<any> = [];
  metricFilters: IMetricFilters = {
    projectUserList: [],
  }

  // Records
  recordList: Array<RecordModel> = [];
  recordListPage = 1;
  recordListPagePagination = 1;
  recordListLoaded = false
  recordListLoading = false;
  recordSidePanel = true;
  recordRowSizes = [
    { md: 7, lg: 8 },
    { md: 5, lg: 4 },
  ];

  mappingTypes: any = {
    'leader': 'leader',
    'arbitrator': 'arbitrator',
    'researcher': 'primary researcher',
    'secondary-researcher': 'secondary researcher',
    'ai-manager': 'ai manager',
  };

  // Filters and options
  lastSearchDate: Date | null = null;
  loadingPresets = false;
  savingPreset = false;
  filterList: Array<any> = [];
  defaultPresetItem: Array<any> = [{
    logic: 'and',
    operator: 'contains',
    isJoin: false,
    list: [],
    field: null,
    value: null,
    group: [] as any,
  }];

  recordFieldList = recordFieldList;
  recordOptions = {
    highlightKeywords: true,
    highlightFilters: true,
    addSpaces: true,
    splitSection: true,
    removeUseless: true,
    recordFields: [] as Array<string>,
  };

  // Confusing now because "advanced" was not the
  // "Advanced Query Builder" at the beginning.
  // Here, "advanced" means the Query
  // (stage/status/reviewed by/etc).
  tick = 0
  advanced: IQueryAdvanced = {
    stage: null,
    status: null,
    userId: null,
    userStatus: null,
    reviewed: null,
    order: [],
    orderAsc: []
  };

  submittedFilters: any[] = []
  submittedAdvanced: IQueryAdvanced = {
    stage: null,
    status: null,
    userId: null,
    userStatus: null,
    reviewed: null,
    order: [],
    orderAsc: []
  };

  orderList = orderList;
  limitList = [50, 100, 250, 500, 1000]
  limit = null

  // Export CSV Modal Options
  exportCsvModal: {
    visible: boolean,
    loading: boolean,
  } = {
    visible: false,
    loading: false,
  }

  defaultMassAssignTo = RecordUserModel;
  defaultMassAssignToData = {
    userentity: new UserModel().data,
    percentage: 100,
  }

  massAssignUserList: {
    primary: UserModel[],
    secondary: UserModel[],
  } = {
    primary: [],
    secondary: [],
  }

  selectedMassAssignTo: {
    primary: RecordUserModel[],
    secondary: RecordUserModel[],
  } = {
    primary: [],
    secondary: [],
  };

  overwriteAssignation = false
  keepViewState = false
  formQueryValid = true

  rules: any = [];

  @Watch('tab')
  onTabChanged(index: number) {
    if (!this.loadedTabIdx[index]) {
      this.search();
    }
  }

  get reviewedByList() : Array<any> {
    const reviewedByUserWithRoleList: Array<{value: string, text: string}> = [];
    const reviewedByUserList: Array<{value: string, text: string}> = [];

    this.currentProject?.data.usernode.forEach((userNode: ProjectUserModel) => {
      const userType = userNode.data.type;
      const userTypeText = this.mappingTypes[userType];
      const userFullName = userNode.data.userentity.getFullName();
      const userId = userNode.data.userentity.data.id;

      // by user node (ProjectUser)
      const reviewedByUserNodeText = 'reviewed by ' + userTypeText + ' ' + userFullName;
      const reviewedByUserNodeValue = 'reviewed by userType ' + userType + ' userId ' + userId;
      reviewedByUserWithRoleList.push({ value: reviewedByUserNodeValue, text: reviewedByUserNodeText});
      reviewedByUserWithRoleList.push({ value: 'not ' + reviewedByUserNodeValue, text: 'not ' + reviewedByUserNodeText});

      // by user
      const reviewedByUserText = 'reviewed by ' + userFullName;
      const reviewedByUserValue = 'reviewed by user userId ' + userId;
      reviewedByUserList.push({value: reviewedByUserValue, text: reviewedByUserText});
      reviewedByUserList.push({value: 'not ' + reviewedByUserValue, text: 'not ' + reviewedByUserText});
    });

    return [
      ...[{ divider: true }],
      ...reviewedByList,
      ...[{ divider: true }, { header: 'Users With Roles' }], { divider: true },
      ...reviewedByUserWithRoleList,
      ...[{ divider: true }, { header: 'Users List' }], { divider: true },
      ...reviewedByUserList,
    ];
  }

  canCreateRecord(): boolean {
    if (this.recordComponent && this.recordComponent.length > 0) {
      return this.recordComponent[0].canEditDeleteRestoreRecord;
    }
    return false;
  }

  createRecordIsActive(): boolean {
    if (this.recordComponent && this.recordComponent.length > 0) {
      return this.recordComponent[0].canEditDeleteRestoreRecord && !this.recordComponent[0].editing;
    }
    return false;
  }

  get canGoFirst(): boolean {
    return !this.recordListLoading && this.recordListPagePagination > 1;
  }

  get canGoPrevious(): boolean {
    return !this.recordListLoading && this.recordListPagePagination > 1;
  }

  get canGoNext(): boolean {
    return !this.recordListLoading && this.recordListPagePagination < this.numberOfPages;
  }

  get canGoLast(): boolean {
    return !this.recordListLoading && this.recordListPagePagination < this.numberOfPages;
  }

  get percentageType(): string {
    return this.percentageTypes[this.percentageTypeIdx];
  }

  get currentMode(): null | string {
    return (this.mode && this.mode.value) || null;
  }

  get availableKeywordList(): Array<any> {
    return this.keywordList.filter(keyword => !keyword.data.deleted);
  }

  get userId(): number | null {
    return Identity.getCurrentUserId()
  }

  get projectStage(): 'screening' | 'indepth' | 'final' {
    return this.submittedAdvanced.stage || this.currentProject?.data?.stage;
  }

  get modeList(): Array<any> {
    const items: Array<any> = [];
    const filteredTypes = this.currentProject?.data?.usernode?.filter((userNode: any) => {
      return userNode.data.userId === Identity.getCurrentUserId();
    }).map((projectUser: any) => projectUser.data.type) || [];
    for (const userType of projectUserTypeList) {
      if (filteredTypes.indexOf(userType.value) !== -1) {
        items.push(userType);
      }
    }
    return items;
  }

  get filteredStageList(): { value: any, text: string }[] {
    const index = stageList.findIndex(stage => stage.value === this.currentProject?.data.stage);
    return stageList.slice(0, index + 1);
  }

  get filteredStatusList(): { value: any, text: string, color: string }[] {
    // arbitrator mode can filter using conflict
    if (this.currentMode === 'arbitrator') {
      return officialQueryStatusList;
    }
    // other users can only see basic status
    return statusList
  }

  get canSearch(): boolean {
    return !!(this.formQueryValid
      && !this.recordListLoading
      && !this.metricsLoading
      && this.advanced.stage)
  }

  get showUserList() : boolean {
    return this.currentMode === 'arbitrator';
  }

  get canMassAssignUser(): boolean {
    return this.currentMode === 'arbitrator';
  }

  get canClickMassAssign(): boolean {
    return this.canMassAssignUser
      && (
        this.selectedMassAssignTo.primary.length > 0
        || this.selectedMassAssignTo.secondary.length > 0
      )
      && (
        (this.selectedMassAssignTo.primary.filter(recordUser => recordUser.data.userentity?.data.id).length === this.selectedMassAssignTo.primary.length)
        && (this.selectedMassAssignTo.secondary.filter(recordUser => recordUser.data.userentity?.data.id).length === this.selectedMassAssignTo.secondary.length)
      )
      && !this.massAssigningToPilot;
  }

  get mappedFilters(): any {
    const filters = Query.prepareFilters(this.filterList);
    return Query.applyMapper(filters, queryMapper);
  }

  get hasProject() : boolean {
    return !!this.projectId;
  }

  get hasRecords() : boolean {
    return !!this.recordList.length;
  }

  get hasKeywords() : boolean {
    return !!this.keywordList.length;
  }

  get showModeSelection(): boolean {
    return !!this.modeList.length && !!(this.currentProject);
  }

  get showFilters(): boolean {
    return this.hasProject && this.mode;
  }

  get showQuery(): boolean {
    return this.hasProject && this.mode;
  }

  get showRecords(): boolean {
    return this.hasProject;
  }

  get showMetrics(): boolean {
    return this.hasProject;
  }

  get showKeywords(): boolean {
    return this.showRecords;
  }

  get showOptions(): boolean {
    return this.showRecords;
  }

  get showTabs(): boolean {
    return this.showMetrics || this.showRecords;
  }

  get numberOfPages() : number {
    return Math.ceil(this.recordList.length / this.itemsPerPage);
  }

  get itemsPerPage() : number {
    return 1;
  }

  get availableKeywordAllSelected(): boolean {
    for (const keyword of this.availableKeywordList) {
      if (!keyword.selected) {
        return false;
      }
    }
    return true;
  }

  get isAdvancedQueryBuilderPristine(): boolean {
    return this.filterList.every((item, index) => {
      return this.defaultPresetItem[index] && this.filterList[index]
        && Object.keys(this.filterList[index]).every((key: string) => {
          return JSON.stringify(this.defaultPresetItem[index][key]) === JSON.stringify(this.filterList[index][key]);
        });
    })
  }

  clearAdvancedQueryBuilder() {
    this.filterList = structuredClone(this.defaultPresetItem);
  }

  getMassAssignUserList(type: string, index: number): any[] {
    // @ts-ignore
    const userList = this.massAssignUserList[type];
    // @ts-ignore
    const selectedMassAssignToList = this.selectedMassAssignTo[type];
    return userList.filter((user: UserModel) => {
      const otherIdx = index === 0 ? 1 : 0;
      const otherUser = selectedMassAssignToList[otherIdx]?.data.userentity;
      // @ts-ignore
      return !otherUser || !otherUser.data.id || (selectedMassAssignToList[otherIdx]?.data.userentity.data.id !== user.data.id);
    })
  }

  onProjectChange(): void {
    if (this.projectId) {
      this.loadProject(this.projectId)
        .then(() => this.saveViewState());
    }
  }

  onModeChange(): void {
    this.advanced.reviewed = null;
    this.autoAssignReviewedBy();
    this.saveViewState();

    if (this.projectId) {
      this.loadTags(this.projectId);
      this.loadKeywords(this.projectId);
    }
  }

  onApplyKeyword(model: KeywordModel | SynonymModel): Promise<any> {
    model.states.saving = true;
    const instance = model instanceof KeywordModel
      ? KeywordService.getInstance()
      : SynonymService.getInstance();

    const addCallback = () => {
      const wasNew = !(model.data.id);
      const wasSelected = model.selected;
      return instance.save(model)
        .then(response => model.assign(response.data.view.single))
        .catch(this.$root.$zemit.handleError)
        .finally(() => {
          model.selectedToggle(wasNew || wasSelected);
          model.states.saving = false;
          this.$forceUpdate();
        });
    }

    // If newly added keyword/synonym that already exists, ask the end-user if he wishes
    // to continue or not.
    if (
      !model.data.id
      && (
        (model instanceof KeywordModel && this.availableKeywordList.find(keyword => keyword.data.id && keyword.data.label.toLowerCase().trim() === model.data.label.toLowerCase().trim()))
        || (model instanceof SynonymModel && this.availableKeywordList.find(keyword => keyword.data.id && keyword.data.synonymlist.find((synonym: SynonymModel) => synonym.data.id && synonym.data.label.toLowerCase().trim() === model.data.label.toLowerCase().trim())))
      )
    ) {
      return new Promise((resolve) => this.$root.$shouldTakeAction.ask({
        title: 'Duplicate found',
        body: 'This item already exist in your list. Do you want to add it anyway?',
        actionText: 'Add anyway',
        color: 'warning',
        dark: true,
        onAccept: () => resolve(addCallback()),
      }));
    } else {
      return addCallback();
    }
  }

  onInsertTag(tag: TagModel) {
    this.tagList.push(tag);
  }

  onAddKeywordClick(): void {
    const model = new KeywordModel({
      projectId: this.projectId,
    });
    this.keywordList.push(model);
    this.$nextTick(() => {
      const ref = this.$refs['keyword_' + (this.availableKeywordList.length - 1).toString()];
      if (ref) {
        // @ts-ignore
        ref[0].editItem(model);
      }
    })
  }

  onAddSynonymClick(keyword: KeywordModel, keywordIdx: number): void {
    const synonym = new SynonymModel({
      id: null,
      label: null,
      keywordId: keyword.data.id,
      projectId: this.projectId,
    });
    keyword.data.synonymlist.push(synonym);

    const refKeyword = this.$refs['keyword_' + keywordIdx.toString()];
    if (refKeyword) {
      // @ts-ignore
      refKeyword[0].editKeywordSynonym(keyword, synonym);
    }
  }

  onSelectAllKeywordsClick(): void {
    const allSelected = this.availableKeywordAllSelected
    this.availableKeywordList.forEach((keyword: any) => {
      if ((allSelected && keyword.selected) || (!allSelected && !keyword.selected)) {
        keyword.selectedToggle();
      }
    })
  }

  onCreateRecordClick() {
    if (this.recordComponent && this.recordComponent.length > 0) {
      return this.recordComponent[0].onCreateRecordClick();
    }
  }

  onApplyPresetFilters(preset: Array<any>) {
    this.filterList = preset;
    this.saveViewState();
    this.queryLocked = true;
  }

  onPidChange(pid: number) {
    const index = this.recordList.findIndex(record => record.data.pid === pid);
    if (index !== -1) {
      this.recordListPage = index + 1;
    }
  }

  get selectedAllRecordFields () {
    return this.recordOptions.recordFields.length === this.recordFieldList.length;
  }

  get selectedSomeRecordFields () {
    return this.recordOptions.recordFields.length > 0 && !this.selectedAllRecordFields;
  }

  onAssignMassUserInput(user: UserModel, type: string, index: number) {
    this.updateMassAssignUserList();
    // @ts-ignore
    this.applyMassAssignPercentage(this.selectedMassAssignTo[type].length === 2 ? 50 : 100, index, this.selectedMassAssignTo[type]);
  }

  updateMassAssignUserList(): void {
    this.massAssignUserList.primary = [];
    this.massAssignUserList.secondary = [];
    for (let i = 0; i < this.currentProject?.data.usernode.length; i++) {
      const userNode = this.currentProject?.data.usernode[i];
      ['researcher', 'secondary-researcher'].forEach(mappingType => {
        const userMode = mappingType === 'researcher' ? 'primary' : 'secondary';
        if (!this.massAssignUserList[userMode].find(item => item.data.id === userNode.data.userentity.data.id)) {
          const item = new UserModel(userNode.data.userentity);
          this.massAssignUserList[userMode].push(item);
        }
      })
    }
  }

  applyMassAssignPercentage(amount: number, index: number, list: RecordUserModel[]) {
    amount = parseInt(amount.toString());
    if (list.length === 2) {
      amount = amount > 99 ? 99 : amount < 1 ? 1 : amount;
      const otherIdx = index === 0 ? 1 : 0;
      if (parseInt(list[otherIdx].data.percentage) + parseInt(list[index].data.percentage) !== 100) {
        if (list[otherIdx]) {
          list[otherIdx].data.percentage = 100 - amount;
        }
        if (list[index]) {
          list[index].data.percentage = amount;
        }
      }
    }
    else {
      amount = 100;
      if (list[index]) {
        list[index].data.percentage = amount;
      }
    }
  }

  get recordFieldsIcon () {
    if (this.selectedAllRecordFields) return 'mdi-close-box'
    if (this.selectedSomeRecordFields) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  async reloadRecord(record: RecordModel): Promise<any> {
    this.recordListLoading = true;
    return RecordService.getInstance().get({
      id: record.data.id,
    })
      .then((response) => {
        record.assign(response.data.view.single);
        return record;
      })
      .finally(() => this.recordListLoading = false);
  }

  async loadPresets(): Promise<any> {
    return ProjectFilterStateService.getInstance().getAll({
      filters: [{
        field: 'projectId',
        operator: 'equals',
        value: this.projectId,
      }, [{
        field: 'userId',
        operator: 'is null',
      }, {
        field: 'userId',
        operator: 'equals',
        value: Identity.getCurrentUserId(),
      }]]
    }).then(response => response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason));
  }

  async savePresets(item: ProjectFilterStateModel) {
    return ProjectFilterStateService.getInstance().save(item)
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  async removePreset(item: ProjectFilterStateModel) {
    return ProjectFilterStateService.getInstance().delete({
      id: item.data.id,
    })
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  toggleRecordFields(): void {
    this.$nextTick(() => {
      if (this.selectedAllRecordFields) {
        this.recordOptions.recordFields = []
      } else {
        this.recordOptions.recordFields = this.recordFieldList.map((item: {text: string, value: string}) => {
          return item.value;
        });
      }
    });
  }

  onToggleSort(index: number): void {
    this.advanced.orderAsc[index] = !this.advanced.orderAsc[index];
    this.tick++; // Hack so #selection slot updates
    this.$forceUpdate();
  }

  onRecordListPagePaginationChange(page: number): void {
    clearTimeout(recordListPagePaginationChangeTimeout);
    recordListPagePaginationChangeTimeout = setTimeout(() => {
      this.recordListPage = page;
    }, 500);
  }

  setStatus(status: string) {
    this.recordComponent[0].setStatus(status);
  }

  // Go to first page
  firstPage() {
    this.recordListPage = 1;
  }

  // Go to last page
  lastPage() {
    this.recordListPage = this.numberOfPages;
  }

  // Go to next page
  nextPage() {
    if (this.recordListPage + 1 <= this.numberOfPages) {
      this.recordListPage += 1;
    } else {
      const record = this.recordComponent[0] as RecordComponent;
      record.loadRecordStatus();
    }
  }

  // Set page
  setPage(page: number) {
    this.recordListPage = page;
  }

  // Go to previous page
  previousPage() {
    if (this.recordListPage - 1 >= 1) {
      this.recordListPage -= 1;
    }
  }

  onMassAssignClick() {
    this.massAssignToUserListVisible = true;
    this.selectedMassAssignTo.primary = [];
    this.selectedMassAssignTo.secondary = [];
    this.overwriteAssignation = false;
    this.updateMassAssignUserList();
  }

  showWarningIfLast() {
    if (this.recordListPage === this.recordList.length) {
      this.$root.$globalSnack.info({
        message: 'You completed the list of records.'
      });
    }
  }

  openPanels(panels: string | Array<string> = [], list = this.panelList) {
    if (!Array.isArray(panels)) {
      panels = [panels];
    }
    panels.forEach(panel => {
      const index = this.expansionPanel[panel];
      if (!list.includes(index)) {
        list.push(index);
      }
    })
  }

  closePanels(panels: string | Array<string> = [], list = this.panelList) {
    if (!Array.isArray(panels)) {
      panels = [panels];
    }
    panels.forEach(panel => {
      const index = this.expansionPanel[panel];
      if (list.includes(index)) {
        list.splice(index, 1);
      }
    })
  }

  isPanelOpen(key: string, list = this.panelList): boolean {
    const index = this.expansionPanel[key];
    return !list.includes(index);
  }

  getSearchParams() {
    this.clearRecordList();
    this.clearMetrics();
    this.recordListLoading = true;

    // Filters, order, advanced
    const filters = this.getFilters();
    const order = this.getOrder();
    const limit = this.getLimit();
    const advanced = this.getAdvanced();
    const joins = this.getJoins();

    // Collapse keywords panel
    this.closePanels('keywords', this.recordPanelList);

    // Fix first filter logic (must be AND)
    if (filters.length > 1 && filters[1].length > 0) {
      filters[1][0].logic = 'and';
    }

    return { filters, order, limit, advanced, joins };
  }

  search(): Promise<any> {
    if (this.tab === 0) {
      return this.searchRecords();
    } else {
      return this.searchMetrics();
    }
  }

  /**
   * Search feature
   * - Build the filters and launch query to fetch records
   */
  async searchRecords(): Promise<any> {
    this.loadedTabIdx[0] = true;

    const params: any = this.getSearchParams();
    return RecordService.getInstance().getAll(params)
      .then(response => {
        this.recordList = response.data.view.list;
        this.submittedAdvanced = structuredClone(this.advanced);
        this.submittedFilters = structuredClone(params.filters);
        this.recordListLoaded = true;

        // If 'not reviewed by anyone' has been selected, set the last search date
        // as current date and pass it to the StatusComponent which is going to
        // perform a verification on loading the statuses for the current record and
        // show an alert message if the statuses has been changed since the user
        // last performed search.
        this.lastSearchDate = this.submittedAdvanced.reviewed === 'not reviewed by anyone'
          ? new Date()
          : null;

        this.currentProject?.data.surveylist.forEach((survey: SurveyModel) => {
          survey.setCustomFieldValues({
            show_from_stage: this.submittedAdvanced.stage
          });
        })
      })
      .finally(() => this.recordListLoading = false);
  }

  /**
   * Show Metrics based on the filters
   */
  async searchMetrics(): Promise<any> {
    this.loadedTabIdx[1] = true;

    this.clearRecordList();
    this.closePanels(['keywords', 'options'], this.recordPanelList);

    // Filters, order, advanced
    const filters = this.getFilters();
    const limit = this.getLimit();
    const advanced = this.getAdvanced();

    // Global Query
    this.metricsLoading = true;
    return RecordService.getInstance().getMetrics({filters, limit, advanced})
      .then(response => this.metrics = response.data.view.metrics)
      .then(() => this.searchMetricsProjectUserList(this.metricFilters.projectUserList, true))
      .finally(() => this.metricsLoading = false);
  }

  /**
   * Searches and updates the metrics for a given list of project users (nodes)
   */
  searchMetricsProjectUserList(projectUserList: ProjectUserModel[], reset = false) {
    const filters = this.getFilters();
    const limit = this.getLimit();

    if (reset) {
      this.usersMetrics = [];
    }

    // remove removed projectUserList from usersMetrics
    this.usersMetrics = this.usersMetrics.filter((userMetric) => projectUserList.findIndex(projectUser => projectUser.data.id === userMetric.projectUser.data.id) !== -1)

    projectUserList.forEach((projectUser: ProjectUserModel) => {
      const userMetric = this.usersMetrics.find((userMetric: any) => userMetric.projectUser.data.id === projectUser.data.id);

      if (!userMetric) {
        const newUserMetric = {
          projectUser: projectUser,
          loading: true,
          metrics: {},
        };
        this.usersMetrics.push(newUserMetric);

        const advanced = {
          ...this.getAdvanced(),
          userId: projectUser.data.userId,
          userMode: projectUser.data.type,
        };

        RecordService.getInstance().getMetrics({filters, limit, advanced})
          .then(response => newUserMetric.metrics = response.data.view.metrics)
          .finally(() => newUserMetric.loading = false);
      }
    });
  }

  /**
   * Export CSV based on the filters
   */
  exportCsv(props: {
    type: string,
    data: {
      articles: number[],
      fields: number[],
      questions: number[],
      questionUsers: any[]
      projectUserList: ProjectUserModel[],
      aiList: any[],
    }
  } = {
    type: 'xlsx',
    data: {
      articles: [],
      fields: [],
      questions: [],
      questionUsers: [],
      projectUserList: [],
      aiList: [],
    }
  }) {
    const filters = this.getFilters();
    const order = this.getOrder();
    const limit = this.getLimit();
    const advanced: any = {
      ...this.getAdvanced(),
      ...props.data,
      questionUsers: [],
      questionAi: false,
    }

    advanced.projectUserList = props.data.projectUserList.map((projectUser: ProjectUserModel) => projectUser.toObject())

    for (const value of props.data.questionUsers) {
      if (value !== 'ai') {
        advanced.questionUsers.push(value);
      } else {
        advanced.questionAi = true;
      }
    }

    // Query
    this.exportCsvModal.loading = true;
    RecordService.getInstance().export({
      filters,
      order,
      limit,
      advanced,
      contentType: props.type,
    }, {responseType: 'blob'})
      .then((response: any) => {
        const fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename
          || this.currentProject?.data.label + ' - Records - ' + moment().format('YYYY-MM-DD') + '.' + props.type;
        saveAs(response.data, fileName);
        this.exportComponent.close();
      })
      .finally(() => this.exportCsvModal.loading = false);
  }

  onSaveStatusSuccess() {
    this.showWarningIfLast();
  }

  loadProjectProgress() {
    if (this.projectProgressComponent) {
      const promise = this.projectProgressComponent.loadProgress();
      if (promise) {
        promise.then(this.onProjectProgressChange);
      }
    }
  }

  getLimit(): number {
    return parseInt((this.limit || '0').toString()) || 1000000;
  }

  getFilters(): any[] {
    return Query.getValidFilters(this.mappedFilters, this.projectId, queryMapper);
  }

  getOrder(): string[] {
    return this.advanced.order.map((order: string, index: number) => {
      return (this.advanced.order[index] || 'id') + ' ' + (this.advanced.orderAsc[index] ? 'asc' : 'desc');
    })
  }

  getJoins(): { [key: string]: any[] } {
    return Query.getJoins(this.filterList);
  }

  getAdvanced(): object {
    return this.advanced;
  }

  getOfficialStatusStageLabel(stage = this.advanced.stage) : string {
    return 'Official Status' + (stage? ' (' + this.stageList.find(item => item.value === stage)?.text || stage + ')' : '');
  }

  getReviewedByStageLabel(stage = this.advanced.stage) : string {
    return 'Reviewed by' + (stage ? (' (' + this.stageList.find(item => item.value === stage)?.text || stage + ')') : '');
  }

  @Watch('currentProject.data.id')
  onProjectIdChange(id: string | number | null, previous: string | number | null) {
    this.clearRecordList();
    this.clearMetrics();
    this.clearKeywords();
    this.clearFilters();

    // clear mode when switching from a project to another
    if (previous) {
      this.clearMode();
    }

    if (this.presetManager && id) {
      this.presetManager.load();
    }

    // Project selected
    if (id) {
      this.openPanels(['filters'], this.panelList);

      if (this.currentMode) {
        const projectId = parseInt(id.toString());
        this.loadTags(projectId);
        this.loadKeywords(projectId);
      }

      // Set default query
      this.autoAssignReviewedBy();
    }

    // No project selected
    else {
      // Close everything except the project selection
      this.closePanels(['filters'], this.panelList);
      this.closePanels(['keywords', 'options'], this.recordPanelList);
    }
  }

  onProjectProgressChange(response: any) {
    if (
      response.progress === 100
      || response.percentage.pass.percentage === 100
      || response.percentage.pending.percentage === 100
      || response.percentage.halt.percentage === 100
    ) {
      this.$root.$globalConfetti.fireworks();
    }
  }

  @Watch('recordListPage', {
    immediate: true
  })
  onRecordListPageChanged(page: number) {
    if (this.recordList[page - 1]) {
      this.recordListPagePagination = page;
      this.reloadRecord(this.recordList[page - 1]);
    }
  }

  @Watch('keepViewState')
  onKeepViewStateChanged(state: boolean) {
    localStorage.setItem('review_keep_view_state', JSON.stringify(state));
  }

  @Watch('view')
  @Watch('recordListPagePagination')
  @Watch('panelList', { deep: true })
  @Watch('recordPanelList', { deep: true })
  @Watch('filterList', { deep: true })
  @Watch('advanced', { deep: true })
  onStateChange() {
    this.saveViewState();
  }

  onPageInput(value: string): void {
    this.onRecordListPagePaginationChange(parseInt(value));
  }

  onExpansionPanelHeaderClick(panel: string): void {
    if (this.$refs[panel + '_panel']) {
      // @ts-ignore
      this.$refs[panel + '_panel'].expansionPanel.toggle();
    }
  }

  async loadTags(projectId: number): Promise<Array<TagModel>> {
    return SharedQuery.getProjectTagsByCategoryId(projectId)
      .then(tags => this.tagList = tags);
  }

  autoAssignReviewedBy(): void {
    this.advanced.status = null;

    const project = this.currentProject;
    if (project) {
      this.advanced.stage = project.data.stage;
      if (project.hasRole('secondary-researcher')) {
        this.advanced.reviewed = 'not reviewed by secondary researcher';
      }
      if (project.hasRole('researcher')) {
        this.advanced.reviewed = 'not reviewed by primary researcher';
      }
    }

    if (this.mode) {
      if (this.currentMode === 'arbitrator') {
        this.advanced.reviewed = 'not reviewed by arbitrator';
        this.advanced.status = 'conflict';
      } else if (this.currentMode === 'secondary-researcher') {
        this.advanced.reviewed = 'not reviewed by secondary researcher';
      } else if (this.currentMode === 'researcher') {
        this.advanced.reviewed = 'not reviewed by primary researcher';
      }
    }
  }

  setDefaultMode() {
    if (this.modeList.length === 1) {
      this.mode = this.modeList[0];
    }
  }

  /**
   * Clear Metrics & close panel
   */
  clearMetrics() {
    this.metrics = {};
    this.metricFilters = {
      projectUserList: [],
    }
  }

  /**
   * Clear Record list & close panel
   */
  clearRecordList() {
    this.recordListPage = 1;
    this.recordListPagePagination = 1;
  }

  /**
   * Clear Keywords & close panel
   */
  clearKeywords() {
    this.keywordList = [];
    this.closePanels(['keywords'], this.recordPanelList);
  }

  /**
   * Clear Filters & close panel
   */
  clearFilters() {
    this.filterList = JSON.parse(JSON.stringify(this.defaultPresetItem));
    this.closePanels([
      'filters'
    ], this.panelList);
  }

  clearMode() {
    this.mode = null;
  }

  async loadProject(id: number): Promise<ProjectModel> {
    this.loadingProject = true;
    return ProjectService.getInstance().get({ id })
      .then(response => {
        this.currentProject = response.data.view.single;
        this.setDefaultMode();
        setTimeout(() => {
          if (!this.currentMode && this.modeRef) {
            this.modeRef.isMenuActive = true;
            this.modeRef.isFocused = true;
          }
        })
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loadingProject = false);
  }

  timeoutCallback(fn: () => void, timeout = 0): number {
    return setTimeout(fn, timeout);
  }

  saveViewState(): void {
    if (this.keepViewState) {
      localStorage.setItem('review_view_state', JSON.stringify({
        limit: this.limit,
        advanced: this.advanced,
        filterList: this.filterList,
        projectId: this.projectId,
        mode: this.mode,
        recordOptions: this.recordOptions,
        panelList: this.panelList,
        recordPanelList: this.recordPanelList,
      }));
    }
  }

  autoSelectProject(projectId: number): Promise<number> {
    return new Promise((resolve) => {
      if (projectId) {
        this.projectId = projectId;
        this.loadProject(projectId).then(() => {
          if (this.showQuery && this.formQueryValid) {
            setTimeout(() => {
              this.search();
            })
          }
          return projectId;
        }).then(resolve);
      } else {
        resolve(projectId);
      }
    })
  }

  loadViewState(): void {
    const state = JSON.parse(localStorage.getItem('review_view_state') || '{}');

    Object.assign(this.advanced, state.advanced || {});
    Object.assign(this.filterList, state.filterList || {});
    Object.assign(this.recordOptions, state.recordOptions || {});

    // Check if values array to support recent
    // change in data structure
    if (!Array.isArray(this.advanced.order)) {
      this.advanced.order = [this.advanced.order];
    }
    if (!Array.isArray(this.advanced.orderAsc)) {
      this.advanced.orderAsc = [this.advanced.orderAsc];
    }

    if (state.mode) {
      if (this.modeList.find(item => item.value === this.mode)) {
        this.mode = state.mode;
      }
    }

    this.limit = state.limit ? state.limit : this.limit;
    this.panelList = state.panelList ? state.panelList : this.panelList;
    this.recordPanelList = state.recordPanelList ? state.recordPanelList : this.recordPanelList;
    this.autoSelectProject(state.projectId ? state.projectId : this.projectId)
      .finally(() => this.ready = true);
  }

  onProjectLoad(projects: Array<ProjectModel>) {
    if (!this.keepViewState && projects.length > 0) {
      this.ready = true
    }
  }

  onMassAssignRecordClick() {
    if (
      this.selectedMassAssignTo.primary.length === 0
      && this.selectedMassAssignTo.secondary.length === 0
    ) {
      return;
    }

    this.$root.$shouldTakeAction.ask({
      icon: 'mdi-alert',
      title: 'Mass assign records',
      body: 'You are about to assign all filtered records to the selected users?. This will affect ' + this.numberOfPages + ' records. Are you sure you want to proceed?',
      actionText: 'Proceed',
      color: 'error',
      dark: true,
      onAccept: async () => {
        const params: any = { ...this.getSearchParams() };
        params.advanced.overwriteAssignation = this.overwriteAssignation;
        params.advanced.assignUsers = [];
        ['primary', 'secondary'].forEach(userMode => {
          // @ts-ignore
          const items = this.selectedMassAssignTo[userMode].map((recordUser: RecordUserModel) => ({
            userId: recordUser.data.userentity.data.id,
            userMode: userMode === 'primary' ? 'researcher' : 'secondary-researcher',
            percentage: recordUser.data.percentage,
          }));
          params.advanced.assignUsers.push(...items);
        })

        this.massAssigningToPilot = true;
        return RecordService.getInstance().assignUser(params)
          .then(async () => {
            return this.search().then(() => {
              this.massAssignToUserListVisible = false;
              this.$root.$globalSnack.success({
                message: 'The record set have been assigned to the selected user(s) successfully'
              });
            });
          })
          .catch(reason => this.$root.$zemit.handleError(reason))
          .finally(() => this.massAssigningToPilot = false);
      }
    })
  }

  loadKeywords(projectId: number) {
    // Load related keywords
    this.keywordLoading = true;
    KeywordService.getInstance().getAll({filters: [[{
        field: 'projectId',
        value: projectId,
        operator: 'equals',
      }, {
        field: 'projectId',
        operator: 'is null',
      }]]
    })
      .then(response => {
        this.keywordList = response.data.view.list;
        this.onSelectAllKeywordsClick();
      })
      .finally(() => this.keywordLoading = false);
  }

  destroyed() {
    this.$root.$emit('toggleFullscreen', false);
  }

  created() {
    this.rules.required = (value: string) => Rules.required(value) || this.$t('rules.required');
    this.rules.minMaxPage = (value: number) => Rules.isBetween(value, 1, this.numberOfPages) || this.$t('rules.minMaxPage', {
      min: 1,
      max: this.numberOfPages,
    });

    this.keepViewState = JSON.parse(localStorage.getItem('review_keep_view_state') || 'false') || false;
    if (this.keepViewState) {
      this.loadViewState();
    }
  }
}
</script>

<style lang="scss" scoped>
.ReviewView .v-expansion-panel-header {
  cursor: default;
}
.header-not-clickable .v-expansion-panel-header {
  cursor: default;
}
#fullscreen-wrapper {
  position: static !important;
}
#fullscreen-wrapper.fullscreen {
  padding: 0 !important;

  .fullscreen-hide {
    display: none;
  }
  & > .v-expansion-panels > .fullscreen-keep {
    margin-top: 0;
  }
}

</style>
