<template>
  <v-card>
    <v-card-title>
      <div class="d-flex align-center w-100">
        <div class="d-flex align-center" style="flex: 0">
          <v-icon v-if="icon" class="mx-2 mr-6" v-text="icon"></v-icon>

          <v-btn
            v-test-id="'back-button'"
            v-if="canGoBack"
            icon
            class="mr-4"
            @click="() => timeoutCallback(onGoBackClick)"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </div>

        <div class="d-flex align-center" style="flex: 1">
          <!-- TITLE -->
          <slot
            name="title"
            :title="title"
            :icon="titleIcon"
          >
            <v-icon left v-if="titleIcon">{{titleIcon}}</v-icon>
            <span v-text="title"></span>
          </slot>
        </div>

        <div class="d-flex align-center" style="flex: 0">

          <!-- OPTIONS FIELD -->
          <slot name="options"></slot>

          <div class="ml-6 d-flex align-center" style="gap: 0.5rem">
            <InlineTutorial v-if="help" :slug="help">
              <template #activator="{ on }">
                <MenuTooltip
                  v-on="on"
                  :btn-attrs="{
                  icon: true,
                }"
                  icon="mdi-help-circle-outline"
                  tooltip="How is this working?"
                />
              </template>
            </InlineTutorial>

            <v-btn
              icon
              @click="() => timeoutCallback(onToggleContent)"
            >
              <v-icon v-if="isCollapsed">mdi-chevron-down</v-icon>
              <v-icon v-else>mdi-chevron-up</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

    </v-card-title>

    <v-expand-transition>
      <div v-if="!isCollapsed">
        <v-divider />

        <!-- ITEM DELETED INDICATOR -->
        <v-expand-transition>
          <div v-if="model.data.deleted">
            <v-alert type="error" class="mb-0" tile>
              <div class="d-flex align-center justify-space-between">
                <span>This item is deleted</span>

                <v-btn
                  v-if="!hideDeleteButton"
                  :disabled="!canRestore"
                  :loading="model.states.restoring"
                  outlined
                  dark
                  @click="() => timeoutCallback(onRestoreClick)"
                >
                  <span v-text="$t('btn.restore')"></span>
                </v-btn>
              </div>
            </v-alert>
          </div>
        </v-expand-transition>

        <div style="position: relative">
          <v-overlay v-model="loading" color="sheet" absolute>
            <v-progress-circular size="64" width="5" color="primary" indeterminate />
          </v-overlay>

          <v-card-text :class="['py-6', background]">
            <v-form
              v-bind="$attrs"
              v-model="formIsValid"
              :disabled="model.states.saving"
              ref="form"
              lazy-validation
              @submit="onSubmit"
            >
              <!-- MAIN CONTENT -->
              <slot
                name="form"
                :form-errors="formErrors"
                :rules="rules"
              >
              </slot>
            </v-form>
          </v-card-text>
        </div>

        <template v-if="!hideActions">
          <v-divider />
          <v-card-actions>

            <slot name="footer_left">
              <!-- DELETE -->
              <v-btn
                v-if="!model.data.deleted && !hideDeleteButton"
                :disabled="!canDelete"
                :loading="model.states.deleting"
                color="error"
                outlined
                @click="() => timeoutCallback(onDeleteClick)"
              >
                <span v-text="$t('btn.delete')"></span>
              </v-btn>

              <!-- RESTORE -->
              <v-btn
                v-else-if="!hideDeleteButton"
                :disabled="!canRestore"
                :loading="model.states.restoring"
                color="success"
                outlined
                @click="() => timeoutCallback(onRestoreClick)"
              >
                <span v-text="$t('btn.restore')"></span>
              </v-btn>
            </slot>

            <v-spacer />

            <slot name="actions" v-bind="{
              canSubmit,
              model,
            }">

              <!-- RESET -->
              <v-btn
                v-if="!hideResetButton"
                :disabled="!canReset"
                text
                depressed
                @click="() => timeoutCallback(onResetClick)"
              >
                <span v-text="$t('btn.reset')"></span>
              </v-btn>

              <!-- SAVE -->
              <v-btn
                v-if="!hideSaveButton"
                :disabled="!canSubmit || model.states.saving"
                :loading="model.states.saving"
                color="primary"
                depressed
                @click="() => timeoutCallback(onSubmitClick)"
              >
                <span v-text="model.data.id ? $t('btn.save') : $t(createAndNew? 'btn.createAndNew' : 'btn.create')"></span>
              </v-btn>
            </slot>
          </v-card-actions>
        </template>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
import { Route } from 'vue-router';
import Model from '@/modules/sdk/core/model';
import Service from '@/modules/sdk/core/service';
import Rules from '@/modules/sdk/core/rules';
import InlineTutorial from '@/modules/common/components/InlineTutorial.vue';
import MenuTooltip from '@/modules/common/components/MenuTooltip.vue';

let lockLeave = false;

const specificKeys = [
  'deleted', 'deletedAs', 'deletedAt', 'deletedBy',
  'restored', 'restoredAs', 'restoredAt', 'restoredBy',
];

@Component({
  components: {
    InlineTutorial,
    MenuTooltip,
  }
})
export default class DataForm extends Vue {
  @VModel() model!: Model
  @Prop() title!: string
  @Prop() icon!: string
  @Prop() titleIcon?: string
  @Prop() service!: Service
  @Prop() parentRoute?: any
  @Prop({ type: Boolean, default: false }) collapsed!: boolean
  @Prop({ type: String, default: null }) background!: string
  @Prop({ type: String, default: null }) help!: string
  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ type: Boolean, default: false }) hideBackButton!: boolean
  @Prop({ type: Boolean, default: false }) hideDeleteButton!: boolean
  @Prop({ type: Boolean, default: false }) hideSaveButton!: boolean
  @Prop({ type: Boolean, default: false }) createAndNew!: boolean
  @Prop({ type: Boolean, default: false }) hideResetButton!: boolean
  @Prop({ type: Boolean, default: false }) hideActions!: boolean
  @Prop({ type: Boolean, default: true }) redirectOnCreate!: boolean
  @Prop({ default: () => () => new Promise((resolve, reject) => resolve(true)) }) onBeforeSave!: (model: any) => Promise<boolean>
  @Prop({ default: () => () => new Promise((resolve, reject) => resolve(true)) }) onAfterSave!: (model: any) => Promise<boolean>

  formErrors = {}
  formIsValid = false
  isCollapsed = false
  rules = {};
  beforeResolve: () => void = () => ({})

  get hasActiveState(): boolean {
    return Object.values(this.model.states).every(Boolean) || this.loading;
  }

  get canDelete(): boolean {
    return !this.hasActiveState && !this.model.data.deleted && this.model.data.id;
  }

  get canRestore(): boolean {
    return !this.hasActiveState && this.model.data.deleted && this.model.data.id;
  }

  get canReset(): boolean {
    return !this.hasActiveState && this.model.isDifferentFromOriginal();
  }

  get canSubmit(): boolean {
    return !this.hasActiveState && this.formIsValid && this.model.isDifferentFromOriginal();
  }

  get canGoBack(): boolean {
    return !this.hideBackButton && !!(this.$route.matched[this.$route.matched.length - 1].parent);
  }

  timeoutCallback(fn: any, timeout = 0): number {
    return setTimeout(fn, timeout);
  }

  onGoBackClick(): void {
    if (this.parentRoute) {
      this.$router.push(this.parentRoute);
      return;
    }
    const parent = this.$route.matched[this.$route.matched.length - 1].parent;
    if (parent) {
      this.$router.push({ name: parent.name, params: this.$route.params });
    }
  }

  onToggleContent(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  onSubmit(event: Event): Promise<any> {
    if (event) {
      event.preventDefault();
    }
    return this.onBeforeSave(this.model).then(response => {
      if (response) {
        this.model.states.saving = true;
        this.formErrors = {};
        return this.service.save(this.model)
          .then(response => {
            const wasNew = !(this.model.data.id);
            this.model.assign(response.data.view.single);
            this.$root.$globalSnack.success({
              message: this.$i18n.t(wasNew ? 'dataForm.snack.itemCreated' : 'dataForm.snack.itemSaved')
            });
            this.onAfterSave(response.data.view.single);
            if (wasNew) {
              if (this.createAndNew) {
                this.$router.replace({ name: this.$route.name || '', query: { ...this.$route.query, refresh: new Date().getTime().toString() } });
              } else if (this.redirectOnCreate) {
                this.$router.replace({ name: (this.$route.name || '').replace('New', 'Form'), params: { ...this.$route.params, id: this.model.data.id } })
              }
            }
            return this.model;
          })
          .catch(reason => {
            this.$root.$zemit.handleError(reason, this.formErrors);
            this.$emit('catch', this.formErrors);
          })
          .finally(() => this.model.states.saving = false);
      }
    });
  }

  onDeleteClick(event: Event): void {
    if (event) {
      event.preventDefault();
    }

    this.$root.$shouldTakeAction.askDelete({
      onAccept: () => {
        this.model.states.deleting = true;
        this.formErrors = {};
        return this.service.delete({id: this.model?.data.id})
          .then(response => {
            this.model.assign(response.data.view.single, specificKeys);
            this.$root.$globalSnack.success({
              message: this.$i18n.t('dataForm.snack.itemDeleted')
            });
            this.$emit('delete', this.model);
            return this.model;
          })
          .catch(reason => this.$root.$zemit.handleError(reason, this.formErrors))
          .finally(() => this.model.states.deleting = false);
      }
    })
  }

  onRestoreClick(event: Event): void {
    if (event) {
      event.preventDefault();
    }

    this.$root.$shouldTakeAction.askRestore({
      onAccept: () => {
        this.model.states.restoring = true;
        this.formErrors = {};
        return this.service.restore({id: this.model?.data.id})
          .then(response => {
            this.model.assign(response.data.view.single, specificKeys);
            this.$root.$globalSnack.success({
              message: this.$i18n.t('dataForm.snack.itemRestored')
            });
            return this.model;
          })
          .catch(reason => this.$root.$zemit.handleError(reason, this.formErrors))
          .finally(() => this.model.states.restoring = false);
      }
    });
  }

  onResetClick(): void {
    this.$root.$shouldTakeAction.ask({
      title: 'Reset data',
      body: 'Are you sure you want to reset this form data to its original state?',
      actionText: 'Reset',
      color: null,
      dark: true,
      onAccept: () => {
        this.model.revertData()
        this.formErrors = {};
      }
    });
  }

  onSubmitClick(event: Event): Promise<Model> {
    return this.onSubmit(event);
  }

  onBeforeUnload(event: Event): void | boolean {
    if (this.model.isDifferentFromOriginal()) {
      event.preventDefault();
      return true;
    }
  }

  onBeforeResolve(from: Route, to: Route, next: any) {
    if (this.model.isDifferentFromOriginal() && !lockLeave) {
      console.log('changes', this.model.getDifferences());
      lockLeave = true;
      this.$root.$shouldTakeAction.ask({
        title: this.$i18n.t('dataForm.beforeLeaveRoute.title'),
        body: this.$i18n.t('dataForm.beforeLeaveRoute.body'),
        actionText: this.$i18n.t('btn.leave'),
        color: 'warning',
        dark: true,
        onAccept: () => {
          next();
          lockLeave = false;
        }
      });
    } else {
      lockLeave = false;
      next();
    }
  }

  destroyed() {
    lockLeave = false;
    window.removeEventListener('beforeunload', this.onBeforeUnload);
    this.beforeResolve();
  }

  created() {
    this.isCollapsed = this.collapsed;

    window.addEventListener('beforeunload', this.onBeforeUnload);
    if (this.beforeResolve) {
      this.beforeResolve();
    }
    this.beforeResolve = this.$router.beforeResolve(this.onBeforeResolve);

    // Validations
    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required'),
      email: (value: string) => Rules.email(value) || this.$t('rules.email'),
    };
  }
}
</script>
