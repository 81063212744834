<template>
  <ListView
    v-bind="$attrs"
    title="Record AI Status"
    icon="mdi-robot"
    :headers="headers"
    :service="service"
    :query="filters"
    searchable
    index="record_ai_status"
    hide-presets
    hide-collapsable
    hide-new-button
  >
    <template v-slot:item.Record.pid="{item}">
      <span>
        {{ item.data.recordentity.data.pid }}
      </span>
    </template>
    <template v-slot:item.Record.screeningStatus="{item}">
      <v-chip label small :color="item.data.recordentity.data.screeningStatus | color('status')">
        {{ item.data.recordentity.data.screeningStatus }}
      </v-chip>
    </template>

    <template v-slot:item.airequestentity.model="{item}">
      <v-chip label small>
        {{ item.data.airequestentity.data.model }}
      </v-chip>
    </template>

    <template #item.type="{ item }">
      <v-chip label small :color="item.data.type | color('stage')">
        {{ item.data.type }}
      </v-chip>
    </template>
    <template #item.status="{ item }">
      <v-chip label small :color="item.data.status | color('status')">
        {{ item.data.status }}
      </v-chip>
    </template>
    <template #item.passProbability="{ item }">
      {{ item.data.passProbability }}%
    </template>
    <template #item.clarityScore="{ item }">
      {{ item.data.clarityScore }}%
    </template>
    <template #item.confidence="{ item }">
      {{ item.data.confidence }}%
    </template>
    <template #item.analysis="{ item }">

      <div>
        <div
          v-text="item.data.analysis"
          style="width: 30rem; max-height: 5rem"
          class="overflow-auto"
        />
        <div v-if="item.data.uncertaintyReason">
          <strong>Uncertainty Reason:</strong>
          {{ item.data.uncertaintyReason }}
        </div>
        <div v-if="item.data.exclusionReason">
          <strong>Exclusion Reason:</strong>
          {{ item.data.exclusionReason }}
        </div>
      </div>
    </template>
    <template #item.rating="{ item }">
      <v-rating
        v-model.number="item.data.rating"
        hover
        color="warning"
        background-color="warning"
        large
        readonly
      ></v-rating>
    </template>
  </ListView>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Prop } from 'vue-property-decorator';
import ListView from '@/modules/common/components/ListView.vue';
import RecordAiStatusService from '@/services/record-ai-status.service';

@Component({
  components: {
    ListView
  }
})
export default class RecordUserStatusList extends Vue {

  @Prop({ default: () => ([]) }) filters!: Array<{
    field: string,
    operator: string,
    value: any,
  }>

  service = RecordAiStatusService.getInstance();
  headers = [
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Record',
      align: 'start',
      sortable: true,
      value: 'Record.pid',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'AI Model',
      align: 'start',
      value: 'airequestentity.model',
      sortable: false,
      filterable: false,
    },
    {
      text: 'Stage',
      align: 'start',
      sortable: true,
      value: 'type',
      filterable: {
        type: 'enum',
        items: [
          { text: 'Screening', value: 'screening' },
          { text: 'Indepth', value: 'indepth' },
          { text: 'Final', value: 'final' },
        ],
      }
    },
    {
      text: 'Screening',
      align: 'start',
      sortable: true,
      value: 'Record.screeningStatus',
      filterable: {
        type: 'enum',
        items: [
          { text: 'Screening', value: 'screening' },
          { text: 'Indepth', value: 'indepth' },
          { text: 'Final', value: 'final' },
        ],
      }
    },

    {
      text: 'AI Status',
      align: 'start',
      sortable: true,
      value: 'status',
      filterable: {
        type: 'enum',
        items: [
          { text: 'Pending', value: 'pending' },
          { text: 'Pass', value: 'pass' },
          { text: 'Halt', value: 'halt' },
          { text: 'Skip', value: 'skip' },
        ],
      }
    },
    {
      text: 'Pass Probability',
      align: 'start',
      sortable: true,
      value: 'passProbability',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Clarity Score',
      align: 'start',
      sortable: true,
      value: 'clarityScore',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Analysis',
      align: 'start',
      sortable: true,
      value: 'analysis',
      filterable: false,
    },
    {
      text: 'Date',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    },
  ];
}
</script>
