<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-subheader>Description</v-subheader>
        <v-card :flat="!project.data.description">
          <v-card-text>
            <div v-html="project.data.description || 'Not defined yet.'" class="last-p-mb-0"></div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="py-0 py-md-3">

        <!-- OBJECTIVES -->
        <v-subheader>Objectives</v-subheader>
        <v-alert v-if="filteredObjectiveList.length === 0">
          No objectives found.
        </v-alert>
        <v-expansion-panels v-else multiple>
          <v-expansion-panel
            v-for="item in filteredObjectiveList"
            :key="item.key"
            :disabled="project.data[item.key].length === 0"
          >
            <v-expansion-panel-header>
              {{ item.label }}
              <div class="text-right">
                <v-chip v-if="project.data[item.key].length === 0" class="mr-2" x-small label>Undefined</v-chip>
                <v-chip v-else color="primary" class="mr-2" small label>{{ project.data[item.key].length }}</v-chip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li
                  v-for="str in project.data[item.key]"
                  v-html="$options.filters?.nl2br(str)"
                  :key="str"
                ></li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- CRITERIA -->
        <v-subheader>Criteria</v-subheader>
        <v-alert v-if="filteredCriteriaList.length === 0">
          No criteria found.
        </v-alert>
        <v-expansion-panels multiple>
          <v-expansion-panel
            v-for="item in filteredCriteriaList"
            :key="item.key"
            :disabled="project.data[item.key].length === 0"
          >
            <v-expansion-panel-header>
              {{ item.label }}
              <div class="text-right">
                <v-chip v-if="project.data[item.key].length === 0" class="mr-2" x-small label>Undefined</v-chip>
                <v-chip v-else color="primary" class="mr-2" small label>{{ project.data[item.key].length }}</v-chip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li
                  v-for="str in project.data[item.key]"
                  v-html="$options.filters?.nl2br(str)"
                  :key="str"
                ></li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="6">

        <!-- PICOTS -->
        <v-subheader>PICOTS</v-subheader>
        <v-alert v-if="filteredPicotList.length === 0">
          No PICOTS found.
        </v-alert>
        <v-expansion-panels multiple>
          <v-expansion-panel
            v-for="item in filteredPicotList"
            :key="item.key"
            :disabled="project.data[item.key].length === 0"
          >
            <v-expansion-panel-header>
              {{ item.label }}
              <div class="text-right">
                <v-chip v-if="project.data[item.key].length === 0" class="mr-2" x-small label>Undefined</v-chip>
                <v-chip v-else color="primary" class="mr-2" small label>{{ project.data[item.key].length }}</v-chip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li
                  v-for="str in project.data[item.key]"
                  v-html="$options.filters?.nl2br(str)"
                  :key="str"
                ></li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Vue, Component, VModel } from 'vue-property-decorator';
import { objectiveList, picotList, criteriaList } from '@/enums/global';
import ProjectModel from '@/models/project.model';

@Component
export default class ProjectInformationInner extends Vue {
  @VModel({ type: ProjectModel, default: () => new ProjectModel() }) project!: ProjectModel;

  objectiveList = objectiveList;
  criteriaList = criteriaList;
  picotList = picotList;

  get filteredObjectiveList(): { label: string, key: string }[] {
    return objectiveList.filter(item => this.project.data[item.key].length > 0);
  }

  get filteredCriteriaList(): { label: string, key: string }[] {
    return criteriaList.filter(item => this.project.data[item.key].length > 0);
  }

  get filteredPicotList(): { label: string, key: string }[] {
    return picotList.filter(item => this.project.data[item.key].length > 0);
  }
}
</script>
