<script lang="ts">
import {Vue, Component, VModel, Watch, Prop} from 'vue-property-decorator';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import KeywordModel from '@/models/keyword.model';
import SynonymModel from '@/models/synonym.model';
import {debounce} from 'debounce';
import KeywordService from '@/services/keyword.service';
import AiService from '@/services/ai.service';

@Component({
  components: {
    ModalDialog
  }
})
export default class KeywordLookupModal extends Vue {
  @VModel({ type: Boolean, default: false }) visible!: boolean
  @Prop({ type: KeywordModel, default: () => new KeywordModel() }) keyword!: KeywordModel
  @Prop({ type: String, default: null }) defaultQuery!: string
  @Prop({ type: String, default: null }) defaultColor!: string

  previousSearch: {[key: string]: string | null} = {
    previous: null,
    word: null,
    ai: null,
  }

  panel: number | null = 0;
  query: string | null = null;
  aiSynonymLoading = false;
  aiSynonymList: Array<string> = [];
  previousSynonymLoading = false;
  previousSynonymList: Array<SynonymModel> = [];
  previousKeywordList: Array<KeywordModel> = [];

  @Watch('panel')
  onSuggestionDialogPanelChange() {
    this.onSearchKeyUp.clear();
    this.search();
  }

  @Watch('visible')
  onDialogVisible(visible: boolean) {
    if (visible) {
      this.panel = 0;
      this.query = this.defaultQuery;
    }
    this.search();
  }

  get isSearching(): boolean {
    return this.query !== null
      && this.query.trim().length > 0;
  }

  get _aiSynonymList(): Array<string> {
    return this.aiSynonymList.filter(item => {
      return this.aiSynonymList.indexOf(item) !== -1;
    });
  }

  onSearchKeyUp = debounce(() => {
    this.search();
  }, 500);

  onSearchKeyUpEnter(): void {
    this.onSearchKeyUp.clear();
    this.search();
  }

  addSynonymLabel(labelList: Array<string>, index: number) {
    const label = labelList[index];

    // Check if the synonym is already in the current list
    const item = this.keyword.data.synonymlist.find((model: SynonymModel) => model.data.label === label);

    // Synonym exists, make sure it's active
    if (item) {
      item.data.deleted = 0;
    }

    // Synonym doesn't exists, append new synonym
    else {
      this.keyword.data.synonymlist.push(new SynonymModel({
        label: labelList[index],
        projectId: this.keyword.data.projectId,
        color: this.keyword.data.color || this.defaultColor,
        caseSensitive: this.keyword.data.caseSensitive || false,
        wordOnly: this.keyword.data.wordOnly || false,
        regexp: this.keyword.data.regexp || false,
        deleted: 0,
      }));
    }

    // Remove from the suggestion list
    labelList.splice(index, 1);
  }

  addPreviousSynonym(synonymList: Array<SynonymModel|KeywordModel>, index: number) {
    const synonym = synonymList[index];

    // Check if the synonym is already in the current list
    const item = this.keyword.data.synonymlist.find((model: SynonymModel) => model.data.label === synonym.data.label);

    // Synonym exists, make sure it's active
    if (item) {
      item.data.deleted = 0;
    }
    // Synonym doesn't exists, append new synonym
    else {
      this.keyword.data.synonymlist.push(new SynonymModel({
        label: synonym.data.label,
        projectId: this.keyword.data.projectId,
        color: synonym.data.color || this.defaultColor,
        caseSensitive: synonym.data.caseSensitive || false,
        regexp: synonym.data.regexp || false,
        deleted: 0,
      }));
    }

    // Remove from the suggestion list
    synonymList.splice(index, 1);
  }

  loadPreviousSynonymList(label: string | null) {
    if (this.previousSearch.previous === label) {
      return;
    }
    this.previousSearch.previous = label;
    this.previousSynonymList = [];

    if (label) {
      this.previousSynonymLoading = true;
      KeywordService.getInstance().getAll({
        filters: [
          [
            [
              { field: 'label', value: label, operator: 'contains word' },
              { field: 'deleted', value: 1, operator: 'does not equal' },
            ],
            // or
            [
              { field: 'Synonym.label', value: label, operator: 'contains word' },
              { field: 'Synonym.deleted', value: 1, operator: 'does not equal' },
            ]
          ],
          // and
          {
            field: 'id',
            value: this.keyword.data.id || '',
            operator: 'does not equal'
          },
        ],
      }).then((response) => {
        if (response.data.view.list) {
          response.data.view.list.forEach((keyword: KeywordModel) => {
            this.previousSynonymList = [...this.previousSynonymList, ...keyword.data.synonymlist].filter((value, index, self) =>
                index === self.findIndex((t) => (
                  t.data.label === value.data.label
                ))
            ).filter((item: KeywordModel) => { // Remove existing synonyms
              return !this.keyword.data.synonymlist.find((model: SynonymModel) => !model.data.deleted && model.data.label === item.data.label);
            }).sort((a: KeywordModel, b: KeywordModel) => { // Sort alphabetically (color, label)
              return a.data.color === b.data.color
                ? a.data.label.trim().toLowerCase() < b.data.label.trim().toLowerCase() ? -1 : 1
                : a.data.color < b.data.color ? -1 : 1;
            });
          });
        } else {
          this.$root.$globalSnack.warning({
            message: 'An unexpected error has occurred while trying to load the previous projects synonym list.',
            icon: 'mdi-emoticon-dead-outline'
          });
        }
      }).catch(reason => this.$root.$zemit.handleError(reason))
        .finally(() => this.previousSynonymLoading = false);
    }
  }

  loadAiSynonymList(label: string | null) {
    if (this.previousSearch.ai === label) {
      return;
    }

    this.previousSearch.ai = label;
    this.aiSynonymList = [];
    if (label) {
      this.aiSynonymLoading = true;
      AiService.getInstance().getKeywordSuggestions({label: label})
        .then(response => {
          this.aiSynonymList = response.data.view.list.map((item: any) => item.data.suggestion);
        }).catch(reason => this.$root.$zemit.handleError(reason))
        .finally(() => this.aiSynonymLoading = false);
    }
  }

  loadSuggestions(keyword: string | null) {
    switch (this.panel) {
      case 0: this.loadPreviousSynonymList(keyword); break;
      case 1: this.loadAiSynonymList(keyword); break;
    }
  }

  search() {
    if (this.query) {
      this.loadSuggestions(this.query);
    }
  }
}
</script>

<template>
  <ModalDialog
    v-model="visible"
    width="680"
    title="Keyword Lookup"
    scrollable
    background-color="background"
  >
    <template #body>

      <!-- QUERY -->
      <v-text-field
        v-model="query"
        :placeholder="$t('keywordForm.filterKeywords')"
        prepend-inner-icon="mdi-magnify"
        solo
        hide-details
        clearable
        autofocus
        class="mt-6 mb-4"
        @keyup.enter="onSearchKeyUpEnter"
        @keyup="onSearchKeyUp"
      />

      <v-expansion-panels v-model="panel">

        <!-- PREVIOUS RECORDS -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Previous Records
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-if="!previousSynonymLoading">
              <v-alert
                v-if="previousKeywordList.length === 0 && previousSynonymList.length === 0"
                class="mb-0"
                text
                type="info"
              >
                <span v-if="isSearching" v-text="$t('keywordForm.searchEmpty')"></span>
                <span v-else v-text="$t('keywordForm.empty')"></span>
              </v-alert>
              <v-chip-group v-else column>
                <v-chip
                  v-for="(synonym, wordIndex) in previousSynonymList"
                  :key="synonym.data.label + '_synonym'"
                  label
                  outlined
                  @click="addPreviousSynonym(previousSynonymList, wordIndex)"
                >
                  <div class="d-flex align-center" style="gap: 0.5rem; max-width: 10rem">
                    <v-icon :color="synonym.data.color" :size="20">mdi-circle</v-icon>
                    <div class="text-truncate">
                      <span>{{ synonym.data.label }}</span>
                    </div>
                  </div>
                </v-chip>
              </v-chip-group>
            </div>
            <div v-else>
              <v-progress-linear
                indeterminate
              ></v-progress-linear>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- OPEN AI -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Open AI
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-if="!aiSynonymLoading">
              <v-alert
                v-if="_aiSynonymList.length === 0"
                class="mb-0"
                text
                type="info"
              >
                <span v-if="isSearching" v-text="$t('keywordForm.searchEmpty')"></span>
                <span v-else v-text="$t('keywordForm.empty')"></span>
              </v-alert>
              <v-chip-group v-else column>
                <v-chip
                  v-for="(item, itemIdx) in _aiSynonymList"
                  :key="item"
                  outlined
                  label
                  @click="addSynonymLabel(aiSynonymList, itemIdx)"
                >
                  <div class="d-flex align-center" style="gap: 0.5rem; max-width: 10rem">
                    <v-icon :size="20">mdi-plus-circle</v-icon>
                    <div class="text-truncate">
                      <span>{{ item }}</span>
                    </div>
                  </div>
                </v-chip>
              </v-chip-group>
            </div>
            <div v-else>
              <v-progress-linear
                indeterminate
              ></v-progress-linear>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <template #buttons>
      <v-btn outlined @click="visible = false">
        <span v-text="$t('btn.close')"></span>
      </v-btn>
    </template>
  </ModalDialog>
</template>
