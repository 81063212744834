import {keyedUserTypeList} from '@/enums/global'
import Model from '@/modules/sdk/core/model'
import UserModel from '@/modules/sdk/models/user.model'

export default class ProjectUserModel extends Model
{

  relatedMap() {
    return {
      userentity: UserModel,
    }
  }

  get label() {
    return this.getLabel()
  }

  getLabel() {
    return this.data.userentity.getLabel() + ' (' + (keyedUserTypeList[this.data.type] || 'Unknown') + ')'
  }

}
