<script lang="ts">
import { Component, Prop, VModel, Watch } from 'vue-property-decorator';
import PopupCommunicatorMixin from '@/modules/common/mixins/popup-communicator';

interface IRoute { name: string, params?: any }

@Component
export default class PopupCommunicatorHost extends PopupCommunicatorMixin {
  @VModel({ type: Boolean, default: false }) opened!: boolean;
  @Prop({ type: String, default: 'unknown' }) id!: string;
  @Prop({ type: String, default: 'PopupCommunicator' }) title!: string;
  @Prop({ type: Object, default: null }) route!: IRoute;
  @Prop({ type: Object, default: () => ({}) }) props!: any;
  @Prop({ type: Number, default: 800 }) width!: number;
  @Prop({ type: Number, default: 600 }) height!: number;
  @Prop({ type: String, default: 'no' }) menubar!: string;
  @Prop({ type: String, default: 'no' }) toolbar!: string;
  @Prop({ type: String, default: 'no' }) location!: string;
  @Prop({ type: String, default: 'no' }) status!: string;

  child: Window | null = null

  @Watch('opened')
  @Watch('id')
  @Watch('path')
  onVisibleChange(): void {
    if (this.opened && this.id && !this.child) {
      this.open(this.id, this.route);
    }
  }

  @Watch('$vuetify.theme.dark', { immediate: true })
  onDarkThemeChange(dark: boolean) {
    this.child?.postMessage(JSON.stringify({
      action: 'theme',
      data: dark,
    }));
  }

  @Watch('props', { immediate: true })
  onPropChanged() {
    this.sendProps(this.child);
  }

  // eslint-disable-next-line no-undef
  sendProps(source: MessageEventSource | Window | null) {
    if (source) {
      source.postMessage(JSON.stringify({
        action: 'props',
        data: this.propsToData(this.props),
      }));
    }
  }

  open(id: string, route: IRoute) {
    const path = this.$router.resolve(route);
    const params: {[key: string]: string} = {
      width: this.height.toString(),
      height: this.height.toString(),
      menubar: this.menubar,
      toolbar: this.toolbar,
      location: this.location,
      status: this.status,
    }
    this.child = window.open(window.location.origin + path.href, id, Object.keys(params).map(key => key + '=' + params[key]).join(','));
    if (this.child) {
      window.addEventListener('message', this.listen);
    }
  }

  close() {
    window.removeEventListener('message', this.listen);

    this.child?.close();
    this.child = null;
    this.opened = false
  }

  listen(event: MessageEvent) {
    if (event.origin === window.location.origin) {
      try {
        const data = JSON.parse(event.data);
        switch (data.action) {
          case 'beforeUnload': this.close(); break;
          case 'getProps': this.sendProps(event.source); break;
        }
      } catch (e) {

      }
    }
  }

  destroyed() {
    this.close();
  }
}
</script>
