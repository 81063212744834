import TagService from '@/services/tag.service';
import TagModel from '@/models/tag.model';
import ProjectService from '@/services/project.service';
import KeywordService from '@/services/keyword.service';
import KeywordModel from '@/models/keyword.model';
import ProjetModel from '@/models/project.model';
import CommentModel from '@/models/comment.model';
import CommentService from '@/services/comment.service';
import {stageList} from '@/enums/global';

export class SharedQuery {

  static getProjectComments(projectId: number, userId: number | null): Promise<CommentModel[]> {
    return CommentService.getInstance().getAll({
      filters: [{
        field: 'recordentity.projectId',
        value: projectId,
        operator: 'equals'
      }, [
        [
          {
            logic: 'and',
            field: 'userId',
            value: userId,
            operator: 'equals',
          }
        ], [
          {
            logic: 'or',
            field: 'private',
            value: 0,
            operator: 'equals',
          }, {
            logic: 'and',
            field: 'userId',
            value: userId,
            operator: 'not equal',
          }
        ]
      ]
    ]})
      .then(response => response.data.view.list)
  }

  static getProject(projectId: number): Promise<ProjetModel> {
    return ProjectService.getInstance().get({
      id: projectId
    }).then(response => response.data.view.single);
  }

  static getProjectKeywords(projectId: number): Promise<Array<KeywordModel>> {
    return KeywordService.getInstance().getAll({
      filters: [{
        field: 'projectId',
        value: projectId,
        operator: 'equals'
      }]
    }).then(response => response.data.view.list);
  }

  static getProjectTagsByCategoryId(
    projectId: number,
    categoryId?: number,
    stage?: 'screening' | 'indepth' | 'final',
    userId?: number,
  ): Promise<Array<TagModel>> {
    const filters: any[] = [{
      field: 'recordnode.projectId',
      value: projectId,
      operator: 'equals',
    }, {
      field: 'recordnode.categoryId',
      value: categoryId,
      operator: categoryId ? 'equals' : 'is null',
    }];
    if (stage) { // Filter by passed stage and lower
      const stageFilterList: any = structuredClone(stageList)
        .splice(0, stageList.findIndex(item => item.value === stage) + 1)
        .map(item => ({
          field: 'recordnode.stage',
          value: item.value,
          operator: 'equals',
        }));
      filters.push(stageFilterList);
    }
    if (userId) {
      filters.push({
        field: 'createdBy',
        value: userId,
        operator: 'equals',
      });
    }
    return TagService.getInstance().getAll({ filters })
      .then(response => response.data.view.list);
  }

  static getAllProjectTags(
    projectId: number,
    userId?: number,
  ): Promise<Array<TagModel>> {
    const filters = [{
      field: 'recordnode.projectId',
      value: projectId,
      operator: 'equals',
    }];
    if (userId) {
      filters.push({
        field: 'createdBy',
        value: userId,
        operator: 'equals',
      });
    }
    return TagService.getInstance().getAll({ filters })
      .then(response => response.data.view.list);
  }
}
