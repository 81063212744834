import { render, staticRenderFns } from "./RecordComponent.vue?vue&type=template&id=2684ff84&scoped=true"
import script from "./RecordComponent.vue?vue&type=script&lang=ts"
export * from "./RecordComponent.vue?vue&type=script&lang=ts"
import style0 from "./RecordComponent.vue?vue&type=style&index=0&id=2684ff84&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2684ff84",
  null
  
)

export default component.exports