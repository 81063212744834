<template>
  <div>
    <ModalDialog
      v-model="syncedVisible"
      :title="$t('ReviewView.export.title') + ' - ' + project.getLabel()"
      :persistent="loading"
      icon="mdi-file-export-outline"
      max-width="1000"
      scrollable
    >
      <template #content>
        <v-card-text class="background px-0 pb-0">
          <v-container>
            <v-row dense>
              <v-col cols="12" md="6">

                <!-- PROJECT DATA -->
                <v-card>
                  <v-card-title>
                    <span>Project Data</span>
                  </v-card-title>
                  <v-card-text>
                    <v-alert
                      v-if="projectDataList.length === 0"
                      class="mb-0"
                      type="info"
                      color="info"
                      colored-border
                      text
                    >
                      <span>No project data available</span>
                    </v-alert>
                    <template v-else>
                      <v-treeview
                        v-model="projectData"
                        :items="projectDataList"
                        selected-color="primary"
                        selection-type="leaf"
                        selectable
                        open-all
                        return-object
                      />
                    </template>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">

                <!-- USER REVIEW STATUS -->
                <v-card>
                  <v-card-title class="d-flex align-center justify-space-between">
                    <span>Review Status</span>
                  </v-card-title>
                  <v-card-text>

                    <v-autocomplete
                      label="Users"
                      v-model="projectUserList"
                      :items="project.data.usernode"
                      item-text="label"
                      hide-details="auto"
                      chips
                      small-chips
                      deletable-chips
                      multiple
                      outlined
                      dense
                      clearable
                      return-object
                    >
                      <template #prepend-item>
                        <v-list-item @click.stop="toggleSelectAllUserClick">
                          <v-list-item-action>
                            <v-checkbox v-model="allUserSelected"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Select all
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider/>
                      </template>
                    </v-autocomplete>

                    <v-divider class="my-4"/>

                    <v-autocomplete
                      label="AI"
                      v-model="aiList"
                      :items="gptModelList"
                      item-text="text"
                      hide-details="auto"
                      chips
                      small-chips
                      deletable-chips
                      multiple
                      outlined
                      dense
                      clearable
                      return-object
                    >
                      <template #prepend-item>
                        <v-list-item @click.stop="toggleSelectAllAiClick">
                          <v-list-item-action>
                            <v-checkbox v-model="allAiSelected"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Select all
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider/>
                      </template>

                      <template #item="{ item, attrs }">
                        <v-list-item-action>
                          <v-checkbox v-model="attrs.inputValue"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-card-text>
                </v-card>

                <!-- SURVEY QUESTIONS -->
                <v-card class="mt-3">
                  <v-card-title>
                    <span>Questions</span>
                  </v-card-title>
                  <v-card-text>
                    <v-alert
                      v-if="surveyQuestionList.length === 0"
                      class="mb-0"
                      type="info"
                      color="info"
                      colored-border
                      text
                    >
                      <span v-text="$t('ReviewView.noQuestionAvailable')"></span>
                    </v-alert>
                    <template v-else>

                      <!-- ANSWERS FROM USERS -->
                      <v-autocomplete
                        v-model="selectedQuestionUserList"
                        :items="questionUserList"
                        :loading="questionUserListLoading"
                        :disabled="questionUserListLoading"
                        label="Answer(s) from"
                        prepend-inner-icon="mdi-account"
                        class="mb-3"
                        multiple
                        clearable
                        outlined
                        hide-details
                        dense
                        return-object
                      />

                      <!-- SURVEY TREE -->
                      <v-treeview
                        v-model="surveyQuestion"
                        :items="surveyQuestionList"
                        selected-color="primary"
                        selection-type="leaf"
                        selectable
                        open-all
                        return-object
                      />
                    </template>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </template>

      <template #buttons>
        <v-select
          v-model="exportType"
          :items="typeList"
          :label="$t('exportComponent.type')"
          style="max-width: 15rem"
          hide-details
          outlined
          dense
        />
        <v-spacer />
        <v-btn
          :loading="loading"
          :disabled="!canExport"
          color="primary"
          large
          @click="exportCsv"
        >
          <span v-text="$t('btn.export')"></span>
        </v-btn>
        <v-btn
          outlined
          large
          @click="close"
        >
          <span v-text="$t('btn.cancel')"></span>
        </v-btn>
      </template>
    </ModalDialog>
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import ProjectUserModel from '@/models/project-user.model'
import { Vue, Component, VModel, Prop, PropSync, Watch } from 'vue-property-decorator';
import {fieldList, recordRelatedFieldList, officialStatusFieldList, gptModelList} from '@/enums/global'
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import ProjectModel from '@/models/project.model';
import ArticleModel from '@/models/article.model';
import ArticleService from '@/services/article.service';
import UserModel from '@/modules/sdk/models/user.model';
import SurveyModel from '@/modules/sdk/models/survey.model';

@Component({
  components: {
    ModalDialog
  }
})
export default class ExportComponent extends Vue {
  @VModel({ type: ProjectModel, default: () => new ProjectModel() }) project!: ProjectModel
  @PropSync('visible', { type: Boolean, default: false }) syncedVisible!: boolean;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: String, default: null }) userType!: string;

  userLoading = false;
  articleLoading = false;
  questionUserListLoading = false;
  articleList: Array<ArticleModel> = [];
  projectData: any[] = []
  surveyQuestion: any[] = []
  exportType = 'xlsx'
  projectUserList: ProjectUserModel[] = []
  aiList: any[] = []
  selectedQuestionUserList: any[] = []
  questionUserList: UserModel[] = []
  typeList = [
    { text: 'XLSX', value: 'xlsx' },
    { text: 'CSV', value: 'csv' },
    { text: 'JSON', value: 'json' },
  ]

  @Watch('syncedVisible', {
    immediate: true,
  })
  onVisibleChanged(visible: boolean) {
    if (visible) {
      this.clear();
    }
    if (visible && !this.articleLoading) {
      this.loadArticles();
    }
    if (visible && !this.questionUserListLoading) {
      this.loadQuestionUserList();
    }
  }

  get gptModelList() {
    return gptModelList.filter(item => !item.replacement);
  }

  get projectDataList(): any[] {
    const items: any[] = [];
    if (fieldList.length > 0) {
      items.push({
        id: 'field_all',
        name: 'Fields',
        children: fieldList.map(item => ({
          id: 'field_' + item.value,
          type: 'field',
          key: item.value,
          name: item.text,
        })),
      })
    }
    if (officialStatusFieldList.length > 0) {
      items.push({
        id: 'official_status_all',
        name: 'Official Status',
        children: officialStatusFieldList.map(item => ({
          id: 'official_status_' + item.value,
          type: 'official_status',
          key: item.value,
          name: item.text,
        })).concat({
          id: 'official_status_exclusion_reason',
          type: 'official_status',
          key: 'exclusion_reason',
          name: 'Reason(s) of Exclusion',
        }),
      })
    }
    if (recordRelatedFieldList.length > 0) {
      items.push({
        id: 'related_field_all',
        name: 'Related Fields',
        children: recordRelatedFieldList.map((item: any) => ({
          id: 'related_field_' + item.value,
          type: 'related_field',
          key: item.value,
          name: item.text,
        })),
      })
    }
    if (this.articleList.length > 0) {
      items.push({
        id: 'article_all',
        name: 'Articles',
        children: this.articleList.map(item => ({
          id: 'article_' + item.data.id,
          type: 'article',
          key: item.data.id,
          name: item.data.title,
        })),
      })
    }
    return items;
  }

  get surveyQuestionList(): any[] {
    return this.project.data.surveylist.map((survey: SurveyModel) => ({
      id: 'survey_' + survey.data.id,
      name: survey.data.label || 'Untitled (Survey)',
      children: survey.toTreeviewItems(false)
    }));
  }

  get canExport(): boolean {
    return !this.loading
      && (
        this.projectData.length > 0
        || this.surveyQuestion.length > 0
        || this.projectUserList.length > 0
        || this.aiList.length > 0
        || this.selectedQuestionUserList.length > 0
      )
  }

  get canSeeAnswerName(): boolean {
    return false;
  }

  get canSeeOfficialAnswers(): boolean {
    return ['leader', 'arbitrator'].includes(this.userType || '')
      && (
        this.project.data.type !== 'systematic_review'
        || this.project.data.stage === 'final'
      )
  }

  get allUserSelected(): boolean {
    return this.projectUserList.length === this.project.data.usernode.length;
  }

  selectAllUser() {
    this.project.data.usernode.forEach((userNodeItem: ProjectUserModel) => {
      if (this.projectUserList.findIndex((projectUserList: ProjectUserModel) => projectUserList.data.id === userNodeItem.data.id) === -1) {
        this.projectUserList.push(userNodeItem);
      }
    })
  }

  toggleSelectAllUserClick() {
    if (this.allUserSelected) {
      this.projectUserList.splice(0, this.projectUserList.length);
    } else {
      this.selectAllUser()
    }
  }

  get allAiSelected(): boolean {
    return this.aiList.length === this.gptModelList.length;
  }

  selectAllAi() {
    this.gptModelList.forEach((gptModel: any) => {
      if (this.aiList.findIndex((aiItem: any) => aiItem.value === gptModel.value) === -1) {
        this.aiList.push(gptModel);
      }
    })
  }

  toggleSelectAllAiClick() {
    if (this.allAiSelected) {
      this.aiList.splice(0, this.aiList.length);
    } else {
      this.selectAllAi()
    }
  }

  selectAllUserAiClick() {
    this.selectAllUser()
    this.selectAllAi()
  }


  loadQuestionUserList() {
    if (this.project.data.surveylist.length > 0) {
      this.questionUserListLoading = true;
      const survey = this.project.data.surveylist[0];
      survey.loadAnswerUserList()
        .then(() => {
          this.questionUserList = survey.getFilteredAnswerUserList(
            this.canSeeAnswerName,
            this.canSeeOfficialAnswers,
            this.project.data.aiUseAllowed && this.project.hasRole(['ai-manager']),
          );
        })
        .finally(() => this.questionUserListLoading = false);
    }
  }

  loadArticles(): void {
    this.articleLoading = true;
    const filters = [{
      field: 'projectId',
      value: this.project.data.id,
      operator: 'equals'
    }];
    ArticleService.getInstance().getAll({filters})
      .then((response) => this.articleList = response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.articleLoading = false);
  }

  exportCsv() {
    this.$emit('export', {
      type: this.exportType,
      data: {
        articles: this.projectData.filter(item => item.type === 'article').map(item => item.key),
        fields: this.projectData.filter(item => ['field', 'official_status', 'extra_field'].includes(item.type)).map(item => item.key),
        questions: this.surveyQuestion.filter(item => item.type === 'question').map(item => item.key),
        questionUsers: this.selectedQuestionUserList.map(item => item.value),
        projectUserList: this.projectUserList,
        aiList: this.aiList,
      }
    })
  }

  clear() {
    this.exportType = 'xlsx';
    this.articleList = []
    this.projectData = []
    this.surveyQuestion = []
  }

  open() {
    this.syncedVisible = true;
  }

  close() {
    this.syncedVisible = false;
  }
}
</script>
