<template>
  <QueryBuilder
    v-model="conditionList"
    :fields="fields"
    :relationships="relationships"
    :disabled="disabled"
    :item-values="getItemValues"
    :root="conditionList"
    :return-object="true"
    ref="queryBuilder"
    v-test-id="'query_builder'"
    @focus="onFocus"
    @blur="onBlur"
  />
</template>

<script lang="ts">
import 'reflect-metadata'
import {Vue, Component, VModel, Prop, Watch, Ref} from 'vue-property-decorator'
import ProjectStatusReasonModel from '@/models/project-status-reason.model'
import ProjectStatusReasonService from '@/services/project-status-reason.service'
import RecordService from '@/services/record.service'
import ProjectModel from '@/models/project.model'
import KeywordModel from '@/models/keyword.model'
import TagModel from '@/models/tag.model'
import SurveyGroupModel from '@/modules/sdk/models/survey-group.model'
import SurveyModel from '@/modules/sdk/models/survey.model'
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model'
import QueryBuilder from '@/modules/common/components/QueryBuilder.vue'
import CategoryModel from '@/models/category.model'
import RecordTagModel from '@/models/record-tag.model'
import UserModel from '@/modules/sdk/models/user.model'
import UserService from '@/modules/sdk/services/user.service'
import Identity from '@/modules/sdk/core/identity'
import CommentModel from '@/models/comment.model'
import SurveySectionModel from '@/modules/sdk/models/survey-section.model'
import {SharedQuery} from '@/utils/shared-query'
import {IQueryAdvanced} from '@/modules/sdk/core/interfaces'
import {IQueryItem} from '@/modules/sdk/core/query'
import {
  recordRelatedFieldList,
  fieldList,
  officialStatusFieldList,
  statusReasonFieldList,
  sourceTypeList,
  statusList,
  stageList,
  projectUserTypeList
} from '@/enums/global'

interface IField
{
  header?: string,
  text?: string,
  value?: any,
  type?: string,
  list?: string,
  children?: IField[],
  filter?: (value: any, index: number, array: any[]) => boolean,
  parentId?: number,
}

@Component({
  components: {
    QueryBuilder,
  }
})
export default class ProjectQueryBuilder extends Vue
{
  @Ref() readonly queryBuilder!: QueryBuilder
  @VModel({type: Array, default: () => []}) conditionList!: IQueryItem[]
  @Prop({type: Number, default: null}) projectId!: number
  @Prop({type: Boolean, default: false}) disabled!: boolean
  @Prop({type: Object, default: null}) mode!: any
  @Prop({type: ProjectModel, default: null}) project?: ProjectModel
  @Prop({type: Array, default: null}) comments?: Array<CommentModel>
  @Prop({type: Array, default: null}) keywords?: Array<KeywordModel>
  @Prop({type: Array, default: null}) tags?: Array<TagModel>
  @Prop({
    type: Object, default: () => ({
      stage: null,
      status: null,
      userId: null,
      userStatus: null,
      reviewed: null,
      order: [],
      orderAsc: []
    })
  }) advanced!: IQueryAdvanced

  loading = false
  innerProject: ProjectModel | null = null
  innerKeywords: Array<KeywordModel> = []
  innerComments: Array<CommentModel> = []
  innerTags: Array<TagModel> = []
  projectUserList: Array<UserModel> = []
  projectStatusReasonList: Array<ProjectStatusReasonModel> = []
  languageList: Array<string> = []
  loadedList: {
    [key: string]: {
      keys?: string[],
      enabled?: () => boolean,
      loaded: boolean,
    }
  } = {
    comments: {
      keys: [],
      loaded: false,
    },
    project: { // always loaded
      loaded: false,
    },
    keywords: {
      keys: [],
      loaded: false,
    },
    tags: {
      keys: [],
      loaded: false,
    },
    languages: {
      keys: [],
      loaded: false,
    },
    users: { // loaded on condition
      keys: [],
      enabled: () => this.includeUserList,
      loaded: false,
    },
    projectStatusReasons: {
      keys: [],
      loaded: false,
    },
  }

  @Watch('projectId')
  onProjectIdChange() {
    this.clear()
    this.loadIfRequired()
  }

  @Watch('conditionList', { deep: true })
  onConditionListChange() {
    this.loadIfRequired()
  }

  @Watch('project', {deep: true, immediate: true})
  onProjectChanged(project: ProjectModel) {
    if (project) {
      this.innerProject = project
    }
  }

  @Watch('keywords', {deep: true})
  onKeywordsChanged(keywords: Array<KeywordModel>) {
    if (keywords) {
      this.innerKeywords = keywords
    }
  }

  @Watch('tags', {deep: true})
  onTagsChanged(tags: Array<TagModel>) {
    if (tags) {
      this.innerTags = tags
    }
  }

  @Watch('comments', {deep: true})
  onCommentsChanged(comments: Array<CommentModel>) {
    if (comments) {
      this.innerComments = comments
    }
  }

  onFocus(props: any) {
    props.condition.loading = true
    this.queryBuilder.forceUpdate()
    this.loadIfRequired().finally(() => {
      props.condition.loading = false
      this.queryBuilder.forceUpdate()
    })
  }

  onBlur(props: any) {
    props.condition.loading = false
    this.queryBuilder.forceUpdate()
  }

  get flatConditionList(): IQueryItem[] {
    function flattenQueryItems(items: IQueryItem[]): IQueryItem[] {
      const flattened: IQueryItem[] = []
      for (const item of items) {
        flattened.push(item)
        if (item.group && item.group.length > 0) {
          const nestedFlattened = flattenQueryItems(item.group)
          nestedFlattened.forEach(nested => {
            flattened.push(nested)
          })
        }
      }
      return flattened
    }

    return flattenQueryItems(this.conditionList)
  }

  get includeUserList(): boolean {
    return this.advanced.stage && this.mode && ['leader', 'arbitrator'].includes(this.mode.value)
  }

  get fields(): Array<any> {

    let fields: Array<any> = [
      {
        header: this.$i18n.t('builderComponent.fields'),
      },
      ...fieldList,
      {
        header: this.$i18n.t('builderComponent.officialStatuses'),
      },
      ...officialStatusFieldList,
      {
        header: this.$i18n.t('builderComponent.statusReasons'),
      },
      ...statusReasonFieldList
    ]

    if (this.innerProject) {

      // tags categories
      if (this.innerProject.data.categorylist && this.innerProject.data.categorylist.length) {
        fields.push({
          header: 'Eligibility Tags',
        })

        this.innerProject.data.categorylist.forEach((category: CategoryModel) => {
          fields.push({
            text: category.data.label,
            value: 'RecordTag[' + category.data.id + '].categoryId',
            joinValue: 'RecordTag[' + category.data.id + ']',
            type: 'join',
            onPrepareJoinItem: (items: IQueryItem[]) => {
              return items.map((item: IQueryItem) => ({
                ...item,
                subquery: true, // Required by backend
                field: item.field?.replace('[' + category.data.id + '][', '[' + category.data.id + '_').replace(/{[^}]*}/g, '')
              }))
            },
            onGetJoin: (item: any) => {
              const ret: {[key: string]: any[]} = {};
              ret['RecordTag[' + category.data.id + '_' + item.key + ']'] = [{
                logic: 'and',
                field: 'RecordTag[' + category.data.id + '_' + item.key + '].categoryId',
                operator: 'equals',
                value: category.data.id,
                group: [],
              }];
              return ret;
            },
            children: [{
              parentId: category.data.id,
              value: 'Tag.label{' + category.data.id + '}',
              text: category.data.label + ' (Tag)',
              list: 'tags',
            }]
          })
        })
      }

      // survey groups and questions
      if (this.innerProject.data.surveylist) {
        this.innerProject.data.surveylist.forEach((survey: SurveyModel) => {
          survey.data.surveysectionlist.forEach((section: SurveySectionModel) => {
            section.data.surveygrouplist.forEach((group: SurveyGroupModel) => {
              if (group.data.surveyquestionlist.length > 0) {
                fields.push({
                  header: this.$i18n.t('builderComponent.questions') + ' > ' + group.data.label,
                })
                group.data.surveyquestionlist.forEach((question: SurveyQuestionModel) => {
                  fields.push({
                    text: question.data.label,
                    value: 'SurveyQuestion.' + question.data.id + '.SurveyAnswer.content'
                  })
                })
              }
            })
          })
        })
      }
    }

    fields = fields.map(field => ({
      ...field,
      ...this.getValueAttrs(field),
    }))

    return fields
  }

  get relationships(): any[] {
    return recordRelatedFieldList.map(field => ({
      ...field,
      ...this.getValueAttrs(field),
    }))
  }

  filterTagsCategory(category: number | null = null) {
    return (tag: TagModel) => {
      return tag.data.recordnode && tag.data.recordnode.findIndex((recordNode: RecordTagModel) => {
        return recordNode.data.categoryId === category
      }) !== -1
    }
  }

  filterTagsByOwner(tag: TagModel) {
    return !['leader', 'arbitrator'].includes(this.mode?.value)
           ? tag.data.createdBy === Identity.getCurrentUserId()
           : true
  }

  mapTagModelToItem(tag: TagModel) {
    return {
      text: tag.data.label,
      value: tag.data.label,
      color: tag.data.color,
      tag,
    }
  }

  getItemValues(field: IField) {
    return this.getValueAttrs(field).items
  }

  getValueAttrs(field: IField): any {
    const value = field.list
    let items: Array<any> | null = null
    switch (value) {
      case 'tags':
        items = (
          field.parentId
            ? this.innerTags
              .filter(this.filterTagsCategory(field.parentId))
            : this.innerTags
              .filter(this.filterTagsCategory(null))
              .filter(this.filterTagsByOwner)
          )
          .map(model => this.mapTagModelToItem(model))
        break
      case 'stage':
        items = stageList
        break
      case 'userType':
        items = projectUserTypeList.filter(item => ['arbitrator', 'researcher', 'secondary-researcher'].includes(item.value))
        break
      case 'status':
        items = statusList
        break
      case 'statusReasons':
        items = this.projectStatusReasonList
          .map((model: ProjectStatusReasonModel) => ({
            text: model.data.label,
            value: model.data.label,
            model,
          }))
        break
      case 'sourceTypes':
        items = sourceTypeList
        break
      case 'languages':
        items = this.languageList
        break
      case 'keywords':
        items = this.innerKeywords.map(model => ({
          text: model.data.label,
          value: model.data.id,
          color: model.data.color,
          model,
        }))
        break
      case 'users':
        items = this.projectUserList.map(model => ({
          text: model.getFullName(),
          value: model.data.id,
        }))
        break
      case 'comments':
        items = this.innerComments.map(model => ({
          text: model.data.content,
          value: model.data.content,
          model,
        }))
        break
    }

    if (field && field.value?.startsWith('Category.')) {
      const categoryPath = field.value.split('.')
      items = this.innerTags
        .filter(this.filterTagsCategory(parseInt(categoryPath[1])))
        .filter(this.filterTagsByOwner)
        .map(this.mapTagModelToItem)
    }

    const type = ['id', 'pid', 'year'].includes(field.value)
                 ? 'numeric'
                 : Array.isArray(items)
                   ? 'list'
                   : (field.type || null)

    const filteredItems = (items || [])
      .filter(field.filter || function () { return true })
      .filter(item => (typeof item === 'string' && item.trim().length > 0) || item.value)

    return {
      ...field,
      items: filteredItems,
      type,
      itemColor: 'color',
    }
  }

  clear() {
    this.conditionList.splice(0, this.conditionList.length, {
      logic: 'and',
      operator: 'contains',
      field: null,
      isJoin: false,
      list: [],
      value: null,
      group: [] as any,
    })
  }

  loadIfRequired(): Promise<any> {
    return new Promise((resolve) => {
      if (this.projectId) {
        const promises: any[] = []
        this.flatConditionList.forEach(condition => {
          Object.keys(this.loadedList).forEach(listKey => {
            const list: any = this.loadedList[listKey]
            if (!list.loaded && list.keys && (list.keys.includes(condition.field) || condition.list.includes(listKey))) {
              list.loaded = true
              switch (listKey) {
                case 'comments':
                  promises.push(this.comments ||
                    SharedQuery.getProjectComments(this.projectId, Identity.getCurrentUserId())
                      .then(response => this.innerComments = response))
                  break
                case 'keywords':
                  promises.push(this.keywords ||
                    SharedQuery.getProjectKeywords(this.projectId)
                      .then(response => this.innerKeywords = response))
                  break
                case 'tags':
                  promises.push(this.tags ||
                    SharedQuery.getAllProjectTags(this.projectId)
                      .then(response => this.innerTags = response)
                  )
                  break
                case 'languages':
                  promises.push(RecordService.getInstance().getAll({
                    filters: [
                      {
                        field: 'projectId',
                        value: this.projectId,
                        operator: 'equals'
                      }
                    ],
                    group: 'language', // Distinct
                  }).then(response => {
                    const languages: Array<string> = []
                    response.data.view.list.forEach((item: any) => {
                      const newItems = []
                      if (item.data.language.indexOf(',') !== -1) {
                        newItems.push(...item.data.language.split(',').map((item: string) => item.trim()))
                      }
                      else {
                        newItems.push(item.data.language)
                      }
                      newItems.forEach((newItem: string) => {
                        if (!languages.includes(newItem)) {
                          languages.push(newItem)
                        }
                      })
                    })
                    this.languageList = languages.sort()
                  }))
                  break
                case 'statusReasons':
                  promises.push(ProjectStatusReasonService.getInstance().getAll({
                    filters: [
                      [
                        {
                          field: 'projectId',
                          value: this.projectId,
                          operator: 'equals'
                        },
                        {
                          field: 'projectId',
                          operator: 'is null'
                        }
                      ]
                    ],
                  }).then(response => this.projectStatusReasonList = response.data.view.list))
                  break
                case 'users':
                  promises.push(UserService.getInstance().getAll({
                    order: 'firstName, lastName, email',
                    advanced: {activeUserProjectId: this.projectId}
                  }).then(response => this.projectUserList = response.data.view.list))
                  break
              }
            }
            else if (listKey === 'project' && list.enabled && list.enabled()) {
              promises.push(this.project || SharedQuery.getProject(this.projectId))
            }
          })
        })

        if (promises.length > 0) {
          this.loading = true
          Promise.all(promises)
            .then(() => resolve(true))
            .catch(reason => this.$root.$zemit.handleError(reason))
            .finally(() => this.loading = false)
        }
        else {
          resolve(true)
        }
      }
    })
  }

  created() {
    this.loadIfRequired()
  }
}
</script>
