import Model from '@/modules/sdk/core/model';
import ProjectModel from '@/models/project.model';
import SurveyAnswerModel from '@/modules/sdk/models/survey-answer.model';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import RecordUserModel from '@/models/record-user.model';

export default class RecordModel extends Model {

  default() {
    return {
      authors: [],
      affiliations: [],
      references: [],
      url: [],
      surveyanswerlist: [],
      surveyquestionlist: [],
      recorduserlist: [],
      usernode: [],
    };
  }

  relatedMap() {
    return {
      projectentity: ProjectModel,
      surveyanswerlist: SurveyAnswerModel,
      surveyquestionlist: SurveyQuestionModel,
      usernode: RecordUserModel,
    }
  }

  relatedDeleteMissing() {
    return {
      surveyanswerlist: false,
      surveyquestionlist: false,
    };
  }

  getIdentifier(): string {
    return this.data.uid
      + '-' + String(this.data.projectId).padStart(4, '0')
      + '-' + String(this.data.pid).padStart(4, '0')
      + '-' + String(this.data.id).padStart(6, '0')
  }
}
