<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'notification'"
      v-model="model"
      :service="service"
      :loading="loading"
      name="notification"
      title="Notification"
    >
      <template #form="{ formErrors, rules }">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="model.data.label"
                v-safechar
                :error-messages="formErrors.label"
                :rules="[rules.required]"
                label="Notification"
                hide-details="auto"
                clearable
                required
                outlined
                @input="delete formErrors.label"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="model.data.description"
                v-safechar
                :error-messages="formErrors.description"
                :rules="[]"
                label="Description"
                hide-details="auto"
                clearable
                required
                outlined
                @input="delete formErrors.description"
              />
            </v-col>

            <v-col cols="12">
              <Wysiwyg
                v-model="model.data.content"
                label="Extended Content"
                hide-details="auto"
                @input="delete formErrors.content"
              ></Wysiwyg>
            </v-col>

            <v-col cols="12">
              <v-switch
                v-model="model.data.important"
                class="pa-0 ma-0 pl-2"
                label="Important"
                hide-details="auto"
                inset
                @change="delete formErrors.important"
              ></v-switch>
            </v-col>

            <v-col cols="12">
              <v-switch
                v-model="model.data.everyone"
                class="pa-0 ma-0 pl-2"
                label="Everyone"
                hide-details="auto"
                inset
                @change="delete formErrors.everyone"
              ></v-switch>
            </v-col>

            <v-col cols="12" v-if="!model.data.everyone">
              <v-autocomplete
                v-model="model.data.userlist"
                :loading="userListLoading"
                :items="userList"
                item-text="label"
                item-value="data.id"
                label="Target Users"
                hide-details="auto"
                outlined
                multiple
                chips
                small-chips
                deletable-chips
                clearable
              />
            </v-col>
          </v-row>
          <v-row>

            <v-divider class="ma-4"/>

            <v-col cols="12">
              <v-autocomplete
                v-model="model.data.useracklist"
                :loading="userListLoading"
                :items="userList"
                item-text="label"
                item-value="data.id"
                label="Acknowledge Users"
                hide-details="auto"
                outlined
                multiple
                chips
                deletable-chips
                small-chips
                clearable
              />
            </v-col>
          </v-row>
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component} from 'vue-property-decorator';
import Wysiwyg from '@/modules/common/components/Wysiwyg.vue';
import DataForm from '@/modules/common/components/DataForm.vue';
import UserService from '@/modules/sdk/services/user.service';
import UserModel from '@/modules/sdk/models/user.model';
import NotificationService from '@/modules/sdk/services/notification.service';
import NotificationModel from '@/modules/sdk/models/notification.model';

@Component({
  components: {
    Wysiwyg,
    DataForm,
  }
})
export default class NotificationForm extends Vue {

  service = NotificationService.getInstance();
  loading = false;
  model: NotificationModel = new NotificationModel();

  userList: Array<UserModel> = [];
  userListLoading = false;

  load(id: number) {
    this.loading = true;
    this.service.get({id})
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  loadUserList() {
    this.userList = [];
    this.userListLoading = true;
    const order = 'firstName, lastName, email';
    const advanced = {'deleted': 0};
    UserService.getInstance().getAll({order, advanced})
      .then(response => this.userList = response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.userListLoading = false);
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id));
    }
    this.loadUserList();
  }
}
</script>
