<template>
  <v-container fluid class="pa-4">
    <ListView
      v-test-id="'project-status-reasons'"
      v-bind="$attrs"
      :headers="headers"
      :service="service"
      :hide-collapsable="hideCollapsable"
      :query="query"
      :new-route="newRoute"
      title="Exclusion Reasons"
      searchable
      hide-presets
      index="status_reason"
      fill-height
    >
      <!-- Project -->
      <template v-slot:item.project.label="{item}">
        {{ item.data.projectentity?.data.label }}
      </template>

      <!-- STAGE -->
      <template v-slot:item.stage="{item}">
        <v-chip class="ma-2" label :color="$options.filters?.color(item.data.stage, 'stage')" small>
          {{ $t('stage.' + item.data.stage) }}
        </v-chip>
      </template>

      <!-- IS DEFAULT? -->
      <template v-slot:item.isDefault="{item}">
        <v-icon v-if="item.data.isDefault" color="primary">mdi-check-circle-outline</v-icon>
        <v-icon v-else>mdi-circle-outline</v-icon>
      </template>
    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop} from 'vue-property-decorator';
import ListView from '@/modules/common/components/ListView.vue';
import ProjectStatusReasonService from '@/services/project-status-reason.service';
import ProjectService from '@/services/project.service';
import { stageList, statusList } from '@/enums/global';
import {Route} from 'vue-router';

@Component({
  components: {
    ListView,
  }
})
export default class ProjectStatusReasonList extends Vue {
  @Prop({ type: [Number, String] }) readonly projectId!: number;
  @Prop({ type: Boolean, default: true }) hideCollapsable!: boolean;

  name = 'ProjectStatusReasonList';
  service = ProjectStatusReasonService.getInstance();
  newRoute: Partial<Route> = {name: 'ProjectStatusReasonNew'};

  get headers(): any[] {
    const items: any[] = [
      {
        text: '',
        align: 'start',
        sortable: false,
        fixed: true,
        value: '_first',
        width: 72,
        item: {
          type: 'button',
          attrs: {},
          icon: {
            text: 'mdi-pencil-outline'
          },
          to: {
            name: this.projectId ? 'ProjectProjectStatusReasonForm' : 'ProjectStatusReasonForm',
            params: this.projectId ? {
              projectId: 'props.item.data.projectId',
              id: 'props.item.data.id',
            } : {
              id: 'props.item.data.id',
            }
          }
        }
      },
      {
        text: 'ID',
        align: 'start',
        sortable: true,
        fixed: true,
        value: 'id',
        filterable: {
          type: 'number',
        },
      }
    ];

    if (!this.projectId) {
      items.push({
        text: 'Project',
        align: 'start',
        sortable: true,
        value: 'project.label',
        cellClass: 'text-no-wrap',
        filterable: {
          field: 'projectId',
          type: 'distinct',
          service: ProjectService,
        }
      });
    }
    items.push(...[
      {
        text: 'Is Default?',
        align: 'center',
        sortable: true,
        value: 'isDefault',
        filterable: {
          type: 'boolean',
        },
      },
      {
        text: 'Reason',
        align: 'start',
        sortable: true,
        value: 'label',
        filterable: {
          type: 'text',
        }
      },
      {
        text: 'Status',
        align: 'start',
        sortable: true,
        value: 'status',
        filterable: {
          type: 'enum',
          items: statusList
        }
      },
      {
        text: 'Stage',
        align: 'start',
        sortable: true,
        value: 'stage',
        filterable: {
          type: 'enum',
          items: stageList
        }
      },
      {
        text: 'Created at',
        align: 'start',
        sortable: true,
        value: 'createdAt',
        filterable: {
          type: 'date'
        },
      },
      {
        text: 'Updated at',
        align: 'start',
        sortable: true,
        value: 'updatedAt',
        filterable: {
          type: 'date'
        },
      },
      {
        text: 'Deleted',
        align: 'start',
        sortable: true,
        value: 'deleted',
        filterable: {
          type: 'boolean'
        },
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: '_last',
      }
    ]);

    return items;
  }

  get query(): Array<object> {
    const query = [];
    if (this.projectId) {
      query.push({
        field: 'projectId',
        operator: 'equals',
        value: this.projectId,
      })
    }
    return query;
  }

  created() {
    if (this.projectId) {
      this.newRoute = {
        name: 'ProjectProjectStatusReasonNew', params: {
          projectId: this.projectId.toString()
        }
      };
    }
  }
}
</script>
