<template>
  <v-sheet color="transparent">

    <!-- COLOR PICKER DIALOG -->
    <ModalDialog
      v-if="!alreadyBeenTagged"
      v-model="dialog.visible"
      :persistent="!dialog.tag.data.label"
      :loading="dialog.saving"
      title="Tag Parameter"
      width="390"
    >
      <template v-if="dialog.tag" #body>

        <v-alert
          v-if="dialog.tag.changed"
          outlined
          type="info"
          color="primary"
        >
          <span>The upcoming changes will be applied globally across the system.</span>
        </v-alert>

        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="dialog.tag.data.label"
              v-safechar
              :rules="[rules.required]"
              label="Keyword"
              type="text"
              hide-details="auto"
              outlined
              clearable
              required
              @input="dialog.tag.changed = true"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-divider class="mt-3"></v-divider>

        <v-color-picker
          v-model="_color"
          mode="hexa"
          hide-canvas
          hide-inputs
          hide-mode-switch
          show-swatches
          width="340"
          swatches-max-height="340"
          @input="dialog.tag.changed = true"
        ></v-color-picker>
      </template>

      <!-- BUTTONS -->
      <template #buttons>
        <v-btn
          color="primary"
          :loading="dialog.saving"
          :disabled="dialog.saving || !dialog.tag.changed"
          @click="onApplyTagSettings"
        >
          <span v-text="$t('btn.apply')"></span>
        </v-btn>
        <v-btn
          text
          :disabled="dialog.saving"
          @click="dialog.visible = false"
        >
          <span v-text="$t('btn.cancel')"></span>
        </v-btn>
      </template>
    </ModalDialog>

    <!-- TAGS -->
    <v-combobox
      ref="combobox"
      v-model="_selected"
      v-bind="attrs"
      v-on="$listeners"
      :items="computedTags"
      :loading="loading"
      :disabled="computedDisabled"
      :background-color="alreadyBeenTagged || loading ? 'backgroundVeryLight' : undefined"
      :class="{
        'pe-none': computedDisabled
      }"
      item-text="data.label"
      item-value="data.id"
      hide-details="auto"
      style="z-index: 1"
      outlined
      multiple
      small-chips
      deletable-chips
      clearable
      hide-selected
    >
      <template #append>
        <v-icon :color="attrs.color">mdi-circle</v-icon>
      </template>
      <template #item="{ item }">
        <v-list-item-icon v-if="item.data.color">
          <v-icon :color="item.data.color" left>mdi-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ item.data.label }}
        </v-list-item-title>
      </template>
      <template #selection="{ attrs, item, selected }">
        <v-chip
          v-if="typeof item !== 'string'"
          v-bind="attrs"
          :input-value="selected"
          :disabled="disabled"
          chips
          outlined
          small
          @click.stop="openColor(item)"
        >
          <v-icon v-if="item.data.color" :color="item.data.color" small left>mdi-circle</v-icon>
          <span class="pr-1 text-truncate" :class="item.getTextClass()" v-text="item.data.label"></span>
          <v-progress-circular
            v-if="item.loading"
            :size="15"
            class="pl-1"
            width="2"
            indeterminate
          />
          <v-icon
            v-else
            small
            @click.stop="() => remove(item)"
          >
            $delete
          </v-icon>
        </v-chip>
      </template>
    </v-combobox>
  </v-sheet>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop, Emit, Watch} from 'vue-property-decorator';
import TagModel from '@/models/tag.model';
import TagService from '@/services/tag.service';
import RecordTagService from '@/services/record-tag.service';
import Rules, { IRuleSet } from '@/modules/sdk/core/rules';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import Identity from '@/modules/sdk/core/identity';
import { SharedQuery } from '@/utils/shared-query';
import Model from '@/modules/sdk/core/model';

const cacheByProjectId: any = {};

@Component({
  components: {
    ModalDialog
  }
})
export default class TagsComponent extends Vue {
  @Prop({ default: null }) categoryId!: number;
  @Prop({ default: null }) projectId!: number;
  @Prop({ default: null }) recordId!: number;
  @Prop({ type: String, default: null }) stage!: 'screening' | 'indepth' | 'final';
  @Prop({ type: Boolean, default: false }) selfOnly!: boolean;
  @Prop({ type: String, default: 'none' }) userType!: string;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ default: () => ([]) }) items!: Array<TagModel>|null;

  loading = false;
  initialized = false;

  selected: Array<TagModel> = []
  rules: IRuleSet = {};
  color = {} as any;
  defaultColor = '#E0E0E0FF';
  tagList: Array<TagModel> = [];
  dialog: {
    visible: boolean,
    saving: boolean,
    tag: TagModel,
    original: TagModel,
  } = {
    visible: false,
    saving: false,
    tag: new TagModel(),
    original: new TagModel(),
  }

  @Watch('projectId')
  onRecordIdChange(projectId: number) {
    if (projectId) {
      cacheByProjectId[projectId] = {};
      this.initialized = false;
      this.load();
    }
  }

  @Watch('userType')
  onUserTypeChange() {
    this.initialized = false;
  }

  @Watch('selected', {
    deep: true,
    immediate: true,
  })
  onModelChanged(
    newItems: Array<string | TagModel>,
    oldItems: Array<string | TagModel>,
  ) {
    if (!this.initialized) {
      return;
    }

    // Save and update tags
    newItems.forEach((item: string | TagModel | any, index) => {
      const tag = typeof item === 'string'
        ? new TagModel({ label: item })
        : !(item instanceof TagModel) && item.data
          ? new TagModel(item.data)
          : item;

      let oldItem: any = oldItems.find(old => typeof old === 'string'
        ? old.toLowerCase() === tag.data.label.toLowerCase()
        : old.data.label.toLowerCase() === tag.data.label.toLowerCase()
      );
      oldItem = oldItem === 'string' ? new TagModel({ label: oldItem }) : oldItem;
      const mustSave = !oldItem || tag.data.label.toLowerCase() !== oldItem.data.label.toLowerCase();
      newItems[index] = tag;

      if (mustSave) {
        if (!tag.data.color) {
          tag.data.color = this.defaultColor;
        }

        tag.setRecordTag(this.projectId, this.categoryId, this.recordId, 0);
        this.save(tag);
      } else if (typeof item === 'string') {
        newItems.splice(index, 1);
      }
    })
    this.$emit('input', newItems);

    // Remove old items if not available in newly added ones
    (oldItems || []).filter(old => {
      return typeof old !== 'string'
        && !newItems.find(newItem => {
          return typeof newItem === 'string'
            ? newItem.toLowerCase() === old.data.label.toLowerCase()
            : newItem.data.label.toLowerCase() === old.data.label.toLowerCase();
        })
    }).forEach(itemToRemove => {
      if (typeof itemToRemove !== 'string') {
        this.remove(itemToRemove);
      }
    })
  }

  get computedDisabled(): boolean {
    return this.loading
      || this.disabled
      || this.alreadyBeenTagged;
  }

  get alreadyBeenTagged(): boolean {
    return this.userType !== 'arbitrator' && this.selected.length > 0;
  }

  get computedTags(): Array<TagModel> {
    return this.items === null
      ? this.tagList
      : this.items;
  }

  set computedTags(tags: Array<TagModel>) {
    this.items === null
      ? this.tagList = tags
      : this.items = tags;
  }

  get _selected(): Array<TagModel> {
    return this.recordId
      ? this.selected.filter(item => this.userType === 'arbitrator' || item.findRecordTag({ createdBy: Identity.getCurrentUserId() }))
      : [];
  }

  set _selected(value) {
    this.selected = value
  }

  get attrs() {
    return this.$attrs;
  }

  set _color(value) {
    this.color = value;
  }

  get _color() {
    return this.color;
  }

  onApplyTagSettings() {
    this.dialog.tag.data.color = this._color;
    this.dialog.saving = true;
    this.save(this.dialog.tag)
      .then(response => {
        // Caught promises aren't returned as error...
        if (response instanceof Model) {
          this.dialog.original.assign(this.dialog.tag);
          this.dialog.visible = false;
        }
      })
      .finally(() => this.dialog.saving = false)
  }

  openColor(tag: TagModel) {
    Object.assign(this.dialog, {
      visible: true,
      tag: tag.clone(),
      original: tag,
    })
    this._color = tag.data.color || this.defaultColor;
  }

  @Emit()
  save(tag: TagModel): Promise<any> {
    const saveTag = (tag: TagModel): Promise<TagModel> => {
      tag.loading = true;

      const save = {
        id: tag.data.id,
        label: tag.data.label,
        color: tag.data.color,
        recordnode: [true, {
          recordId: this.recordId,
          categoryId: this.categoryId,
          stage: this.stage,
          deleted: 0,
        }],
        deleted: 0,
      };
      return TagService.getInstance().save(save)
        .then((response: any) => {
          if (response.data.view.saved) {
            this.$root.$globalSnack.success({message: 'Tag `' + tag.data.label + '` is now assigned to this record.'});
            tag.assign(response.data.view.single);
          }
          // push the new tag if it doesn't already exist
          if (this.computedTags.findIndex((existing: TagModel) => existing.data.id === tag.data.id) === -1) {
            this.computedTags.push(tag);
            this._selected.push(tag);
            this.$emit('insert', tag);
          }
          return response.data.view.single;
        })
        .catch(reason => {
          if (reason.response.data.view.messages[0]?.message === 'not-unique') {
            this.$root.$globalSnack.warning({
              message: 'This label already exist in the list of tags. Instead of renaming this element, close this modal and search for the existing tag in the list.'
            });
          } else {
            this.$root.$zemit.handleError(reason)
          }
        })
        .finally(() => tag.loading = false);
    }

    if (!tag.data.id) {
      tag.loading = true;
      const filters = [{
        field: 'label',
        value: tag.data.label,
        operator: 'equals',
      }];
      const limit = 1;
      return TagService.getInstance().getAll({filters, limit})
        .then((response: any) => {
          if (response.data.view.list[0]) {
            tag.assign(response.data.view.list[0]);
          }
          return saveTag(tag);
        })
        .catch(reason => this.$root.$zemit.handleError(reason))
        .finally(() => {
          tag.loading = false;
          if (this.$refs.combobox) {
            // @ts-ignore
            this.$refs.combobox.$forceUpdate();
          }
        });
    } else {
      return saveTag(tag);
    }
  }

  @Emit()
  remove(tag: TagModel): Promise<TagModel> {

    // Manually delete the all related nodes
    const createdBy = Identity.getCurrentUserId();
    const projectId = this.projectId;
    const categoryId = this.categoryId;
    const recordId = this.recordId;
    const deleted = 0;
    const recordTagList = tag.filterRecordTag(Object.assign(
      {projectId, recordId, categoryId, deleted}, this.selfOnly? {createdBy} : {}
    ));

    const saveList = [];
    for (const recordTag of recordTagList) {
      if (recordTag.data.id) {
        saveList.push(structuredClone({
          id: recordTag.data.id,
          deleted: 1,
        }));
        tag.loading = true;
      }
    }

    tag.loading = true;
    return saveList.length
      ? RecordTagService.getInstance().save(saveList)
        .then((response: any) => {
          for (const view of response.data.view) {
            if (!view.saved || !view.single.deleted) {
              this.$root.$globalSnack.error({message: 'An error occurred while trying to remove tag `' + tag.data.label + '` from this record.'});
            } else {
              this.$root.$globalSnack.info({message: 'Tag `' + tag.data.label + '` has been removed from this record.'});
            }
          }
          if (response.data.view[0]?.saved) {
            tag.setRecordTag(this.projectId, this.categoryId, this.recordId, 1);
            const index = this.selected.findIndex(item => item.data.label.toLowerCase() === tag.data.label.toLowerCase());
            if (index !== -1) {
              this.selected.splice(index, 1);
            }
          }
          return tag.data.usernode;
        })
        .catch(reason => this.$root.$zemit.handleError(reason))
        .finally(() => tag.loading = false)
      : new Promise((resolve) => {
        const index = this.selected.findIndex(item => item.data.label.toLowerCase() === tag.data.label.toLowerCase());
        if (index !== -1) {
          this.selected.splice(index, 1);
        }
        resolve(tag.data.usernode);
      });
  }

  @Emit()
  load(): Promise<void | Array<TagModel>> {
    const callback = (tags: any[]) => {
      this.tagList = tags;
      this.selectTags(this.tagList);
      return this.tagList;
    };

    const cacheKey = JSON.stringify(
      this.categoryId + '_'
      + this.userType + '_'
      + this.stage + '_'
    );
    if (!cacheByProjectId[this.projectId]) {
      cacheByProjectId[this.projectId] = {};
    }
    if (cacheByProjectId[this.projectId][cacheKey]) {
      // We need to update the initialized variable on the next tick
      // so the observed variables can run and cancel before
      // initialize is set to true
      setTimeout(() => {
        this.initialized = true;
      })
      cacheByProjectId[this.projectId][cacheKey].forEach((item: TagModel) => item.changed = false);
      return new Promise(resolve => {
        resolve(callback(cacheByProjectId[this.projectId][cacheKey]) as Array<TagModel>)
      });
    }

    this.loading = true;
    return SharedQuery.getProjectTagsByCategoryId(
      this.projectId,
      this.categoryId,
      // If not arbitrator, can only see current stage tags and lower
      this.userType !== 'arbitrator'
        ? this.stage
        : undefined,
    )
      .then((tags: any[]) => {
        cacheByProjectId[this.projectId][cacheKey] = tags;
        callback(tags);
      })
      .catch(reason => {
        this.$root.$zemit.handleError(reason);
        this.$root.$globalSnack.error({message: 'Unable to load Tags from Project.'});
      })
      .finally(() => {
        this.loading = false;
        this.initialized = true;
      });
  }

  selectTags(tagList: Array<TagModel>) {
    // Only assign some tags to the record
    const projectId = this.projectId;
    const categoryId = this.categoryId;
    const recordId = this.recordId;
    const createdBy = Identity.getCurrentUserId();
    const deleted = 0;

    this.selected = tagList.filter((tag: TagModel) => !tag.data.deleted
      && tag.findRecordTag(Object.assign({projectId, categoryId, recordId, deleted}, this.selfOnly? {createdBy} : {}))
    );
  }

  created() {
    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required').toString(),
    }

    if (this.items === null) {
      this.load();
    } else {
      this.selectTags(this.items);
      setTimeout(() => {
        this.initialized = true;
      }, 0);
    }
  }
}
</script>
