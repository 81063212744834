<template>
  <div v-if="showQuickStatus">

    <StatusReasonModal
      ref="statusReasonModalRef"
      v-model="statusReasonModal.visible"
      :record="record"
      :default-reasons="statusReasonModal.reasons"
      status="halt"
      stage="indepth"
      @confirm="onStatusReasonConfirm"
    />

    <template v-for="(recordStatus, recordStatusIdx) in recordStatusList">
      <v-tooltip
        :key="recordStatusIdx"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :loading="recordStatus.loading"
            :disabled="!statusStore.canApplyStatus"
            icon
            @click="saveStatus(recordStatus)"
          >
            <v-icon :color="recordStatus.color">mdi-circle</v-icon>
          </v-btn>
        </template>
        <span v-text="$t('status.' + recordStatus.status)"></span>
      </v-tooltip>
    </template>
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Component, Emit, Ref } from 'vue-property-decorator';
import RecordService from '@/services/record.service';
import ProjectStatusReasonModel from '@/models/project-status-reason.model';
import ProjectStatusReasonService from '@/services/project-status-reason.service';
import StatusReasonModal from '@/views/Admin/Component/Record/StatusReasonModal.vue';
import {SnackError} from '@/modules/common/core/error';
import StatusMixin, { IStatus } from '@/mixins/status';
import {useStatusStore} from '@/stores/statusStore';

@Component({
  components: {
    StatusReasonModal
  }
})
export default class RecordQuickStatusComponent extends StatusMixin {
  @Ref() readonly statusReasonModalRef!: StatusReasonModal

  loading = false;
  statusStore = useStatusStore();

  // Show Quick Status
  get showQuickStatus() {
    switch (this.stage) {
      case 'final':
        if (this.record.data.indepthStatus !== 'pass') {
          return false;
        }
      // eslint-disable-next-line no-fallthrough
      case 'indepth':
        if (this.record.data.screeningStatus !== 'pass') {
          return false;
        }
      // eslint-disable-next-line no-fallthrough
      case 'screening':
        return true;
    }
    return false;
  }

  onStatusReasonConfirm(reasons: ProjectStatusReasonModel[] | null) {
    this.saveStatus(this.statusReasonModal.recordStatus, reasons);
  }

  /**
   * Save the record status
   * @param recordStatus
   * @param reasons
   */
  @Emit()
  saveStatus(
    recordStatus: IStatus,
    reasons?: ProjectStatusReasonModel[] | null
  ) {
    if (!this.canChangeStatus) {
      return;
    }

    const openStatusReasonModal = recordStatus.status === 'halt' && this.stage !== 'screening';

    if (reasons === undefined && openStatusReasonModal) {
      recordStatus.loading = true;
      this.statusReasonModalRef.load()
        .then((response) => {
          if (!response.data.view.list) {
            throw new SnackError({
              title: 'The status has not been updated',
              message: 'Unable to retrieve status reason list'
            });
          }

          // Open Status Reason Modal
          Object.assign(this.statusReasonModal, {
            visible: true,
            reasons: [],
            recordStatus,
          })
        })
        .catch(reason => {
          this.saveStatusFailed();
          this.$root.$zemit.handleError(reason);
        })
        .finally(() => recordStatus.loading = false);
    }
    else {

      const callback = async (data: any) => {
        return RecordService.getInstance().saveUserStatus(data, {
          params: {
            userType: this.userType,
          }
        })
          .then((response) => {
            if (!response.data.view.saved || !response.data.view.single) {
              throw new SnackError({
                title: 'The status has not been updated',
                message: 'An unexpected error has occurred'
              });
            }
            // Success
            this.record.assign(response.data.view.single);
            this.$root.$globalSnack.success({
              title: 'Record status has been successfully updated',
              message: 'The `' + this.stage + '` stage is now set to `' + recordStatus.status + '`'
            });
            this.saveStatusSuccess();
            this.statusReasonModalRef.close();
          })
          .catch(reason => {
            this.saveStatusFailed();
            this.$root.$zemit.handleError(reason)
          })
          .finally(() => {
            this.loading = false;
            recordStatus.loading = false;
          });
      }

      this.loading = true;
      recordStatus.loading = true;

      // Record data to save
      const data: any = {
        id: this.record.data.id
      };
      data[this.stage + 'Status'] = recordStatus.status;
      data[this.stage + 'ProjectStatusReasonList'] = [false];

      const modelList: ProjectStatusReasonModel[] = [];
      (reasons || []).forEach(reason => {
        if (!reason.data.deleted) {
          if (reason.data.id) {
            data[this.stage + 'ProjectStatusReasonList'].push(reason.data.id);
          }
          else {
            modelList.push(new ProjectStatusReasonModel({
              projectId: this.record.data.projectId,
              label: reason.data.label,
              status: recordStatus.status,
              stage: this.stage,
            }));
          }
        }
      })

      if (modelList.length === 0) {
        // @ts-ignore
        return callback(data, reasons || undefined);
      } else {
        return ProjectStatusReasonService.getInstance().save(modelList)
          .then(response => {
            for (let i = 0; i < response.data.view.length; i++) {
              const data = response.data.view[i];
              if (!data.saved || !data.single) {
                throw new SnackError({
                  title: 'The status reason has not been saved',
                  message: 'An unexpected error has occurred'
                });
              }
            }
            data[this.stage + 'ProjectStatusReasonList'] = [];
            response.data.view.forEach((item: any) => {
              data[this.stage + 'ProjectStatusReasonList'].push(item.single.id);
            })
            callback(data);
          })
          .catch(reason => {
            this.$root.$zemit.handleError(reason);
            this.loading = false;
            recordStatus.loading = false;
          });
      }
    }
  }

  @Emit()
  saveStatusSuccess() {
  }

  @Emit()
  saveStatusFailed() {
  }
}
</script>
