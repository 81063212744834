<template>
  <v-sheet color="transparent">
    <v-overlay v-if="loading" opacity="0.1">
      <v-progress-linear
        style="width: 15rem"
        color="primary"
        indeterminate
      />
    </v-overlay>
    <slot v-else :props="props"></slot>
  </v-sheet>
</template>

<script lang="ts">
import { Component, Watch, Prop } from 'vue-property-decorator';
import PopupCommunicatorMixin from '@/modules/common/mixins/popup-communicator';

@Component
export default class PopupCommunicatorChild extends PopupCommunicatorMixin {
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: String, default: 'PopupCommunicator' }) title!: string;

  props: any = {}
  parent = window.opener;

  @Watch('title', { immediate: true })
  onTitleChanged(title: string): void {
    document.title = title;
  }

  listen(event: MessageEvent) {
    try {
      const props = JSON.parse(event.data);
      switch (props.action) {
        case 'theme':
          this.$vuetify.theme.dark = props.data;
          break;
        case 'props':
          this.dataToProps(props.data).then(props => {
            this.props = props;
          })
          break;
      }
    } catch (e) {

    }
  }

  created() {
    window.addEventListener('message', this.listen);
    window.addEventListener('beforeunload', () => {
      this.parent?.postMessage(JSON.stringify({
        action: 'beforeUnload',
      }), '*');
    });

    this.parent?.postMessage(JSON.stringify({
      action: 'getProps',
    }), '*');
  }

  destroyed() {
    window.removeEventListener('message', this.listen);
  }
}
</script>
