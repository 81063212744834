<template>
  <ModalDialog
    v-model="visible"
    v-bind="$attrs"
    v-on="$listeners"
    :disabled="busy"
    persistent
    max-width="800"
    hide-header
    hide-close-button
  >
    <template #content>
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col cols="6">
            <v-img
              src="/images/still-there.jpg"
              height="400"
            />
          </v-col>
          <v-col cols="6" class="px-6 d-flex align-center justify-center">
            <div class="text-center">
              <div class="display-1">Are you still there?</div>
              <p class="title mt-3">If you do not take action, you will be logged out in {{ countdown }} seconds.</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template #buttons>
      <v-btn
        :loading="refreshing"
        :disabled="busy"
        color="primary"
        large
        @click="onStayConnectedClick"
      >
        Stay connected
      </v-btn>
      <v-btn
        :loading="loggingOut"
        :disabled="busy"
        outlined
        large
        @click="onLogoutClick"
      >
        Logout
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import AuthService from '@/modules/sdk/services/auth.service';
import Identity from '@/modules/sdk/core/identity';
import Main from '@/main';

let countdownInterval: number;
let delayTimeout: number;

@Component({
  components: {
    ModalDialog,
  }
})
export default class StillAliveModal extends Vue {

  @Prop({ type: Number, default: 1000 * 60 * 5 }) delay!: number;
  @Prop({ type: Boolean, default: true }) enabled!: boolean;

  @Watch('visible', { immediate: true })
  onVisibleChange(visible: boolean) {
    if (this.enabled && visible) {
      this.reset();
      clearInterval(countdownInterval);
      countdownInterval = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          this.clearTimers();
          this.logout();
        }
      }, 1000);
    }
  }

  visible = false
  refreshing = false
  loggingOut = false
  countdown = 0

  get busy(): boolean {
    return this.refreshing || this.loggingOut;
  }

  onStayConnectedClick() {
    this.clearTimers();
    this.refresh().then(() => {
      this.visible = false;
      this.initDelay();
    })
  }

  onLogoutClick() {
    this.logout();
  }

  logout(): Promise<any> {
    this.loggingOut = true;
    return AuthService.getInstance().logout()
      .then(response => {
        if (!response.data.view.loggedIn) {
          Identity.removeIdentity()
          this.$router.push({ name: 'login' })
          Main.logout()
        }
        else {
          this.$root.$globalSnack.warning({
            message: this.$t('error.logout'),
            icon: 'mdi-emoticon-dead-outline'
          })
        }
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loggingOut = false)
  }

  refresh(): Promise<any> {
    this.refreshing = true;
    return Identity.refreshPromise()
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.refreshing = false)
  }

  initDelay() {
    clearTimeout(delayTimeout);
    delayTimeout = setTimeout(() => {
      this.visible = true;
    }, this.delay);
  }

  reset() {
    this.countdown = 60
  }

  clearTimers() {
    clearTimeout(delayTimeout);
    clearInterval(countdownInterval);
  }

  created() {
    if (this.enabled) {
      document.body.addEventListener('mousemove', this.initDelay);
      this.initDelay();
      this.reset();
    }
  }

  destroyed() {
    document.body.removeEventListener('mousemove', this.initDelay);
    this.clearTimers();
  }
}
</script>
