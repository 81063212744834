<template>
  <v-menu
    v-test-id="'app-notification-menu'"
    v-model="open"
    min-width="300"
    max-height="500"
    left
    offset-y
  >
    <template #activator="{ on: menuOn, attrs: menuAttrs }">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-test-id="'app-notification-button'"
            v-bind="{ ...menuAttrs, ...attrs }"
            v-on="{ ...menuOn, ...on }"
            icon
          >
            <v-badge :value="count" :content="count" color="error" overlap>
              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span
          v-test-id="'app-notification-tooltip'"
          v-text="$t('toolbar.notifications')"
        ></span>
      </v-tooltip>
    </template>
    <v-list v-test-id="'app-notification-items'">
      <v-list-item v-if="notificationList.length === 0">
        <v-list-item-content>
          <v-alert class="mb-0" text type="info">
            <span v-text="$t('notificationMenu.empty')"></span>
          </v-alert>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="(notification, notificationIdx) in notificationList"
        :key="notificationIdx"
        :value="notificationIdx"
        @click="() => onNotificationClick(notification, notificationIdx)"
      >
        <v-list-item-icon>
          <v-icon v-if="notification.data.selfAck" color="green">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else color="grey">mdi-checkbox-blank-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="notification.data.label"></v-list-item-title>
          <v-list-item-subtitle v-html="notification.data.description"></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon :color="notification.data.important ? 'error' : 'transparent'" x-small>mdi-checkbox-blank-circle</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import {Vue, Component, Watch} from 'vue-property-decorator';
import NotificationModel from '@/modules/sdk/models/notification.model';
import NotificationService from '@/modules/sdk/services/notification.service';

let loadInterval: number;

@Component
export default class NotificationMenu extends Vue {

  loading = false
  open = false
  notificationList: Array<NotificationModel> = []

  get count() {
    return this.notificationList.filter(notification => !notification.data.selfAck).length;
  }

  onNotificationClick(notification: NotificationModel, index: number) {
    let body = '';
    body += (notification.data.description? '<h3>' + notification.data.description + '</h3>' : '');
    body += (notification.data.content? '<p>' + notification.data.content + '</p>' : '');
    this.$root.$globalPrompt.ask(
      notification.data.label,
      body,
      () => {
        this.$root.$globalPrompt.setLoading(true);
        NotificationService.getInstance().ack({
          id: notification.data.id,
        })
          .then(() => {
            notification.data.selfAck = 1;
            this.$root.$globalPrompt.hide();
          })
          .catch(reason => this.$root.$zemit.handleError(reason))
          .finally(() => this.$root.$globalPrompt.setLoading(false));
      },
      this.$i18n.t('btn.acknowledge'),
    )

    if (notification.data.selfAck) {
      this.$root.$globalPrompt.buttons[1].attrs.text = true;
      this.$root.$globalPrompt.buttons[1].attrs.disabled = true;
    }
  }

  fetchNotifications() {
    this.loading = true;
    NotificationService.getInstance().getAll({ filters: [{
      field: 'deleted',
      operator: '!=',
      value: true,
    }]})
      .then(response => this.notificationList = response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  created() {
    loadInterval = setInterval(() => {
      this.fetchNotifications();
    }, 5 * 60 * 1000);
    this.fetchNotifications();
  }

  destroyed() {
    clearInterval(loadInterval);
  }
}
</script>
