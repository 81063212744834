<template>
  <draggable
    :value="conditionList"
    :animation="200"
    :group="dragGroup"
    handle=".sortable-handle"
    ghost-class="background-white"
    @start="drag = true"
    @end="drag = false"
    @input="onDragInput"
  >
    <transition-group type="transition" :name="!drag ? 'flip-list' : undefined">
      <template>
        <div
          v-for="(condition, conditionIdx) of _conditionList"
          :key="conditionIdx"
        >

          <!-- CONDITION -->
          <v-row>
            <v-col style="flex: 1">
              <v-row>

                <!-- DRAG -->
                <v-col style="flex: 0" class="pt-5">
                  <v-icon
                    class="sortable-handle"
                    v-test-id="'query_builder_drag'"
                    :disabled="disabled || (conditionList.length < 2 && depth === 0)"
                  >
                    mdi-drag
                  </v-icon>
                </v-col>

                <!-- AND/OR -->
                <v-col style="flex: 0; min-width: 10rem">
                  <v-autocomplete
                    v-model="condition.logic"
                    v-test-id="'query_builder_logic'"
                    :items="logicList"
                    :disabled="disabled || conditionIdx === 0"
                    :style="{
                      opacity: conditionIdx === 0 ? 0.5 : 1,
                    }"
                    background-color="backgroundVeryLight"
                    placeholder="Condition"
                    hide-details="auto"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>

                <!-- CONDITION -->
                <v-col style="flex: 1">

                  <!-- CHILDREN (GROUP) -->
                  <v-alert
                    v-if="condition.group.length > 0 && !isFieldType(['join'], condition)"
                    class="mb-0"
                    :style="{
                      marginRight: depth > 0 ? '-17px' : null,
                      backgroundColor: $vuetify.theme.themes.light.sheet + ' !important',
                    }"
                    color="#bbb"
                    border="left"
                    outlined
                  >
                    <QueryGroupBuilderChild
                      :value="conditionList[conditionIdx].group"
                      v-bind="$attrs"
                      v-on="$listeners"
                      :fields="fields"
                      :depth="depth + 1"
                      :parent-item-index="conditionIdx"
                      :parent-list="conditionList"
                      :parent-instance="instance"
                      :disabled="disabled"
                      :relationships="relationships"
                      :relationship-fields="relationshipFields"
                      :item-values="itemValues"
                      :root="root"
                      ref="queryGroupBuilderChild"
                      @ungroup="onUngroup"
                      @removeEmpty="onRemoveEmpty"
                    />
                  </v-alert>

                  <!-- CURRENT ITEM -->
                  <v-row v-else>

                    <!-- FIELD -->
                    <v-col cols="12" md="4" lg="4" xl="4">
                      <v-autocomplete
                        v-model="condition.field"
                        v-test-id="'query_builder_field'"
                        :items="filteredFields"
                        :return-object="returnObject"
                        :disabled="disabled"
                        ref="field"
                        background-color="sheet"
                        label="Field"
                        hide-details="auto"
                        height="40"
                        clearable
                        outlined
                        dense
                        @change="field => onFieldChange(condition, field)"
                      >
                        <template #append>
                          <!--                          <v-chip-->
                          <!--                            v-if="isFieldType(['join'], condition)"-->
                          <!--                            class="mt-1"-->
                          <!--                            color="warning"-->
                          <!--                            label-->
                          <!--                            x-small-->
                          <!--                          >-->
                          <!--                            JOIN-->
                          <!--                          </v-chip>-->
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <!-- RELATIONSHIPS TYPE -->
                    <template v-if="isFieldType(['join'], condition)">
                      <!--                      <v-col cols="12" md="3" lg="3" xl="2" class="d-flex align-center">-->
                      <!--                        <v-select-->
                      <!--                          v-model="condition.key"-->
                      <!--                          v-test-id="'query_builder_key'"-->
                      <!--                          :items="alphabetList"-->
                      <!--                          prepend-inner-icon="mdi-key"-->
                      <!--                          label="Identifier"-->
                      <!--                          hide-details="auto"-->
                      <!--                          height="40"-->
                      <!--                          outlined-->
                      <!--                          dense-->
                      <!--                        />-->
                      <!--                      </v-col>-->
                    </template>

                    <template v-else>

                      <!-- COMPARATOR -->
                      <v-col cols="12" md="3" lg="4" xl="2">
                        <v-autocomplete
                          v-model="condition.operator"
                          v-test-id="'query_builder_comparator'"
                          :items="getOperatorList(condition)"
                          :disabled="disabled"
                          background-color="sheet"
                          hide-details="auto"
                          label="Comparator"
                          height="40"
                          outlined
                          dense
                          class="text-no-wrap"
                          @change="changeOperator(condition)"
                        ></v-autocomplete>
                      </v-col>

                      <!-- VALUES -->
                      <v-col cols="12" md="5" lg="4" xl="6">
                        <DialogDateField
                          v-if="isFieldType(['date'], condition)"
                          v-model="condition.value"
                          v-test-id="'query_builder_value_date'"
                          :input-attrs="{
                            hideDetails: 'auto',
                            label: $t('placeholder.chooseDate'),
                            backgroundColor: 'sheet',
                          }"
                          :disabled="disabled"
                          @input="() => $forceUpdate()"
                        />
                        <DialogDateTimeField
                          v-else-if="isFieldType(['date_time'], condition)"
                          v-model="condition.value"
                          v-test-id="'query_builder_value_datetime'"
                          :label="$t('placeholder.chooseDateTime')"
                          :disabled="disabled"
                          @input="() => $forceUpdate()"
                        />
                        <DialogTimeField
                          v-else-if="isFieldType(['time'], condition)"
                          v-model="condition.value"
                          v-test-id="'query_builder_value_time'"
                          :input-attrs="{
                            hideDetails: 'auto',
                            label: $t('label.pickTime'),
                            outlined: true,
                            dense: true,
                            backgroundColor: 'sheet',
                          }"
                          :disabled="disabled"
                          @input="() => $forceUpdate()"
                        />
                        <v-combobox
                          v-else-if="isFieldList(condition)"
                          v-model="condition.value"
                          v-bind="getValueAttrs(condition)"
                          v-test-id="'query_builder_value_list'"
                          :disabled="isDisabledValue(condition)"
                          :return-object="returnObject"
                          background-color="sheet"
                          label="Value"
                          hide-details="auto"
                          multiple
                          outlined
                          dense
                          clearable
                          chips
                          hide-selected
                          @focus="onFocus(condition)"
                          @blur="onBlur(condition)"
                          @copy="onCopy(condition)"
                          @paste.stop.prevent="event => onPaste(condition, event)"
                        >
                          <template v-if="condition.loading" #append>
                            <v-progress-circular
                              color="primary"
                              size="24"
                              width="2"
                              indeterminate
                            />
                          </template>
                          <template #selection="{ attrs, item, parent, selected }">
                            <v-chip
                              v-bind="attrs"
                              v-on="disabled ? {} : {
                                click: () => onChipSelect(item)
                              }"
                              :input-value="selected"
                              outlined
                              clearable
                              chips
                              small
                            >
                              <v-icon v-if="item.color" :color="item.color" small left>mdi-circle</v-icon>
                              <span class="text-truncate">
                                {{ typeof item === 'object' ? item.text : item }}
                              </span>
                              <v-btn :disabled="disabled" icon x-small class="ml-2" @click="parent.selectItem(item)">
                                <v-icon small>
                                  $delete
                                </v-icon>
                              </v-btn>
                            </v-chip>
                          </template>
                          <template #item="{ item }">
                            <v-list-item-icon v-if="item.color">
                              <v-icon :color="item.color">mdi-circle</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              {{ typeof item === 'object' ? item.text : item }}
                            </v-list-item-title>
                          </template>
                        </v-combobox>
                        <v-text-field
                          v-else-if="isFieldType('numeric', condition)"
                          v-model.number="condition.value"
                          v-bind="getValueAttrs(condition)"
                          v-test-id="'query_builder_value_number'"
                          :disabled="isDisabledValue(condition)"
                          background-color="sheet"
                          type="number"
                          hide-details="auto"
                          label="Value"
                          outlined
                          dense
                          clearable
                        />
                        <v-text-field
                          v-else
                          v-model="condition.value"
                          v-bind="getValueAttrs(condition)"
                          v-test-id="'query_builder_value_text'"
                          :disabled="isDisabledValue(condition)"
                          background-color="sheet"
                          hide-details="auto"
                          label="Value"
                          outlined
                          dense
                          clearable
                        />
                      </v-col>
                    </template>
                  </v-row>
                </v-col>

                <!-- ACTIONS -->
                <v-col
                  v-if="condition.group.length === 0 || isFieldType(['join'], condition)"
                  class="d-flex flex-nowrap justify-end"
                  style="flex: 0"
                >
                  <!-- UNGROUP -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="depth > 0 && !isParentRelationship"
                        v-bind="attrs"
                        v-on="on"
                        v-test-id="'query_builder_ungroup'"
                        :disabled="disabled"
                        icon
                        fab
                        text
                        small
                        @click="ungroupCondition(conditionIdx, conditionList, parentItemIndex)"
                      >
                        <v-icon dark>
                          mdi-arrow-left
                        </v-icon>
                      </v-btn>
                    </template>
                    <span v-text="$t('btn.ungroup')"></span>
                  </v-tooltip>

                  <!-- DELETE -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        v-test-id="'query_builder_remove'"
                        :disabled="disabled || isDefaultState"
                        fab
                        text
                        small
                        color="red"
                        @click="deleteCondition(conditionIdx, conditionList, parentItemIndex)"
                      >
                        <v-icon dark>
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </template>
                    <span v-text="$t('btn.remove')"></span>
                  </v-tooltip>

                  <!-- ADD -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        v-test-id="'query_builder_add'"
                        :disabled="disabled"
                        fab
                        text
                        small
                        color="green"
                        @click="addCondition(conditionIdx + 1, conditionList)"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Add below</span>
                  </v-tooltip>

                  <!-- GROUP -->
                  <v-tooltip v-if="maxDepth === -1 || maxDepth < depth" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        v-test-id="'query_builder_group'"
                        :disabled="disabled"
                        fab
                        text
                        small
                        @click="groupCondition(conditionIdx, conditionList)"
                      >
                        <v-icon dark>
                          mdi-arrow-right
                        </v-icon>
                      </v-btn>
                    </template>
                    <span v-text="$t('btn.group')"></span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- RELATIONSHIPS -->
          <v-row v-if="isFieldType(['join'], condition)">
            <v-col style="margin-left: 3rem" class="pt-0">
              <v-alert
                class="mb-0 w-100"
                :style="{
                  marginRight: depth > 0 ? '-17px' : null,
                }"
                color="warning"
                border="left"
                outlined
              >
                <QueryGroupBuilderChild
                  :value="conditionList[conditionIdx].group"
                  v-bind="$attrs"
                  v-on="$listeners"
                  :fields="fields"
                  :depth="depth + 1"
                  :parent-item-index="conditionIdx"
                  :parent-list="conditionList"
                  :parent-instance="instance"
                  :disabled="disabled"
                  :relationships="relationships"
                  :relationship-fields="conditionList[conditionIdx].children"
                  :item-values="itemValues"
                  :drag-group="getGroup(conditionList[conditionIdx])"
                  :root="root"
                  :is-default-state="isDefaultStateChild(conditionIdx)"
                  ref="queryGroupBuilderChild"
                  @ungroup="onUngroup"
                  @removeEmpty="onRemoveEmpty"
                />
              </v-alert>
            </v-col>
          </v-row>
        </div>
      </template>
    </transition-group>
  </draggable>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Vue, Component, VModel, Prop, Ref} from 'vue-property-decorator'
import {defaultQueryBuilderItem} from '@/modules/common/components/QueryBuilder.vue'
import {logicList, operatorList, alphabetList} from '@/enums/global'
import DialogDateField from '@/modules/common/components/DialogDateField.vue'
import DialogDateTimeField from '@/modules/common/components/DialogDateTimeField.vue'
import DialogTimeField from '@/modules/common/components/DialogTimeField.vue'
import Identity from '@/modules/sdk/core/identity'
import Query from '@/modules/sdk/core/query'
import draggable from 'vuedraggable'

// @ts-ignore
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete'

interface IValue
{
  text: string,
  value: any,
}

interface IField
{
  header?: string,
  text?: string,
  value?: string,
  type?: string,
  children?: IField[],
  items?: any[],
  getParent?: () => IField,
}

@Component({
  components: {
    draggable,
    DialogDateField,
    DialogDateTimeField,
    DialogTimeField,
    'QueryGroupBuilderChild': () => import('@/modules/common/components/QueryGroupBuilder.vue')
  }
})
export default class QueryGroupBuilder extends Vue
{
  @Ref() readonly queryGroupBuilderChild!: QueryGroupBuilder
  @Ref() readonly fieldRef!: VAutocomplete

  @VModel({type: Array, default: () => []}) conditionList!: any[]
  @Prop({type: Array, default: () => []}) root!: any[]
  @Prop({type: Number, default: 0}) readonly parentItemIndex!: number
  @Prop({type: Number, default: 0}) readonly depth!: number
  @Prop({type: Boolean, default: false}) disabled!: boolean
  @Prop({type: String, default: 'builder'}) dragGroup!: string
  @Prop({type: Boolean, default: false}) readonly isDefaultState!: boolean
  @Prop({type: Boolean, default: true}) readonly returnObject!: boolean
  @Prop({type: Number, default: -1}) readonly maxDepth!: number
  @Prop({type: Vue}) parentInstance!: QueryGroupBuilder
  @Prop({default: () => ([])}) readonly fields!: IField[]
  @Prop({default: () => ([])}) readonly relationships!: IField[]
  @Prop({default: null}) readonly relationshipFields!: IField[] | null
  @Prop({type: Function, default: () => []}) itemValues!: (field: IField) => IField[]

  instance: QueryGroupBuilder | undefined
  drag = false
  logicList = logicList
  alphabetList = alphabetList
  copiedItems = ''
  selectedList: IValue[] = []

  get filteredFields(): IField[] {
    if (this.relationshipFields && this.relationshipFields.length > 0) {
      return this.relationshipFields
    }

    const fields = [...this.fields]

    if (this.relationships.length > 0 && !this.relationshipFields) {
      fields.push({
        header: this.$i18n.t('builderComponent.relationshipList').toString(),
      }, ...this.relationships)
    }

    return fields
  }

  isDefaultStateChild(idx: any): boolean {
    return this.conditionList[idx].group.length === 1 && this.conditionList[idx].group[0].field === null
  }

  get isParentRelationship(): boolean {
    return this.parentInstance?.conditionList[this.parentItemIndex].isJoin;
  }

  get operatorList(): any[] {
    return operatorList.filter(item => {
      return !['regexp', 'not regexp'].includes(item.value) || !Identity.hasRole(['client'])
    })
  }

  get _conditionList() {
    return this.conditionList
  }

  set _conditionList(list: any[]) {
    this.$emit('input', list)
  }

  getGroup(condition: any): string {
    if (this.isFieldType(['join'], condition)) {
      return 'builder_' + condition.value
    }
    return 'builder'
  }

  onFocus(condition: any) {
    this.selectedList = []
    condition.focused = true
    this.$emit('focus', {
      field: this.getField(condition.field),
      condition,
    })
  }

  onBlur(condition: any) {
    delete condition.focused
    this.$emit('blur', {
      field: this.getField(condition.field),
      condition,
    })
  }

  onCopy(condition: any) {
    this.copiedItems = ''
    condition.value.forEach((item: any) => {
      const items = this.copiedItems.split(/\r\n|\r|\n/);
      if (!items.includes(item.value.toString())) {
        this.copiedItems += (this.copiedItems === '' ? '' : '\r\n') + item.value.toString().trim();
      }
    })
  }

  onPaste(condition: any, event: any) {
    const pastedData: string = event.clipboardData.getData('text/plain');
    const pastedItems: string[] = pastedData.split(/\r\n|\r|\n/).map(item => item.trim());
    const mergedItems: string[] = this.copiedItems === '' ? pastedItems : this.copiedItems.split(/\r\n|\r|\n/).concat(pastedItems);
    const notFoundItems: string[] = [];

    if (!Array.isArray(condition.value)) {
      condition.value = []
    }
    mergedItems.forEach(item => {
      const { items } = this.getValueAttrs(condition)
      const found = condition.value.find((val: any) => val.value.toString() === item.toString().trim())
      if (!found) {
        const value = items.find((_item: any) => (_item.value || '').toString() === (item || '').toString().trim())
        if (value) {
          condition.value.push(value)
        } else {
          notFoundItems.push(item.toString().trim());
        }
      }
    })

    notFoundItems.forEach(item => {
      condition.value.push(item)
    })
  }

  onChipSelect(item: IValue) {
    const index = this.selectedList.findIndex(_item => _item === item.value)
    if (index !== -1) {
      this.selectedList.splice(index, 1)
    }
    else {
      this.selectedList.push(item.value)
    }
  }

  getOperatorList(condition: any): Array<any> {
    const field: any = this.allFields.find(field => field.value === condition.field) || {};
    if (Array.isArray(field.operators)) {
      return this.operatorList.filter(operator => field.operators.includes(operator.value))
    }
    return this.operatorList
  }

  onFieldChange(condition: any, field: any) {
    const wasJoin = condition.children?.length > 0 || !!(condition.key)

    condition.isJoin = field?.type === 'join'
    condition.value = null

    if (field?.list) {
      condition.list.push(field.list)
      condition.filter = field.filter
    }

    if (this.isFieldList(condition)) {
      if ((field.items || []).includes('Yes')) {
        condition.value = ['Yes']
      }
      else {
        condition.value = []
      }
    }

    if (field) {
      condition.field = field.value
      condition.category = field.category
      condition.children = field.children || []

      const cleanRelationships = (items: any[]) => {
        for (let i = items.length - 1; i >= 0; i--) {
          const item = items[i]
          if ((item.field || '').includes('.')) {
            items.splice(i, 1)
          }
          cleanRelationships(item.group)
        }
      }
      cleanRelationships(condition.group)
    }

    if (condition.isJoin) {
      const entry = this.getNewCondition()
      condition.group = [entry]

      // Auto-select first entry if only one available
      // if (condition.children.length === 1) {
      //   entry.field = condition.children[0].value;
      //   entry.list = [condition.children[0].list];
      //   entry.parentId = condition.children[0].parentId;
      // }
    }
    else if (wasJoin) {
      condition.children = []
      condition.group = []
      condition.key = null
    }


    // Copy all field key/value except some into the condition
    if (field) {
      Object.keys(field).forEach(key => {
        if (![
          'group',
          'children',
          'key',
          'value',
          'list',
          'field',
          'category',
          'isJoin',
          'operator',
          'logic'
        ].includes(key)) {
          condition[key] = field[key]
        }
      })
    }

    Query.applyJoinKeys(this.root)
  }

  onUngroup(props: {
    index: number,
    item: any,
    childIdx: number,
  }) {
    if (!this.conditionList[props.index] || !this.conditionList[props.index].group || this.conditionList[props.index].length === 0) {
      return
    }

    // bring element to parent
    this.conditionList[props.index].group.splice(props.childIdx, 1)

    // remove element from group
    this.conditionList.splice(props.index, 0, structuredClone(props.item))

    // delete old group if empty
    if (this.conditionList[props.index + 1].group.length === 0) {
      this.deleteCondition(props.index + 1, this.conditionList, this.parentItemIndex)
    }

    this.$forceUpdate()
  }

  onRemoveEmpty(props: {
    index: number,
  }) {
    // condition index must exist
    if (!this.conditionList[props.index] || !this.conditionList[props.index].group || this.conditionList[props.index].length === 0) {
      return
    }

    // group is empty after removal
    if (this.conditionList[props.index].group.length === 0) {

      // group type is a relationship field
      if (this.conditionList[props.index].isJoin) {

        // re-create a new empty condition to the empty group (relationship field)
        this.addCondition(0, this.conditionList[props.index].group)
      }

      // group type is normal
      else {

        // delete the condition (empty group)
        this.deleteCondition(props.index, this.conditionList, this.parentItemIndex)
      }
    }

    this.$forceUpdate()
  }

  groupCondition(conditionIdx: number, conditionList = this.conditionList) {
    const condition = conditionList[conditionIdx]
    const clone = JSON.parse(JSON.stringify(condition))

    Object.assign(condition, {
      field: null,
      value: null,
      operator: null,
      isJoin: false,
      list: [],
      children: [],
    })

    if (!clone.isJoin) {
      clone.group = []
    }
    condition.group = [clone]
  }

  ungroupCondition(
    conditionIdx = 0,
    conditionList = this.conditionList,
    parentIndex = this.parentItemIndex,
  ) {
    this.parentInstance.onUngroup({
      index: parentIndex,
      item: conditionList[conditionIdx],
      childIdx: conditionIdx,
    })
  }

  changeOperator(condition: any) {
    if ([
      'is empty',
      'is not empty'
    ].indexOf(condition.operator) !== -1) {
      condition.value = ''
    }
  }

  isDisabledValue(condition: any) {
    return this.disabled || [
      'is empty',
      'is not empty'
    ].indexOf(condition.operator) !== -1 || !condition.field
  }

  isFieldType(type: string | Array<string>, condition: any) {
    if (['regexp', 'not regexp'].includes(condition.operator)) {
      return false
    }

    const types = Array.isArray(type) ? type : [type]
    const field = this.getField(condition.field)
    return (field && field.type && types.includes(field.type))
  }

  forceUpdate() {
    this.$forceUpdate()
    if (Array.isArray(this.queryGroupBuilderChild)) {
      this.queryGroupBuilderChild.forEach(ref => {
        ref.forceUpdate()
      })
    }
  }

  get allFields(): IField[] {
    function flattenItems(items: IField[]): IField[] {
      let result: IField[] = [];
      items.forEach(item => {
        result.push(item);
        if (item.children && item.children.length > 0) {
          result = result.concat(flattenItems(item.children));
        }
      });
      return result;
    }
    return flattenItems(this.fields.concat(this.relationships));
  }

  getField(condition: any, fields = this.allFields): IField | null {
    if (condition) {
      for (let i = 0; i < fields.length; i++) {
        const item = fields[i];
        if (typeof condition === 'string' ? condition === item.value : condition.field === item.value && condition.text === item.text) {
          return item
        }
        else if (item.children) {
          const found = this.getField(condition, item.children)
          if (found) {
            found.getParent = () => item
            return found
          }
        }
      }
    }
    return null
  }

  isFieldList(condition: any): boolean {
    const field = this.getField(condition.field, this.allFields)
    if (field) {
      const fieldToCheck = field.getParent ? field.getParent() : field
      const items = fieldToCheck.items || this.itemValues(field) || []
      const isList = fieldToCheck.type && ['list', 'select', 'join'].includes(fieldToCheck.type)
      return isList || items.length > 0
    }
    return false
  }

  getValueAttrs(condition: any): any {
    const items: any[] = []
    if (Array.isArray(condition.children)) {
      condition.children.forEach((child: any) => {
        items.push(child)
      })
    }

    const field = this.getField(condition, this.allFields)
    if (field) {
      const children = field.items || field.children || this.itemValues(field) || []
      const fieldToCheck = field.getParent ? field.getParent() : field
      if (fieldToCheck.type && ['list', 'select', 'join'].includes(fieldToCheck.type)) {
        items.push(...children)
      }
    }

    return {
      items,
      itemColor: 'color',
    }
  }

  deleteCondition(index: number, conditionList: any[], parentIndex = this.parentItemIndex) {
    conditionList.splice(index, 1)
    if (conditionList.length === 0 && this.depth === 0) {
      this.addCondition()
    }
    if (this.parentInstance) {
      this.parentInstance.onRemoveEmpty({
        index: parentIndex,
      })
    }
  }

  addCondition(conditionIdx = 0, conditionList = this.conditionList) {
    conditionList.splice(conditionIdx, 0, this.getNewCondition())
  }

  getNewCondition(): any {
    return structuredClone(defaultQueryBuilderItem)
  }

  onDragInput(values: any[]) {
    this._conditionList.splice(0, this._conditionList.length)
    this._conditionList.push(...values.filter(value => value.operator !== null || value.group.length !== 0))
  }

  created() {
    this.instance = this
  }

  mounted() {
    // setTimeout(() => {
    //   console.log(this.fieldRef);
    //   if (this.fieldRef && this.fieldRef[0]) {
    //     this.fieldRef[0].trigger('change');
    //   }
    // })
  }
}
</script>

<style lang="scss" scoped>
.draggable {
  background-color: white !important;
  opacity: 1 !important;
  transition: transform 0.5s;
}

.sortable-handle {
  cursor: move !important;
}

.no-move {
  transition: transform 0s;
}
</style>
