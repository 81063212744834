<template>
  <ModalDialog
    v-model="visible"
    :title="$t('reviewView.statusReasonModal.title')"
    icon="mdi-alert-rhombus-outline"
    max-width="400"
    scrollable
    persistent
  >
    <template #body>
      <span v-text="$t('reviewView.statusReasonModal.body')"></span>

      <component
        v-model="reasons"
        :is="canAddItem ? 'v-combobox' : 'v-autocomplete'"
        :items="reasonList"
        :label="$t('reviewView.statusReasonModal.status')"
        :loading="innerLoading"
        item-value="data.label"
        item-text="data.label"
        class="mt-4"
        return-object
        chips
        deletable-chips
        multiple
        outlined
        dense
        @input="onReasonsChange"
      />
    </template>
    <template #buttons>
      <v-btn
        color="primary"
        :disabled="!canConfirmStatus"
        :loading="loading || applying"
        @click="onConfirmStatusReason"
      >
        <span v-text="$t('btn.confirm')"></span>
      </v-btn>
      <v-btn
        outlined
        @click="onCancel"
      >
        <span v-text="$t('btn.cancel')"></span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import 'reflect-metadata';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import RecordModel from '@/models/record.model';
import ProjectStatusReasonModel from '@/models/project-status-reason.model';
import ProjectStatusReasonService from '@/services/project-status-reason.service';
import {Component, Vue, Prop, VModel, Emit, Watch} from 'vue-property-decorator';

@Component({
  components: {
    ModalDialog,
  }
})
export default class StatusReasonModal extends Vue {

  @VModel({ type: Boolean, default: false }) visible!: boolean;
  @Prop({ default: () => new RecordModel() }) record!: RecordModel;
  @Prop({ default: null }) stage!: string;
  @Prop({ default: null }) status!: string;
  @Prop({ type: Array, default: () => ([]) }) defaultReasons!: ProjectStatusReasonModel[];
  @Prop({ type: Boolean, default: false }) loading!: boolean;

  @Watch('visible', { immediate: true })
  onVisibleChanged(visible: boolean) {
    if (!visible) {
      this.applying = false;
      this.cancel();
    } else {
      this.load();
      this.reasons = this.defaultReasons.map(item => item.clone());
    }
  }

  loaded = false
  innerLoading = false
  applying = false
  reasons: ProjectStatusReasonModel[] | null = null;
  reasonList: ProjectStatusReasonModel[] = [];

  get canConfirmStatus() {
    return !!this.reasons
      && !this.applying
      && !this.loading
      && !this.innerLoading
      && JSON.stringify((this.reasons || []).map(reason => reason.data.id).sort()) !== JSON.stringify((this.defaultReasons || []).map(reason => reason.data.id).sort())
  }

  get canAddItem() {
    return false;
  }

  onReasonsChange() {
    // Do not remove redundancy check.
    this.reasons = this.reasons?.map(reason => reason instanceof ProjectStatusReasonModel ? reason : new ProjectStatusReasonModel({ label: reason })) || null
  }

  onConfirmStatusReason() {
    if (this.status) {
      this.applying = true;

      const allCurrentReasonIds = (Array.isArray(this.reasons) && this.reasons.map(reason => reason.data.id)) || [];
      const deletedReasons = this.defaultReasons.filter(defaultReason => !allCurrentReasonIds.includes(defaultReason.data.id));
      deletedReasons.forEach(item => item.data.deleted = true);

      // The backend will handle all final result states
      this.$emit('confirm', this.reasons);
    }
  }

  onCancel() {
    this.cancel();
    this.close();
  }

  async load(): Promise<any> {
    const filters = [
      [
        {
          field: 'projectId',
          operator: 'equals',
          value: this.record.data.projectId,
        },
        // OR
        {
          field: 'projectId',
          operator: 'is null',
        }
      ],
      // AND
      {
        field: 'stage',
        operator: 'equals',
        value: this.stage,
      },
      // AND
      {
        field: 'status',
        operator: 'equals',
        value: this.status,
      }
    ];

    this.innerLoading = true;
    return ProjectStatusReasonService.getInstance().getAll({filters})
      .then(response => {
        if (response.data.view.list) {
          this.reasonList = response.data.view.list;
        }
        this.loaded = true;
        return response;
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.innerLoading = false);
  }

  open() {
    this.visible = true;
  }

  close() {
    this.visible = false;
  }

  @Emit()
  cancel() {

  }
}
</script>
