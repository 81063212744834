<template>
  <PopupCommunicatorChild
    title="Project Information"
  >
    <template #default="{ props }">
      <ProjectInformationInner
        v-model="props.project"
        v-bind="props"
        class="pa-0 ma-0"
      />
    </template>
  </PopupCommunicatorChild>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, Vue } from 'vue-property-decorator'
import ProjectInformationInner from '@/components/ProjectInformationInner.vue';
import PopupCommunicatorChild from '@/modules/common/components/PopupCommunicatorChild.vue';

@Component({
  components: {
    PopupCommunicatorChild,
    ProjectInformationInner
  }
})
export default class ProjectInformationView extends Vue {

}
</script>
