import Model, {Data} from '@/modules/sdk/core/model';
import ProjectModel from '@/models/project.model';
import CategoryModel from '@/models/category.model'
import RecordModel from '@/models/record.model';
import RecordTagModel from '@/models/record-tag.model';
import Identity from '@/modules/sdk/core/identity';
import Logger from '@/modules/sdk/core/logger';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const tinycolor = require('tinycolor2');

const d = new Logger('models/tag.model');

export default class TagModel extends Model {
  selected = false;
  changed = false;

  default() {
    return {
      projectlist: [],
      recordlist: [],
      categorylist: [],
      recordnode: [],
    };
  }

  relatedMap () {
    return {
      projectlist: ProjectModel,
      recordlist: RecordModel,
      categorylist: CategoryModel,
      recordnode: RecordTagModel,
    }
  }

  relatedDeleteMissing() {
    return {
      projectlist: false,
      categorylist: false,
      recordlist: false,
      recordnode: false,
    };
  }

  isDark() {
    return tinycolor(this.data.color).isDark();
  }

  getTextClass() {
    return 'text--' + (this.isDark() ? 'lighten-3' : 'darken-3');
  }

  getTextColor(force = false) {
    return this.selected || force? (this.isDark() ? 'white' : 'black') : this.data.color;
  }

  /**
   * Try to find the user record tag and set the deleted value
   * Will create the record tag if it doesn't exist
   */
  setRecordTag(projectId: number, categoryId: number, recordId: number, deleted: number, createdBy = Identity.getCurrentUserId()): RecordTagModel {
    const data = {projectId, categoryId, recordId, createdBy};
    let recordTag = this.findRecordTag(data);
    if (!recordTag) {
      recordTag = new RecordTagModel(data);
      this.data.recordnode.push(recordTag);
    }
    if (recordTag.data.deleted !== deleted) {
      recordTag.data.deleted = deleted;
      this.changed = true;
    }
    return recordTag;
  }

  /**
   * Find the record tag using projectId, recordId and author createdBy
   */
  findRecordTag(data: Model | Data): RecordTagModel {
    const props = this.getData(data);
    return this.data.recordnode.find((item: RecordTagModel) => {
      for (const prop in props) {
        if (item.data[prop] !== props[prop]) {
          return false;
        }
      }
      return true;
    });
  }

  /**
   * Find the record tag using projectId, recordId and author createdBy
   */
  filterRecordTag(data: Model | Data): Array<RecordTagModel> {
    const props = this.getData(data);
    return this.data.recordnode.filter((item: RecordTagModel) => {
      for (const prop in props) {
        if (item.data[prop] !== props[prop]) {
          return false;
        }
      }
      return true;
    }) || [];
  }
}
