<template>
  <v-app-bar id="toolbar" app style="z-index: 100">
    <div class="d-flex align-center ml-1" style="gap: 1rem">
      <v-btn
        v-if="logo"
        :to="{ name: 'HomeView' }"
        active-class="v-btn-hide-active"
        exact
        text
        x-large
      >
        <img
          :src="logo"
          style="height: 3rem"
          alt="Logo"
        />
      </v-btn>

      <v-chip
        v-if="!['prod'].includes(env.key)"
        :color="env.color"
      >
        <v-avatar class="flashing" left>
          <v-icon>{{ env.icon }}</v-icon>
        </v-avatar>
        {{ env.title }}
      </v-chip>
    </div>

    <v-spacer />

    <div class="d-flex align-center justify-end" style="gap: 0.5rem; flex: 1">

      <!-- PORTAL -->
      <portal-target
        class="d-flex align-center"
        name="toolbar_right_first"
        style="gap: 1rem"
        multiple
      />
      <portal-target
        class="d-flex align-center mr-3"
        name="toolbar_right"
        style="gap: 1rem"
        multiple
      />

      <v-btn
        v-if="showMyAccount"
        :to="{name: 'MyAccount', params: { id: currentUserId }}"
        text
      >
        <v-icon left>mdi-account-circle-outline</v-icon>
        My Account
      </v-btn>

      <MenuTooltip
        :btn-attrs="{
          text: true,
        }"
        icon="mdi-help-circle-outline"
        btn-text="Help"
        chevron-down
      >
        <v-list>
          <v-list-item @click="() => showHelp = true">
            <v-list-item-icon>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>General Help</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="() => showSurvey = true">
            <v-list-item-icon>
              <v-icon>mdi-check-decagram</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Satisfaction Feedback</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'TutorialView' }">
            <v-list-item-icon>
              <v-icon>mdi-video-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Video Library</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </MenuTooltip>

      <!-- DARK MODE -->
      <theme-toggle/>

      <!-- NOTIFICATIONS -->
      <notification-menu />

      <TutorialModal
        :visible.sync="showHelp"
      />

      <!-- SURVEY -->
      <SatisfactionSurveyModal
        :visible.sync="showSurvey"
      />

      <!-- LOGOUT -->
      <LogoutModal
        :visible.sync="showLogout"
        dark
      />
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-test-id="'app-logout-button'"
            v-bind="attrs"
            v-on="on"
            icon
            text
            @click="() => showLogout = true"
          >
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </template>
        <span v-test-id="'app-logout-tooltip'">
          Logout
        </span>
      </v-tooltip>
    </div>
  </v-app-bar>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import LogoutModal from '@/modules/common/components/LogoutModal.vue';
import MenuTooltip from '@/modules/common/components/MenuTooltip.vue';
import NotificationMenu from '@/modules/common/components/NotificationMenu.vue';
import TutorialModal from '@/modules/common/components/TutorialModal.vue';
import ThemeToggle from '@/modules/common/components/ThemeToggle.vue';
import SatisfactionSurveyModal from '@/modules/common/components/SatisfactionSurveyModal.vue';
import Identity from '@/modules/sdk/core/identity';

@Component({
  components: {
    SatisfactionSurveyModal,
    ThemeToggle,
    TutorialModal,
    NotificationMenu,
    MenuTooltip,
    LogoutModal,
  }
})
export default class Toolbar extends Vue {

  @Prop({ type: String, default: null }) logo!: string;
  @Prop({ type: Boolean, default: false }) showMyAccount!: boolean;

  showHelp = false
  showSurvey = false
  showLogout = false

  get currentUserId() {
    return Identity.getCurrentUserId()
  }

  get env(): { key: string, title: string, color: string, icon: string } {
    let env = location.hostname.split('.')[0];
    if (env.toLowerCase() === 'slr') {
      env = 'prod';
    }
    const names: any = { dev: 'Development', stage: 'Staging', prod: 'slr' };
    const colors: any = { dev: 'error', stage: 'warning', prod: 'primary' };
    const icons: any = { dev: 'mdi-alert', stage: 'mdi-alert', prod: 'mdi-cloud-check' };
    return {
      key: env,
      title: names[env] || names.dev,
      color: colors[env] || colors.dev,
      icon: icons[env] || icons.dev,
    }
  }
}
</script>

<style scoped lang="scss">

</style>
