import { Component, Vue } from 'vue-property-decorator';
import Model from '@/modules/sdk/core/model';

@Component
export default class PopupCommunicatorMixin extends Vue {

  propsToData(props: any) {
    const result: any = {};
    const keys = Object.keys(props);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (props[key] instanceof Model) {
        result[key] = {
          path: props[key].getFilename(),
          type: props[key].constructor.name,
          value: props[key].toObject(),
          model: true,
        };
      } else {
        result[key] = {
          type: typeof props[key],
          value: props[key],
          model: false,
        };
      }
    }
    return result;
  }

  dataToProps(data: any): Promise<any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const result: any = {};
      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (data[key].model) {
          // const module = await import(data[key].path);
          // result[key] = new module[data[key].type](data[key].value);
          result[key] = new Model(data[key].value);
        } else {
          result[key] = data[key].value;
        }
      }
      resolve(result);
    })
  }
}
