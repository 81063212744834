import Model from '@/modules/sdk/core/model';
import RecordModel from '@/models/record.model';
import UserModel from '@/modules/sdk/models/user.model';

export default class RecordUserModel extends Model {

  default() {
    return {
      stage: null,
      userMode: null,
      percentage: 100,
    }
  }

  relatedMap () {
    return {
      recordentity: RecordModel,
      userentity: UserModel,
    }
  }

}
