import { render, staticRenderFns } from "./Wysiwyg.vue?vue&type=template&id=525bf51b&scoped=true"
import script from "./Wysiwyg.vue?vue&type=script&lang=ts"
export * from "./Wysiwyg.vue?vue&type=script&lang=ts"
import style0 from "./Wysiwyg.vue?vue&type=style&index=0&id=525bf51b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "525bf51b",
  null
  
)

export default component.exports