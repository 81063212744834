import { defineStore } from 'pinia';
import UserModel from '@/modules/sdk/models/user.model';

interface StatusState {
  userList: UserModel[];
  canApplyStatus: boolean;
}

export const useStatusStore = defineStore('status', {
  state: (): StatusState => ({
    canApplyStatus: false,
    userList: [],
  }),
  actions: {
    setCanApplyStatus(state: boolean) {
      this.canApplyStatus = state;
    },
    setUserList(items: UserModel[]) {
      this.userList = items;
    },
  },
});
