<template>
  <v-card>

    <AskQuestionToAiModal
      v-if="project.data.aiUseAllowed && project.hasRole(['ai-manager'])"
      v-model="askAiModal.visible"
      :record="askAiModal.record"
      :stage="askAiModal.stage"
      :project="project"
      :disabled="!canChangeStatus"
      :history-headers="askAiModal.headers"
      :history-service="askAiModal.service"
      :default-selected-data="defaultSelectedData"
      can-see-history
      can-select-items
      mode="review"
      title="Ask question to the AI"
      icon="mdi-robot"
      max-width="1600"
      scrollable
      @apply="onAiQuestionApply"
    />

    <ManageRecordUserStatusModal
      v-if="canManageRecordUserStatus()"
      v-model="viewRecordUserStatusModal.visible"
      :reasons="viewRecordUserStatusModal.reasons"
      max-width="600"
      scrollable
      @apply="onApplyStatusReason"
    />

    <StatusReasonModal
      ref="statusReasonModalRef"
      v-model="statusReasonModal.visible"
      :record="record"
      :default-reasons="statusReasonModal.reasons"
      status="halt"
      stage="indepth"
      @confirm="onStatusReasonConfirm"
      @cancel="onStatusReasonCancel"
    />

    <template v-if="showUserList">
      <v-select
        v-model="selectedUser"
        :items="userList"
        :disabled="disabled"
        :item-value="getUserListItemValue"
        label="Show answer(s) from"
        hide-details="auto"
        height="auto"
        clearable
        filled
        @change="onUserChange"
      >
        <template #selection="{ item }">
          <div>
            <h5 class="d-inline" v-text="item.getFullName()"></h5>
            <span class="caption"> (<span v-text="getProjectUserTypeName(item.data.userType)"></span>)</span>
          </div>
        </template>
        <template #item="{ item }">
          <div>
            <h5 class="mb-n2" v-text="item.getLabel()"></h5>
            <span class="caption">(<span v-text="getProjectUserTypeName(item.data.userType)"></span>)</span>
          </div>
        </template>
      </v-select>
    </template>

    <template v-if="record.data.id">

      <!-- LOADING -->
      <v-progress-linear
        v-if="listLoading"
        indeterminate
      ></v-progress-linear>

      <v-list v-else-if="_stageList.length > 0">
        <v-list-item
          :key="stage.type"
          v-for="stage in _stageList"
        >
          <v-list-item-content>

            <v-alert
              v-if="!canChangeStatus"
              :type="changeStatusLabel[0]"
              class="mb-0"
              text
            >
              {{ changeStatusLabel[1] }}
            </v-alert>
            <v-select
              v-else
              v-model="status[stage.type]"
              :loading="stage.loading"
              :items="statusList"
              :label="stage.label"
              :disabled="!canChangeStatus"
              hide-details="auto"
              outlined
              required
              @change="saveStatus(stage)"
            >
              <template #append>
                <div class="d-flex align-center" style="gap: 0.5rem; margin-top: -0.4rem">

                  <!-- CONFLICTING/VIEW ANSWERS -->
                  <v-tooltip v-if="canChangeStatus && reasonsLoaded && canManageRecordUserStatus() && allReasonList[stage.type].items.length > 0" bottom>
                    <template #activator="{ attrs, on }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :disabled="disabled"
                        :color="allReasonList[stage.type].conflicting ? 'error': null"
                        icon
                        @mousedown.prevent.stop="onViewAnswersClick(allReasonList[stage.type].items)"
                      >
                        <v-icon v-if="allReasonList[stage.type].conflicting" class="flashing">mdi-alert</v-icon>
                        <v-icon v-else>mdi-forum</v-icon>
                      </v-btn>
                    </template>
                    <span>
                    <span v-if="allReasonList[stage.type].conflicting" v-text="$t('surveyAnswer.conflictingAnswers', {
                      total: allReasonList[stage.type].items.length,
                    })"></span>
                    <span v-else v-text="$tc('surveyAnswer.viewAnswers', allReasonList[stage.type].items.length, {
                      total: allReasonList[stage.type].items.length,
                    })"></span>
                  </span>
                  </v-tooltip>

                  <!-- ASK TO AI -->
                  <v-tooltip v-if="canChangeStatus && project.data.aiUseAllowed && project.hasRole(['ai-manager'])" bottom>
                    <template #activator="{ attrs, on }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :disabled="disabled"
                        icon
                        @mousedown.prevent.stop="onAskQuestionAi()"
                      >
                        <v-icon small>mdi-robot</v-icon>
                      </v-btn>
                    </template>
                    <span v-text="$t('surveyAnswer.askQuestionAi')"></span>
                  </v-tooltip>
                </div>
              </template>
              <template #item="{item}">
                <v-icon :color="item.color">
                  mdi-circle
                </v-icon>
                <span class="ml-2">
                  {{ item.text }}
                </span>
              </template>
              <template #selection="{item}">
                <v-icon :color="item.color">
                  mdi-circle
                </v-icon>
                <span class="ml-2">
                  {{ item.text }}
                </span>
              </template>
            </v-select>

            <!-- EDIT/SHOW HALT STATUS -->
            <div v-if="canSeeHaltStatus">
              <v-sheet class="mb-0 mt-n1 pa-2 d-flex align-center w-100 grey lighten-1 d-flex align-center justify-space-between" style="gap: 1rem; border-radius: 0 0 0.25rem 0.25rem" dark>
                <div>
                  <v-icon left small>mdi-alert-rhombus-outline</v-icon>
                  <span class="caption" v-text="(statusReasons[stage.type] || []).map(item => item.data.label).join(', ')"></span>
                </div>
                <v-btn
                  v-if="canManageRecordUserStatus()"
                  :disabled="disabled"
                  outlined
                  x-small
                  @click="() => onEditReasonOfExclusionClick(stage)"
                >
                  Edit
                </v-btn>
              </v-sheet>
            </div>

            <!-- ANSWER STATE -->
            <template v-if="reasonsLoaded && canManageRecordUserStatus()">
              <div v-if="allReasonList[stage.type].items.length > 0">
                <div v-if="allReasonList[stage.type].items.length === 1" class="mt-1 ml-2 caption warning--text">
                  <v-icon color="warning" x-small left>mdi-alert</v-icon>
                  <span>Partially answered</span>
                </div>

                <div v-if="canChangeStatus && allReasonList[stage.type].suggestion && status[stage.type] !== allReasonList[stage.type].suggestion?.value" class="mt-1 ml-2 caption d-flex align-center" style="gap: 0.5rem">
                  <span>Suggested status:</span>
                  <v-btn
                    :disabled="disabled"
                    :color="allReasonList[stage.type].suggestion?.color"
                    outlined
                    x-small
                    @click="() => onApplySuggestedStatusClick(stage.type)"
                  >
                    <span v-text="allReasonList[stage.type].suggestion?.text"></span>
                  </v-btn>
                </div>
              </div>
              <div v-else class="mt-1 ml-2 caption warning--text">
                <v-icon color="warning" x-small left>mdi-alert</v-icon>
                <span>Not answered yet</span>
              </div>
            </template>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
    </template>

    <!-- AI HISTORY -->
    <template v-if="aiHistory.length > 0 && project.data.aiUseAllowed && project.hasRole(['ai-manager'])">
      <CollapsableCard
        memory-key="aiHistory"
        class="backgroundVeryLight"
        flat
      >
        <template #title>
          <v-icon :color="getStatus(aiHistory[0].data.status)?.color">mdi-circle</v-icon>
          <span class="ml-8">Last AI response</span>
        </template>
        <template #body>
          <v-list color="transparent" class="mt-n4">
            <v-list-item>
              <v-list-item-content
                v-for="entry in aiHistory"
                :key="entry.data.id"
                class="overflow-visible"
              >
                <v-expansion-panels
                  v-model="splitCommentPanels"
                  multiple
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <div><strong>Pass Probability:</strong> {{ entry.data.passProbability }}%</div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>{{ entry.data.analysis }}</v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="entry.data.clarityScore">
                    <v-expansion-panel-header>
                      <div><strong>Clarity Score:</strong> {{ entry.data.clarityScore }}%</div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content v-if="entry.data.uncertaintyReason">{{ entry.data.uncertaintyReason }}</v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="entry.data.exclusionReason">
                    <v-expansion-panel-header><strong>Exclusion Reason</strong></v-expansion-panel-header>
                    <v-expansion-panel-content>{{ entry.data.exclusionReason }}</v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
<!--                <div class="mt-4 d-flex justify-end">-->
<!--                  <div class="d-flex align-center">-->
<!--                    <v-rating-->
<!--                      v-model.number="entry.data.rating"-->
<!--                      hover-->
<!--                      color="warning"-->
<!--                      background-color="warning"-->
<!--                      @input="onRatingChange(entry)"-->
<!--                    ></v-rating>-->
<!--                  </div>-->
<!--                </div>-->
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </CollapsableCard>
      <v-divider />
    </template>

    <!-- USER REVIEWED LIST -->
    <v-sheet class="backgroundVeryLight">
      <v-alert v-if="userList.length === 0" color="transparent" icon="mdi-account-alert" class="mb-0 caption" tile>
        <span v-text="$t('statusComponent.noUserReview')"></span>
      </v-alert>
      <template v-else>
        <v-subheader class="font-weight-bold pt-4" style="height: auto">
         <span v-text="$t('statusComponent.userReviewed')"></span>
        </v-subheader>
        <v-list color="transparent">
          <v-list-item
            v-for="user in userList"
            v-bind="getUserReviewedAttrs(user)"
            :key="user.autoIncrementId"
          >
            <v-list-item-icon v-if="canManageRecordUserStatus(user.data.id, user.data.userType)">
              <v-icon :color="getColorFromStatus(user.data.status)">mdi-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <div v-text="user.getFullName()"></div>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span v-text="getProjectUserTypeName(user.data.userType)"></span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="canManageRecordUserStatus(user.data.id, user.data.userType)">
              <v-chip label x-small>
                {{ getProjectUserStage(user.data.type) }}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </v-sheet>
  </v-card>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Component, Emit, Prop, Ref, Watch} from 'vue-property-decorator';
import RecordModel from '@/models/record.model';
import RecordService from '@/services/record.service';
import UserModel from '@/modules/sdk/models/user.model';
import Identity from '@/modules/sdk/core/identity';
import ManageRecordUserStatusModal from '@/components/ManageRecordUserStatusModal.vue';
import RecordUserStatusService from '@/services/record-user-status.service';
import RecordUserStatusModel from '@/models/record-user-status.model';
import StatusReasonModal from '@/views/Admin/Component/Record/StatusReasonModal.vue';
import ProjectStatusReasonModel from '@/models/project-status-reason.model';
import AskQuestionToAiModal, { ISelectedData } from '@/components/AskQuestionToAiModal.vue';
import ProjectStatusReasonService from '@/services/project-status-reason.service';
import {SnackError} from '@/modules/common/core/error';
import { projectUserTypeList, statusList } from '@/enums/global';
import RecordAiStatusModel from '@/models/record-ai-status.model';
import RecordAiStatusService from '@/services/record-ai-status.service';
import CollapsableCard from '@/modules/common/components/CollapsableCard.vue';
import moment from 'moment';
import StatusMixin from '@/mixins/status';
import { useStatusStore } from '@/stores/statusStore';

const recordUserStatusChangeCache: {[key: string]: boolean} = {}

interface IStatus {
  text: string,
  color: string,
  value: string,
}

@Component({
  components: {
    CollapsableCard,
    StatusReasonModal,
    ManageRecordUserStatusModal,
    AskQuestionToAiModal,
  }
})
export default class RecordStatusComponent extends StatusMixin {

  @Prop({ default: () => false }) showUserList!: boolean;
  @Prop({ type: Date, default: null }) lastSearchDate?: Date | null;
  @Ref() readonly statusReasonModalRef!: StatusReasonModal

  userList: Array<UserModel> = [];
  statusStore = useStatusStore();
  selectedUser: string = (Identity.getCurrentUserId() || '').toString();
  listLoading = false;
  saveLoading = false;
  reasonsLoaded = false;
  loadingAllReasons = false;
  loadingAiHistory = false;
  aiHistory: Array<any> = [];
  splitCommentPanels = [0, 1, 2]
  statusList = statusList
  askAiModal: {
    visible: boolean,
    record: RecordModel,
    headers: any[],
    service: () => Promise<any>,
    stage: 'screening' | 'indepth' | 'final' | string,
  } = {
    visible: false,
    headers: [],
    service: () => new Promise(() => {}),
    record: new RecordModel(),
    stage: 'screening',
  }

  status: any = {
    screening: 'pending',
    indepth: 'pending',
    final: 'pending',
  };

  allReasonList: {[key: string]: {
      items: Array<RecordUserStatusModel>,
      conflicting: boolean,
      equals: boolean,
      suggestion: IStatus | null,
  }} = {
    screening: {
      items: [],
      conflicting: false,
      equals: false,
      suggestion: null,
    },
    indepth: {
      items: [],
      conflicting: false,
      equals: false,
      suggestion: null,
    },
    final: {
      items: [],
      conflicting: false,
      equals: false,
      suggestion: null,
    },
  }

  originalStatus: any = {}
  statusReasons: { [key: string]: ProjectStatusReasonModel[] | null } = {
    screening: [],
    indepth: [],
    final: [],
  }

  viewRecordUserStatusModal: {
    visible: boolean,
    reasons: Array<RecordUserStatusModel>,
  } = {
    visible: false,
    reasons: [],
  }

  stageList = [
    {
      label: 'Screening Status',
      type: 'screening',
      loading: false,
    },
    {
      label: 'Indepth Status',
      type: 'indepth',
      loading: false,
    },
    {
      label: 'Final Status',
      type: 'final',
      loading: false,
    },
  ];

  @Watch('record.data.id', {
    immediate: true
  })
  onRecordIdChanged(id: number) {
    if (!isNaN(id)) {
      this.loadLastAiHistory();
      this.loadRecordStatus(this.record.data.id, this.selectedUser, this.userType);
    }
  }

  @Watch('userType')
  onUserTypeChanged(userType: string | undefined) {
    const splitUser: Array<string|undefined> = this.selectedUser.split('_');
    splitUser.length < 2 ? splitUser.push(userType) : splitUser[1] = userType;
    this.selectedUser = splitUser.join('_');

    this.loadRecordStatus(this.record.data.id, this.selectedUser, userType);

    if (['arbitrator', 'leader'].includes(userType || '')) {
      this.loadAllReasons()
        .then(reasons => {
          ['screening', 'indepth', 'final'].forEach(type => {
            const items = reasons.filter(reason => reason.data.type === type);
            const statuses = items.map(item => item.data.status);
            const uniqueStatuses = statuses.filter((item, index) => statuses.indexOf(item) === index);

            this.allReasonList[type] = {
              conflicting: uniqueStatuses.length > 1 && this.record.data[type + 'Status'] !== 'pending',
              equals: uniqueStatuses.length === 1 && reasons.length >= 1,
              suggestion: null,
              items,
            };

            if (this.allReasonList[type].equals) {
              this.allReasonList[type].suggestion = statusList.find(status => status.value === this.allReasonList[type].items[0].data.status) || null;
            }

            // If more than two answer with the same value, set it as default in the field
            const counts: {[key: string]: {
                count: number,
                status: any
              }} = {};
            items.forEach(item => {
              if (!counts[item.data.id]) {
                counts[item.data.id] = {
                  count: 0,
                  status: item.data.status,
                };
              }
              if (counts[item.data.id].status === item.data.status) {
                counts[item.data.id].count++;
              }
            })
          })
        });
    }
  }

  onApplySuggestedStatusClick(stage: string) {
    const status = this.allReasonList[stage].suggestion?.value;
    this.saveStatus(
      // this.allReasonList[stage].suggestion,
      this.stageList.find(s => s.type === stage),
      this.record,
      (status && [new ProjectStatusReasonModel({
        label: status,
      })]) || null,
      this.userType,
      status,
    )
  }

  onAskQuestionAi(): void {
    Object.assign(this.askAiModal, {
      visible: true,
      record: this.record,
      stage: this.stage,
      headers: [
        {
          class: 'text-no-wrap',
          cellClass: 'text-no-wrap',
          width: 0,
          text: 'Date',
          value: 'data.createdAt',
        },
        {
          class: 'text-no-wrap',
          cellClass: 'text-no-wrap',
          width: 0,
          text: 'Model',
          value: 'data.airequestentity.data.model',
        },
        {
          class: 'text-no-wrap',
          text: 'Status',
          value: 'data.status',
          width: '10%',
        },
        {
          class: 'text-no-wrap',
          width: 0,
          text: 'Pass Probability',
          value: 'data.passProbability',
        },
        {
          class: 'text-no-wrap',
          width: 0,
          text: 'Clarity',
          value: 'data.clarityScore',
        },
        {
          class: 'text-no-wrap',
          text: 'Analysis',
          value: 'data.analysis',
          width: '33%',
        },
        {
          class: 'text-no-wrap',
          width: 0,
          text: 'Rating',
          value: 'data.rating',
        }
      ],
      service: () => RecordAiStatusService.getInstance().getAll({
        filters: [{
          field: 'recordId',
          operator: 'equals',
          value: this.record.data.id,
        }]
      })
    });
  }

  onViewAnswersClick(reasons: Array<RecordUserStatusModel>): void {
    Object.assign(this.viewRecordUserStatusModal, {
      visible: true,
      reasons,
    })
  }

  onStatusReasonConfirm(reasons: ProjectStatusReasonModel[] | null) {
    const stage = this.stageList.find(stage => stage.type === this.statusReasonModal.stage);
    if (stage) {
      this.saveOriginalStatusList();
      this.saveStatus(stage, this.record, reasons);
    }
  }

  onStatusReasonCancel() {
    this.revertToOriginalStatusList();
  }

  get defaultSelectedData(): ISelectedData {
    const isScreening = this.stage === 'screening';
    return {
      objectives: isScreening,
      picots: isScreening,
      criteria: isScreening,
      keywords: isScreening,
      abstract: isScreening,
      fullText: !isScreening,
    }
  }

  get canSeeHaltStatus(): boolean {
    return this.stage !== 'screening'
      && this.status.indepth === 'halt'
      && (this.statusReasons.indepth || []).length > 0;
  }

  canManageRecordUserStatus(
    userId: number = Identity.getCurrentUserId() || -1,
    userType = this.userType,
  ): boolean {
    return this.userType === 'arbitrator'
      || (
        userType === this.userType
        && userId === Identity.getCurrentUserId()
      );
  }

  getColorFromStatus(status: string): string {
    return statusList.find((item: any) => item.value === status)?.color || '';
  }

  getSelectedUserId(selectedUser = this.selectedUser): number {
    return parseInt((selectedUser || '').split('_')[0]);
  }

  get _stageList(): Array<any> {
    return this.record.data.id && (this.stageList.filter(stage => this.showStage(stage.type)) || []);
  }

  getUserListItemValue(item: UserModel) {
    return item.data.id + '_' + item.data.userType;
  }

  onAiQuestionApply(status: RecordAiStatusModel): Promise<any> {
    return this.saveStatus(
      this.stageList.filter(item => item.type === this.stage),
      this.record,
      undefined,
      undefined,
      status.data.status,
    )
  }

  onApplyStatusReason(reasons: ProjectStatusReasonModel[]): void {
    this.saveStatus(
      this.stageList.find(item => item.type === reasons[0].data.type),
      this.record,
      reasons,
      this.userType,
      reasons[0].data.status,
    )
      .then(() => {
        this.loadAllReasons();
      })
  }

  onEditReasonOfExclusionClick(stage: any) {
    Object.assign(this.statusReasonModal, {
      visible: true,
      reasons: this.statusReasons[stage.type] || [],
      stage: stage.type,
    });
  }

  showStage(stage: 'screening' | 'indepth' | 'final' | string) {
    if (stage === 'screening' && this.stage === stage) {
      return true;
    }
    else if (stage === 'indepth' && this.stage === stage) {
      if (this.record.data.screeningStatus === 'pass') {
        return true;
      }
    }
    else if (stage === 'final' && this.stage === stage) {
      if (this.record.data.screeningStatus === 'pass' && this.record.data.indepthStatus === 'pass') {
        return true;
      }
    }
    return false;
  }

  onUserChange(selectedUser: string) {
    const splitUser: Array<string|undefined> = (this.selectedUser || '').split('_');

    this.loadRecordStatus(this.record.data.id, selectedUser, splitUser[1]);
  }

  clearStatus() {
    this.status.screening = 'pending';
    this.status.indepth = 'pending';
    this.status.final = 'pending';
  }

  getUserReviewedAttrs(user: UserModel): any {
    if (
      user.data.id === Identity.getCurrentUserId()
      && user.data.userType === this.userType
    ) {
      return {
        class: 'v-list-item--active primary--text v-list-item--link just-select',
      }
    }
    return {};
  }

  getStatus(status: string) {
    return statusList.find((item: any) => item.value === status);
  }

  splitComment(comment: string) {
    const splitComments = (comment || '').split(/\[\[([^\]]+)\]\]/gim).map(item => item.trim()).filter(item => item !== '');
    if (splitComments.length === 1) {
      return comment || '';
    }

    const sections = [];
    let section: any = {};
    for (let i = 0; i < splitComments.length; i++) {
      const splitComment = splitComments[i];
      if (i % 2 === 0) {
        section = {};
        section.title = splitComment;
      } else {
        section.body = splitComment;
        sections.push(section);
      }
    }
    return sections;
  }

  clearUserList() {
    this.userList = [];
  }

  saveOriginalStatusList() {
    this.originalStatus = structuredClone(this.status);
  }

  revertToOriginalStatusList() {
    this.status = structuredClone(this.originalStatus);
  }

  getProjectUserStage(type: string): string {
    const item = this.stageList.find(item => item.type === type);
    return item?.label.replace(' Status', '') || type;
  }

  getProjectUserTypeName(type: string): string {
    const item = projectUserTypeList.find(item => item.value === type);
    return item?.text || type;
  }

  onRatingChange(answer: RecordAiStatusModel): void {
    answer.states.saving = true;
    RecordAiStatusService.getInstance().save({
      id: answer.data.id,
      rating: answer.data.rating,
    })
      .then(response => answer.assign(response.data.view.single))
      .finally(() => answer.states.saving = false);
  }

  loadAllReasons(): Promise<Array<RecordUserStatusModel>> {
    this.loadingAllReasons = true;
    return RecordUserStatusService.getInstance().getAll({
      filters: [{
        field: 'recordId',
        operator: 'equals',
        value: this.record.data.id,
      }]
    })
      .then(response => {
        ['screening', 'indepth', 'final'].forEach(type => {
          this.allReasonList[type].items = response.data.view.list.filter((item: RecordUserStatusModel) => item.data.type === type);
        })

        this.reasonsLoaded = true;
        return response.data.view.list;
      })
      .catch(this.$root.$zemit.handleError)
      .finally(() => this.loadingAllReasons = false);
  }

  async loadLastAiHistory(): Promise<any> {
    this.loadingAiHistory = true;
    return RecordAiStatusService.getInstance().getAll({
      limit: 1,
      order: 'id DESC',
      filters: [{
        field: 'recordId',
        operator: 'equals',
        value: this.record.data.id,
      }]
    })
      .then(response => this.aiHistory = response.data.view.list)
      .finally(() => this.loadingAiHistory = false);
  }

  loadRecordStatus(
    recordId: number = this.record.data.id,
    selectedUser: string = this.selectedUser,
    userType = this.userType,
  ) {
    this.listLoading = true;
    this.clearStatus();

    const filters = [
      {field: 'recordId', value: recordId, operator: 'equals'},
      {field: 'deleted', value: 0, operator: 'equals'},
    ];

    RecordUserStatusService.getInstance().getAll({filters})
      .then((response) => {
        if (!response.data.view.list) {
          throw new SnackError({
            title: 'Unable to load user status',
            message: 'Record user status list is empty'
          })
        }

        // User status list found, clear user list
        this.clearUserList();
        response.data.view.list.forEach((recordUserStatus: RecordUserStatusModel) => {

          // Populate the user list for admins
          if (recordUserStatus.data.type === this.stage && !this.userList.find((user: UserModel) => {
            return user.data.id === recordUserStatus.data.userentity?.data.id
              && recordUserStatus.data.userType === user.data.userType
          })) {
            this.userList.push(new UserModel({
              ...recordUserStatus.data.userentity?.data || {
                firstName: 'AI',
              },
              status: recordUserStatus.data.status,
              type: recordUserStatus.data.type,
              userType: recordUserStatus.data.userType,
              createdAt: recordUserStatus.data.createdAt,
            }));
          }

          // Set the status for the requested user
          if (recordUserStatus.data.userId === this.getSelectedUserId(selectedUser) && recordUserStatus.data.userType === userType) {
            this.status[recordUserStatus.data.type] = recordUserStatus.data.status;
            this.statusReasons[recordUserStatus.data.type] = recordUserStatus.data.projectstatusreasonlist?.map((item: any) => new ProjectStatusReasonModel(item));
          }
        });

        // Check if the status of this record has been changed by
        // someone else since last search
        response.data.view.list.forEach((recordUserStatus: RecordUserStatusModel) => {
          const createdAt = moment(recordUserStatus.data.createdAt);
          const lastSearchDate = moment(this.lastSearchDate);
          const cacheKey = recordUserStatus.data.createdBy.toString() + '_' + createdAt.format('YYYY-MM-DD_HH:mm:ss');

          if (
            this.lastSearchDate
            && !recordUserStatusChangeCache[cacheKey]
            && recordUserStatus.data.createdBy !== Identity.getCurrentUserId()
            && createdAt > lastSearchDate
          ) {
            recordUserStatusChangeCache[cacheKey] = true;
            this.$root.$globalSnack.warning({
              message: 'The status of this record has been modified by someone else',
              icon: 'mdi-progress-pencil'
            })
          }
        })

        this.saveOriginalStatusList();
        this.statusStore.setUserList(this.userList);
        this.statusStore.setCanApplyStatus(this.canChangeStatus);
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.listLoading = false);
  }

  @Emit()
  async saveStatus(
    stage: any,
    record: RecordModel = this.record,
    reasons?: ProjectStatusReasonModel[] | null,
    userType = this.userType,
    forceStatus?: string,
  ): Promise<any> {

    if (!this.canChangeStatus) {
      return;
    }

    const status = forceStatus || this.status[stage.type];
    this.status[stage.type] = status;

    const openStatusReasonModal = status === 'halt'
      && ['indepth', 'final'].includes(stage.type)
      && (this.statusReasons.indepth || []).length === 0;

    // When applying the status, if no reason is passed
    // and status is HALT of stage SCREENING, open the StatusReason modal
    if (reasons === undefined && openStatusReasonModal) {
      stage.loading = true;
      return this.statusReasonModalRef.load()
        .then((response) => {
          if (!response.data.view.list) {
            throw new SnackError({
              title: 'The status has not been updated',
              message: 'Unable to retrieve status reason list'
            });
          }
          // Open Status Reason Modal
          Object.assign(this.statusReasonModal, {
            visible: true,
            reasons: [],
            stage: stage.type,
          });
        })
        .catch(reason => {
          this.saveStatusFailed();
          this.$root.$zemit.handleError(reason);
        })
        .finally(() => stage.loading = false);
    }
    else {

      // Save the record status reason at the end
      const callback = async (data: any, reasons?: ProjectStatusReasonModel[] | null) => {
        return RecordService.getInstance().saveUserStatus(data, {
          params: {
            userType,
          }
        })
          .then((response) => {
            if (!response.data.view.saved || !response.data.view.single) {
              throw new SnackError({
                title: 'The status has not been updated',
                message: 'An unexpected error has occurred'
              });
            }

            // Success
            record.assign(response.data.view.single);
            this.$root.$globalSnack.success({
              title: 'Record status has been successfully updated',
              message: 'The `' + stage.type + '` stage is now set to `' + status + '`'
            });
            this.saveStatusSuccess();
            this.statusReasonModalRef.close();
            this.statusReasons[stage.type] = (reasons || []).filter(reason => !reason.data.deleted) || null;
          })
          .catch(reason => {
            this.saveStatusFailed();
            this.$root.$zemit.handleError(reason)
          })
          .finally(() => {
            this.saveLoading = false;
            stage.loading = false;
          });
      }

      stage.loading = true;
      this.saveLoading = true;

      // Record data to save
      const data: any = {
        id: record.data.id
      };
      data[stage.type + 'Status'] = status;
      data[stage.type + 'ProjectStatusReasonList'] = [false];

      const modelList: ProjectStatusReasonModel[] = [];
      (reasons || [true]).forEach(reason => {
        if (reason instanceof ProjectStatusReasonModel && !reason.data.deleted) {
          if (reason.data.id) {
            data[stage.type + 'ProjectStatusReasonList'].push(reason.data.id);
          }
          else {
            modelList.push(new ProjectStatusReasonModel({
              projectId: record.data.projectId,
              label: reason.data.label,
              status,
              stage: stage.type,
            }));
          }
        }
      });

      if (modelList.length === 0) {
        // @ts-ignore
        return callback(data, reasons || undefined);
      } else {
        return ProjectStatusReasonService.getInstance().save(modelList)
          .then(response => {
            for (let i = 0; i < response.data.view.length; i++) {
              const data = response.data.view[i];
              if (!data.saved || !data.single) {
                throw new SnackError({
                  title: 'The status reason has not been saved',
                  message: 'An unexpected error has occurred'
                });
              }
            }

            // success
            data[stage.type + 'ProjectStatusReasonList'] = [];
            response.data.view.forEach((item: any) => {
              data[stage.type + 'ProjectStatusReasonList'].push(item.single.id);
            })
            callback(data, reasons);
          })
          .catch(reason => {
            this.$root.$zemit.handleError(reason);
            this.saveLoading = false;
            stage.loading = false;
          });
      }
    }
  }

  @Emit()
  saveStatusSuccess() {
    this.loadRecordStatus(this.record.data.id, this.selectedUser, this.userType);
  }

  @Emit()
  saveStatusFailed() {}
}
</script>
