<template>
  <v-input
    v-bind="_attrs"
    :label="null"
  >
    <fieldset v-if="ready">
      <legend v-if="$attrs.label" v-text="$attrs.label"></legend>

      <div ref="instance" style="min-height: 240px"></div>
    </fieldset>
  </v-input>
</template>

<script lang="ts">
import 'reflect-metadata';
import tinymce, { Editor } from 'tinymce';
import 'tinymce/themes/silver/theme';
import 'tinymce/models/dom/model';
import 'tinymce/icons/default/icons';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/skins/ui/oxide/content.css';
import 'tinymce/skins/content/default/content.css';
import 'tinymce/plugins/lists/index'
import 'tinymce/plugins/link/index'
import 'tinymce/plugins/code/index'
import { Vue, Component, Ref, Prop, VModel, Watch } from 'vue-property-decorator';
import Identity from '@/modules/sdk/core/identity';

@Component
export default class Wysiwyg extends Vue {

  @VModel() content!: string;
  @Ref() readonly instance!: HTMLDivElement
  @Prop({ type: Number, default: () => 350 }) height!: number
  @Prop({ default: () => ({}) }) settings?: any
  @Prop({ default: () => ([
    'bold italic underline removeformat',
    'numlist bullist outdent indent subscript superscript',
    'link',
  ]) }) toolbar!: Array<string>

  ready = true
  editor: Editor | null = null

  @Watch('content')
  onContentChange(content: string | null) {
    /**
     * Latest version of TinyMCE has a vug with its Vue2 wrapper.
     * Reinitializing its instance on content change fix the issue.
     */
    if (content !== this.editor?.getContent()) {
      this.editor?.setContent(content || '');
      this.reinit();
    }
  }

  @Watch('$vuetify.theme.dark')
  onThemeChange() {
    this.reinit();
  }

  get _attrs(): any {
    return this.$attrs;
  }

  insertAtCaret(content: string): void {
    this.editor?.execCommand('mceInsertContent', false, '{{' + content + '}}');
  }

  reinit() {
    this.editor?.destroy()
    this.ready = false;
    this.$nextTick(() => {
      this.ready = true;
      this.$nextTick(this.init);
    });
  }

  init() {
    if (this.instance) {
      const toolbar = structuredClone(this.toolbar);
      if (Identity.hasRole(['dev'])) {
        toolbar.push('code')
      }

      tinymce.init({
        target: this.instance,
        placeholder: 'Type here...',
        readonly: this.$attrs.readonly === 'true' || false,
        height: this.height,
        min_height: 150,
        max_height: 800,
        skin: false,
        content_css: false,
        keep_styles: false,
        remove_trailing_brs: false,
        menubar: false,
        branding: false,
        relative_urls: true,
        allow_conditional_comments: false,
        allow_html_in_named_anchor: false,
        allow_unsafe_link_target: false,
        default_link_target: '_blank',
        toolbar_sticky: true,
        toolbar_sticky_offset: 64,
        plugins: 'lists link code',
        valid_elements: 'p,b,i,u,a[href|title|target=_blank],strong,br,sup,sub,table,td,tr,th,dt,ul,ol,li,h1,h2,h3,h4,h5,h6,span,div,br',
        toolbar: toolbar.join(' | '),
        setup: editor => {
          editor.on('init', () => {
            editor.setContent(this.content || '');
          })
          editor.on('blur', () => {
            this.content = editor.getContent() || '';
          });
          this.editor = editor;
        },
      });
    }
  }

  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.v-input {
  fieldset {
    color: rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    border: rgba(0, 0, 0, 0.24) solid 1px;
    width: 100%;
    position: relative;

    legend {
      color: rgba(0, 0, 0, .698);
      padding: 0 4px;
      margin-left: 8px;
      font-size: 12px;
    }

    ::v-deep .tox-tinymce {
      border: 0;
      margin-top: -0.4rem;
    }
  }
}
.v-input.error--text {
  & ::v-deep {
    .v-messages {
      padding-left: 0.5rem;
    }
    .v-icon {
      color: var(--v-error-base) !important;
    }
  }
}
.theme--dark {
  .v-input {
    fieldset {
      color: rgba(255, 255, 255, 0.38);
      border: rgba(255, 255, 255, 0.38) solid 1px;

      legend {
        color: rgba(255, 255, 255,.698);
      }
    }
  }
}
</style>
