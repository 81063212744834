import {alphabetList} from '@/enums/global';

export interface IQueryItem {
  category?: string | null,
  field: string | null,
  operator: string | null,
  logic: string | null,
  isJoin: boolean,
  subquery?: boolean, // Required by backend (for joins)
  list: string[],
  value: Array<string> | null,
  group: Array<IQueryItem>,
}

export interface IQuerySubmitItem {
  logic: string | null,
  field: string | null,
  operator: string | null,
  value: string[] | null,
  group: any[] | null,
}

export interface IMatch {
  check: boolean,
  amount: number,
  value: boolean,
}

export default class Query {

  static applyMapper(
    filters: Array<any>,
    mapper: (condition: any) => any = (condition) => { return condition },
  ): any {
    return JSON.parse(JSON.stringify(filters)).map((item: any) => Array.isArray(item) ? this.applyMapper(item) : mapper(item))
  }

  static getValidFilters(
    filters: Array<IQueryItem>,
    projectId?: number | null,
    mapper: (condition: any) => any = (condition) => { return condition },
  ): any {
    const mapValidFilters = (items: Array<IQueryItem>): IQueryItem[] => {
      return this.applyMapper(items, mapper).filter((item: IQueryItem) => {
        if ((item.group || []).length > 0) {
          item.group = mapValidFilters(item.group);
        }
        // Only return items that contains a field and a value
        return ((!!item.field && !!item.value) || (item.group || []).length > 0)
          || (!!item.field && !item.value && ['is empty', 'is not empty'].includes(item.operator || ''));
      });
    }
    const filtersClone: IQueryItem[] = JSON.parse(JSON.stringify(filters));
    const validFilters: any[] = [];
    filtersClone.forEach(filter => {
      if (Array.isArray(filter)) {
        validFilters.push(...this.getValidFilters(filter, undefined, mapper));
      } else {
        validFilters.push(...mapValidFilters([filter]));
      }
    })

    const prepend = projectId ? [{
      field: 'projectId',
      value: projectId,
      operator: 'equals'
    }] : [];

    return validFilters.length > 0 ? [...prepend, validFilters] : prepend;
  }

  static getJoins(items: any[]): {[key: string]: IQueryItem[]} {
    const joins: {[key: string]: IQueryItem[]} = {};
    const callback = (items: any[]) => {
      for (const item of items) {
        if (Array.isArray(item.group)) {
          callback(item.group)
        }
        if (item.onGetJoin) {
          const results = item.onGetJoin(item, joins);
          if (results) {
            Object.assign(joins, results);
          }
        }
      }
    }
    callback(items);
    return joins;
  }

  static applyJoinKeys(items: any[]) {
    const keyCache: any[] = [];
    const callback = (items: any[], applyKey = false) => {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.isJoin) {
          const value = item.joinValue || item.value;
          if (!keyCache[value]) {
            keyCache[value] = [];
          }
          const nextLetter = alphabetList.find(letter => !keyCache[value].includes(letter.value));
          if (nextLetter) {
            if (!applyKey && item.key) {
              keyCache[value].push(item.key);
            }
            if (applyKey && !item.key) {
              item.key = nextLetter.value;
            }
          }
        }
        callback(item.group, applyKey);
      }
    }
    callback(items);
    callback(items, true);
  }

  static prepareFilters(items: Array<any>, mapLogic = true, depth = 0): IQuerySubmitItem[] {
    const results: any[] = [];
    for (let i = 0; i < items.length; i++) {
      results[i] = { ...items[i] };

      // If item contains multiple items (group)
      if (mapLogic && (results[i].group || []).length) {
        function mapFirstLogic(items: Array<any>, logic: string) {
          if ((items[0].group || []).length) {
            mapFirstLogic(items[0].group, logic);
          } else {
            items[0].logic = logic;
          }
        }
        mapFirstLogic(results[i].group, results[i].logic);
        if (results[i].isJoin) {
          const joinMapper = (child: any, parent: any) => {
            const childOriginalField = child.originalField ? child.originalField : (child.joinValue || child.field);
            const key = parent.key;
            return {
              ...child,
              originalField: childOriginalField,
              field: (parent.joinValue || parent.field) + '[' + key + '].' + childOriginalField,
            };
          };

          results[i].group = results[i].group.map((child: any) => joinMapper(child, results[i]));
          for (let j = 0; j < results[i].group.length; j++) {
            const child = results[i].group[j];
            if (child.group?.length > 0) {
              child.group = child.group.map((item: any) => joinMapper(item, child))
            }
          }
        }
      }

      const preparedGroup = this.prepareFilters(results[i].group, mapLogic, depth + 1);

      // Run before join hooks
      if (results[i].onPrepareJoinItem) {
        results[i] = results[i].onPrepareJoinItem(preparedGroup, results[i].group, depth);
      } else if (results[i].type === 'join') {
        results[i] = preparedGroup;
      }

      // Flatten values if necessary
      if (Array.isArray(results[i].value)) {
        results[i].value = results[i].value.map((value: any) => {
          return typeof value === 'object' && value.value
            ? value.model ? value : value.value
            : value
        })
      }

      // Only return what backend needs
      if (!Array.isArray(results[i])) {
        Object.keys(results[i]).forEach(key => {
          if (!['logic', 'field', 'operator', 'value', 'group', 'sum'].includes(key)) {
            delete results[i][key];
          }
        })
      }
    }

    return results.filter(Boolean); // Remove empty objects
  }

  static filterItems(filters: Array<IQueryItem>, rows: Array<any>) {
    const filteredRows: Array<{[key: string]: string}> = [];
    const validFilters = Query.getValidFilters(filters, undefined, undefined);

    if (validFilters.length === 0) {
      return rows;
    }

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      if (Query.analyze(validFilters, row)) {
        filteredRows.push(row);
      }
    }

    return filteredRows;
  }

  static analyze(queries: Array<IQueryItem>, values: {[key: string]: any}, match?: IMatch): boolean {
    const results: Array<[string | null, boolean]> = [];

    let totalValidMatches = 0;
    for (let i = 0; i < queries.length; i++) {
      const query = queries[i];

      if (Array.isArray(query)) {
        return this.analyze(query, values, match);
      }

      if ((query.group || []).length > 0) {
        results.push([query.logic, this.analyze(query.group, values, match)]);
      } else if (query.field && query.operator) {
        const valueArr = Array.isArray(query.value) ? query.value : [query.value];
        if (match && match.check) {
          const valueItemVal = this.checkValuesOnField(query.operator, valueArr, values[query.field])
          if (valueItemVal === match.value) {
            totalValidMatches++;
          }
        } else {
          results.push([query.logic, this.checkValuesOnField(query.operator, valueArr, values[query.field])]);
        }
      }
    }
    if (match && match.check) {
      results.push([queries[0].logic, totalValidMatches >= match.amount]);
    }
    return this.checkResults(results);
  }

  static checkResults(results: Array<[string | null, boolean]>): boolean {
    let evalStr = '';
    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      evalStr += (i > 0
        ? result[0] === 'and' ? ' && ' : ' || '
        : '') + result[1];
    }
    // eslint-disable-next-line no-eval
    return eval(evalStr) || evalStr === '';
  }

  static checkValuesOnField(operator: string, values: Array<any>, comparator: any): boolean {
    const mustBeAllTrue = [
      'does not equal',
      'does not contain',
      'does not contain word',
    ].includes(operator);
    for (let j = 0; j < values.length; j++) {
      const value = values[j];
      const valid = this.checkValueOnField(operator, value, comparator);
      if (!valid && mustBeAllTrue) {
        return false
      } else if (valid && !mustBeAllTrue) {
        return true;
      }
    }
    return mustBeAllTrue;
  }

  static extractFloat(value: any): number | null {
    if (typeof value === 'string') {
      const regex = /[-+]?[0-9]*\.?[0-9]+/g;
      const matches = value.match(regex);
      if (matches && matches.length > 0) {
        return parseFloat(matches[0]);
      }
    }
    return null;
  }

  static checkValueOnField(operator: string, valueA: any, valueB: any): boolean {

    if ((valueA === undefined || valueB === undefined) && valueA !== valueB) {
      return false;
    }

    if ((valueA === null || valueB === null) && valueA !== valueB && !['is empty', 'is not empty'].includes(operator)) {
      return false;
    }

    switch (operator) {
      case 'equals':
      case 'does not equal':
        return (operator === 'equals' && valueA === valueB)
          || (operator === 'does not equal' && valueA !== valueB);
      case 'contains':
      case 'does not contain':
        return (operator === 'contains' && valueB.toString().toLowerCase().indexOf(valueA.toString().toLowerCase()) !== -1)
          || (operator === 'does not contain' && !(valueB.toString().toLowerCase().indexOf(valueA.toString().toLowerCase()) !== -1));
      case 'contains word':
      case 'does not contain word':
        return (operator === 'contains word' && new RegExp('\\b' + valueA.toString() + '\\b', 'i').test(valueB))
          || (operator === 'does not contain word' && !new RegExp('\\b' + valueA.toString() + '\\b', 'i').test(valueB));
      case 'begins with':
      case 'ends with':
        return (operator === 'begins with' && valueB.toString().toLowerCase().startsWith(valueA.toString().toLowerCase()))
          || (operator === 'ends with' && valueB.toString().toLowerCase().endsWith(valueA.toString().toLowerCase()));
      case 'greater than':
      case 'greater than or equal':
      case 'less than':
      case 'less than or equal':
        const compareValueA = this.extractFloat(valueA) || valueA;
        const compareValueB = this.extractFloat(valueB) || valueB;
        const allNumbers = /^\d+$/.test(compareValueA) && /^\d+$/.test(compareValueB);
        return allNumbers && (
            (operator === 'greater than' && compareValueB > compareValueA)
            || (operator === 'greater than or equal' && compareValueB >= compareValueA)
            || (operator === 'less than' && compareValueB < compareValueA)
            || (operator === 'less than or equal' && compareValueB <= compareValueA)
        );
      case 'is empty':
      case 'is not empty':
        return (operator === 'is empty' && (valueB === null || valueB === ''))
          || (operator === 'is not empty' && !(valueB === null || valueB === ''));
      case 'regexp':
      case 'not regexp':
        return (operator === 'regexp' && new RegExp(valueA, 'i').test(valueB))
          || (operator === 'not regexp' && !(new RegExp(valueA, 'i').test(valueB)));
      case 'is multiple':
      case 'is not multiple':
        return (operator === 'is multiple' && (valueB || '').split(',').length > 1)
          || (operator === 'is not multiple' && (valueB || '').split(',').length <= 1);
    }
    return true;
  }
}
