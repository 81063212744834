<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'status-reason'"
      v-model="model"
      :service="service"
      :loading="loading"
      :parent-route="parentRoute"
      name="status-reason"
      title="Exclusion Reasons"
    >
      <template #form="{ formErrors, rules }">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="model.data.projectId"
              :error-messages="formErrors.projectId"
              :loading="loadingProjectList"
              :search-input="projectListSearch"
              :items="projectList"
              outlined
              label="Project"
              placeholder="Select a project if you want to limit this exclusion to that specific project"
              item-text="data.label"
              item-value="data.id"
              hide-details="auto"
              clearable
              cache-items
              @input="formErrors = {}"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="model.data.label"
              v-safechar
              :error-messages="formErrors.label"
              :rules="[rules.required]"
              label="Reason"
              outlined
              hide-details="auto"
              clearable
              required
              @input="formErrors = {}"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="model.data.stage"
              :error-messages="formErrors.stage"
              :rules="[rules.required]"
              :items="stageList"
              outlined
              label="Stage"
              hide-details="auto"
              clearable
              required
              @input="formErrors = {}"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="model.data.status"
              :error-messages="formErrors.status"
              :rules="[rules.required]"
              :items="statusList"
              outlined
              label="Status"
              hide-details="auto"
              clearable
              required
              readonly
              @input="formErrors = {}"
            >
              <template #item="{item}">
                <v-icon :color="item.color">
                  mdi-circle
                </v-icon>
                <span class="ml-2">
                  {{ item.text }}
                </span>
              </template>
              <template #selection="{item}">
                <v-icon :color="item.color">
                  mdi-circle
                </v-icon>
                <span class="ml-2">
                  {{ item.text }}
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="4">
            <v-checkbox
              v-model="model.data.isDefault"
              :error-messages="formErrors.isDefault"
              :rules="[]"
              color="primary"
              label="Is default?"
              hide-details
              class="mt-0 pt-0"
              @input="() => delete formErrors.isDefault"
            />
          </v-col>
        </v-row>
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Watch, Prop} from 'vue-property-decorator';
import DataForm from '@/modules/common/components/DataForm.vue';
import Logger from '@/modules/sdk/core/logger';
import ProjectService from '@/services/project.service';
import {stageList, statusList} from '@/enums/global';
import ProjectStatusReasonService from '@/services/project-status-reason.service';
import ProjectStatusReasonModel from '@/models/project-status-reason.model';
import {Route} from 'vue-router';

let projectListSearchTimeout: any;

@Component({
  components: {
    DataForm,
  }
})
export default class ProjectStatusReasonForm extends Vue {
  @Prop({ type: Number, default: null }) id!: number
  @Prop({ type: Number, default: null }) projectId!: number

  service = ProjectStatusReasonService.getInstance();
  loading = false
  model: ProjectStatusReasonModel = new ProjectStatusReasonModel();
  parentRoute: Partial<Route> = { name: 'ProjectStatusReasonList' };

  loadingProjectList = false
  projectList = [];
  projectListSearch = '';

  stageList = stageList.filter(stage => stage.value !== 'screening')
  statusList = statusList.filter(item => item.value !== 'conflict')

  get _projectId(): number | null {
    return this.projectId || parseInt(this.$route.params.projectId);
  }

  search(search: string | null = this.projectListSearch) {
    clearTimeout(projectListSearchTimeout);
    projectListSearchTimeout = setTimeout(() => {
      this.loadingProjectList = true;
      ProjectService.getInstance().getAll({ search })
        .then(response => this.projectList = response.data.view.list)
        .finally(() => this.loadingProjectList = false);
    }, 500);
  }

  load(id: number) {
    this.loading = true;
    return this.service.get({id})
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id)).then(() => this.search());
    } else {
      this.search();
    }
    if (this._projectId) {

      // set parent route
      this.parentRoute = { name: 'ProjectForm', params: { id: this._projectId.toString() } }

      // automatically select the projectId
      if (!this.model.data.projectId) {
        this.model.data.projectId = this._projectId;
      }
    }
  }
}
</script>
