<template>
  <div v-bind="$attrs">
    <slot name="title">
      <h3 v-if="title">{{title}}</h3>
      <v-divider class="mt-1 mb-3" />
    </slot>

    <!-- METRICS -->
    <v-card color="backgroundVeryLight" class="pa-4" flat>
      <v-row>
        <v-col cols="4" v-for="(stage, stageIndex) in stageList" :key="stageIndex" style="position: relative">
          <v-overlay v-if="loading" absolute opacity="0">
            <v-progress-circular color="primary" size="64" width="3" indeterminate />
          </v-overlay>

          <div :style="{
            opacity: loading ? 0.5 : 1
          }">
            <template v-if="hasStatusData(stage.key)">
              <h4>{{stage.label}}</h4>
              <Pie
                :chart-data="getStatusData(stage.key)"
                :chart-options="statusOptions"
              />

              <h5>Match rate</h5>
              <v-progress-linear
                :value="getMatchRatePercentage(stage.key)"
                color="success"
                background-color="error"
                height="18"
              >
                <template v-slot:default="{ value }">
                  <span class="white--text">{{ value }}%</span>
                </template>
              </v-progress-linear>
            </template>
            <div
              v-else
              class="d-flex flex-column h-100 w-100"
            >
              <h4 class="mb-2" style="flex: 0">{{stage.label}}</h4>
              <div
                class="d-flex flex-column align-center justify-center text-center py-6"
                style="flex: 1; min-height: 425px; border: 3px dashed lightgrey; border-radius: 1rem"
              >
                <v-icon disabled large>mdi-cloud-question</v-icon>
                <span class="text-body-1">No data yet</span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>

</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop} from 'vue-property-decorator';
import Logger from '@/modules/sdk/core/logger';
import { Bar, Pie, Doughnut } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'
import ProjectProgressComponent from '@/views/Admin/Component/ProjectProgressComponent.vue';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

const d = new Logger('views/Admin/Component/MetricsComponent');

type TStatus = 'screening' | 'indepth' | 'final'

export interface MetricsStatusInterface {
  pass: number,
  pending: number,
  halt: number,
  skip: number,
  mismatch: number,
  total: number,
}

export interface MetricsInterface {
  screening: MetricsStatusInterface,
  indepth: MetricsStatusInterface,
  final: MetricsStatusInterface,
}

@Component({
  components: {
    Bar,
    Pie,
    Doughnut,
    ProjectProgressComponent,
  }
})
export default class MetricsComponent extends Vue {
  @Prop({ type: Object, default: () => ({}) }) metrics!: MetricsInterface;
  @Prop() title!: string;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ default: () => {} }) statusOptions: any;

  stageList: Array<{
    label: string,
    key: TStatus
  }> = [
    {
      label: 'Screening',
      key: 'screening'
    },
    {
      label: 'In-Depth',
      key: 'indepth'
    },
    {
      label: 'Data Extraction',
      key: 'final'
    }
  ];

  get hasMetrics(): boolean {
    return !!Object.keys(this.metrics).length
  }

  hasStatusData(status: TStatus) {
    return (
        this.metrics[status]?.pass
        + this.metrics[status]?.pending
        + this.metrics[status]?.halt
        + this.metrics[status]?.skip
      )
      > 0;
  }

  getStatusData(status: TStatus) {
    const count = this.metrics[status]?.pass
      + this.metrics[status]?.pending
      + this.metrics[status]?.halt
      + this.metrics[status]?.skip;
    return {
      labels: [
        ' Pass ',
        ' Pending ',
        ' Halt ',
        ' Skip ',
        ' Unknown ',
      ],
      datasets: [
        {
          label: status,
          data: [
            this.metrics[status]?.pass,
            this.metrics[status]?.pending,
            this.metrics[status]?.halt,
            this.metrics[status]?.skip,
            this.metrics[status]?.total - count,
          ],
          backgroundColor: [
            this.$vuetify.theme.currentTheme.success,
            this.$vuetify.theme.currentTheme.warning,
            this.$vuetify.theme.currentTheme.error,
            this.$vuetify.theme.currentTheme.skip,
            this.$vuetify.theme.currentTheme.backgroundDark,
          ]
        }
      ],
    };
  }

  getMatchRatePercentage(status: TStatus) {
    const totalHaltPass = this.metrics[status]?.halt + this.metrics[status]?.pass;
    return 100 - (Math.round(((this.metrics[status]?.mismatch * 100 / totalHaltPass) || 0) * 100) / 100);
  }
}
</script>
