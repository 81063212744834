import Model from '../core/model';
import RoleModel from './role.model';
import GroupModel from './group.model';
import TypeModel from './type.model';
import {projectUserTypeList} from '@/enums/global';

export default class UserModel extends Model {

  userType: string | undefined

  default() {
    return {
      rolelist: [],
      grouplist: [],
      typelist: [],
    };
  }

  relatedMap () {
    return {
      rolelist: RoleModel,
      grouplist: GroupModel,
      typelist: TypeModel,
    }
  }

  relatedDeleteMissing() {
    return {
      rolelist: true,
      grouplist: true,
      typelist: true,
    };
  }

  get fullName(): string {
    return this.getFullName();
  }

  get label(): string {
    return this.getLabel();
  }

  getLabel(): string {
    return this.getFullName();
  }

  getLabelWithMode(): string {
    const mode = (projectUserTypeList.find(projectUserType => projectUserType.value === this.userType) || {}).text;
    return !mode ? this.getFullName() : this.getFullName() + ' (' + mode + ')'
  }

  getFullName(): string {
    return ((this.data.firstName || '') + ' ' + (this.data.lastName || '')).trim();
  }

  getInitials(): string {
    const str = ((this.data.firstName || '') + ' ' + (this.data.lastName || '')).trim();
    return str.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '');
  }
}
