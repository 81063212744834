import {IQueryItem} from '@/modules/sdk/core/query';

interface TutorialItem {
  type: 'dev' | 'general'
  key: string
  label: string
  body: string
  thumbnail: string
  youtube: string
}

export interface IValue {
  text: string,
  value: any,
}

const contactTypeList = [
  { value: 'comment', text: 'Comment only' },
  { value: 'help', text: 'General help' },
  { value: 'bug', text: 'Report a bug' },
];

const logicList = [
  { value: 'and', text: 'And' },
  { value: 'or', text: 'Or' },
];

const stringOperators: Array<string> = [
  'contains',
  'does not contain',
  'contains word',
  'does not contain word',
  'starts with',
  'does not start with',
  'ends with',
  'does not end with',
  'equals',
  'does not equal',
  'is empty',
  'is not empty',
  'regexp',
  'not regexp',
];
const stringOperatorsNoRegex = stringOperators.filter(item => !['regexp', 'not regexp'].includes(item));

const numberOperators: Array<string> = [
  'equals',
  'does not equal',
  'greater than',
  'greater than or equal',
  'less than',
  'less than or equal',
  'is empty',
  'is not empty',
];

const selectionOperators: Array<string> = [
  'equals',
  'does not equal',
  'is empty',
  'is not empty',
];

const fieldList = [
  { value: 'authors', text: 'Authors', operators: stringOperators },
  { value: 'abstract', text: 'Abstract', operators: stringOperators, list: 'keywords' },
  { value: 'affiliations', text: 'Affiliations', operators: stringOperators },
  { value: 'doi', text: 'DOI', operators: stringOperators },
  { value: 'id', text: 'Record ID', operators: numberOperators },
  { value: 'pid', text: 'Project Record ID', operators: numberOperators },
  { value: 'references', text: 'Reference', operators: stringOperators },
  { value: 'sourceType', text: 'Source type', operators: selectionOperators, list: 'sourceTypes' },
  { value: 'title', text: 'Title', operators: stringOperators, list: 'keywords' },
  { value: 'url', text: 'URL', operators: stringOperators },
  { value: 'uid', text: 'Uid', operators: stringOperators },
  { value: 'year', text: 'Year', operators: numberOperators },
  { value: 'language', text: 'Language', operators: stringOperators, list: 'languages' },
  { value: 'ovid', text: 'Ovid', operators: stringOperators }
];

const sourceTypeList = [
  { value: 'client', text: 'Client', list: 'sourceTypes' },
  { value: 'literature', text: 'Literature Search', list: 'sourceTypes' },
  { value: 'pragmatic', text: 'Pragmatic Search', list: 'sourceTypes' },
  { value: 'snowballing', text: 'Snowballing', list: 'sourceTypes' },
];

const recordFieldList = [
  { value: 'authors', text: 'Authors' },
  { value: 'abstract', text: 'Abstract', list: 'keywords' },
  { value: 'affiliations', text: 'Affiliations' },
  { value: 'doi', text: 'DOI' },
  { value: 'identifier', text: 'Identifier' },
  { value: 'language', text: 'Language', list: 'languages' },
  { value: 'ovid', text: 'Ovid' },
  { value: 'references', text: 'Reference' },
  { value: 'sourceType', text: 'Source Type', list: 'sourceTypes' },
  { value: 'title', text: 'Title', list: 'keywords' },
  { value: 'url', text: 'URL' }
];

const officialStatusFieldList = [
  { value: 'screeningStatus', text: 'Screening', list: 'status' },
  { value: 'indepthStatus', text: 'In-depth', list: 'status' },
  { value: 'finalStatus', text: 'Data Extraction', list: 'status' },
];

const statusReasonFieldList = [
  { value: 'indepth', text: 'In-depth (Reason of exclusion)', list: 'projectStatusReasons' },
  { value: 'final', text: 'Data Extraction (Reason of exclusion)', list: 'projectStatusReasons' },
];

const alphabetList = Array.from(Array(26)).map((_, i) => {
  const value = String.fromCharCode(i + 97);
  return {
    text: value.toUpperCase(),
    value,
  }
});

/**
 * Check loadedList in ProjectQueryBuilder.vue for list names (key of loadedList)
 */
const recordRelatedFieldList = [
  { value: 'Article', type: 'join', text: 'Article', children: [
    { value: 'title', text: 'Title', list: 'keywords', operators: stringOperatorsNoRegex },
    { value: 'body', text: 'Body', list: 'keywords', operators: stringOperatorsNoRegex },
  ] },
  { value: 'Comment', type: 'join', text: 'Comment', children: [
    { value: 'content', text: 'Content', list: 'comments', operators: stringOperatorsNoRegex },
  ] },
  { value: 'RecordUserStatus', type: 'join', text: 'Reviewed By', children: [
    { value: 'userId', text: 'User', list: 'users', operators: stringOperatorsNoRegex },
    { value: 'status', text: 'Status', list: 'status', operators: stringOperatorsNoRegex },
    { value: 'type', text: 'Stage', list: 'stage', operators: stringOperatorsNoRegex },
    { value: 'userType', text: 'Mode', list: 'userType', operators: stringOperatorsNoRegex },
    { value: 'RecordUserStatusReason', text: 'Status Reason', type: 'join', children: [
      { value: 'createdBy', text: 'User', list: 'users', operators: stringOperatorsNoRegex },
      { value: 'ProjectStatusReason.label', text: 'Reason', list: 'statusReasons', operators: stringOperatorsNoRegex },
    ] },
  ] },
  { value: 'RecordUser', type: 'join', text: 'Assigned to', children: [
    { value: 'userId', text: 'User', list: 'users', operators: stringOperatorsNoRegex },
    { value: 'userMode', text: 'Mode', list: 'userMode', operators: stringOperatorsNoRegex },
    { value: 'stage', text: 'Stage', list: 'stage', operators: stringOperatorsNoRegex },
  ] },
  { value: 'RecordTag[null]', type: 'join', text: 'Tag', children: [
    { value: 'Tag.label{null}', text: 'Label', list: 'tags', operators: stringOperatorsNoRegex, },
  ], onGetJoin: (item: any) => {
    const ret: {[key: string]: any[]} = {};
    ret['RecordTag[null_' + item.key + ']'] = [{
      logic: 'and',
      field: 'RecordTag[null_' + item.key + '].categoryId',
      operator: 'is null',
      value: null,
      group: [],
    }];
    return ret;
  }, onPrepareJoinItem: (items: IQueryItem[]) => {
    return items.map((item: IQueryItem) => ({
      ...item,
      subquery: true, // Required by backend
      field: item.field?.replace('[null][', '[null_').replace(/{[^}]*}/g, '')
    }))
  }, },
];

const operatorList = [
  { value: 'contains', text: 'Contains' },
  { value: 'does not contain', text: 'Does not contain' },

  { value: 'contains word', text: 'Contains word' },
  { value: 'does not contain word', text: 'Does not contain word' },

  { value: 'starts with', text: 'Starts with' },
  { value: 'does not start with', text: 'Does not start with' },

  { value: 'ends with', text: 'Ends with' },
  { value: 'does not end with', text: 'Does not end with' },

  { value: 'equals', text: 'Equals' },
  { value: 'does not equal', text: 'Does not equal' },

  { value: 'greater than', text: 'Greater than' },
  { value: 'greater than or equal', text: 'Greater than or equal' },

  { value: 'less than', text: 'Less than' },
  { value: 'less than or equal', text: 'Less than or equal' },

  { value: 'is empty', text: 'Is empty' },
  { value: 'is not empty', text: 'Is not empty' },

  { value: 'regexp', text: 'Matches regular expression' },
  { value: 'not regexp', text: 'Does not match regular expression' },
];

const stageList = [
  { value: 'screening', text: 'Screening' },
  { value: 'indepth', text: 'In-depth' },
  { value: 'final', text: 'Data Extraction' },
];

const taskActionList = [
  { value: 'ai_project_review', text: 'AI Project Review' },
  { value: 'ai_project_data_extraction', text: 'AI Project Data Extraction' },
  { value: 'project_record_compare', text: 'Project Record Compare' },
];

const taskStatusList = [
  { text: 'New', value: 'new', color: '', icon: null, },
  { text: 'Running', value: 'running', color: 'info', icon: 'mdi-loading mdi-spin', },
  { text: 'Finished', value: 'finished', color: 'success', icon: 'mdi-check', },
  { text: 'Failed', value: 'failed', color: 'error', icon: 'mdi-alert', },
  { text: 'Canceled', value: 'canceled', color: '', icon: 'mdi-close', },
];

const officialQueryStatusList = [
  { value: 'pending', text: 'Pending', color: 'warning' },
  { value: 'halt', text: 'Halt', color: 'error' },
  { value: 'pass', text: 'Pass', color: 'success' },
  { value: 'skip', text: 'Skip', color: 'grey' },
  { value: 'conflict', text: 'Conflict - Status', color: 'yellow' },
  { value: 'conflict-status-reason', text: 'Conflict - Exclusion Reason', color: 'yellow' },
];

const statusList = [
  { value: 'pending', text: 'Pending', color: 'warning' },
  { value: 'halt', text: 'Halt', color: 'error' },
  { value: 'pass', text: 'Pass', color: 'success' },
];

const projectTypeList = [
  { value: 'systematic_review', text: 'Systematic Review' },
  { value: 'non_systematic_review', text: 'Non-systematic Review' },
  { value: 'data_landscaping', text: 'Data Landscaping' },
];

const projectStatusList = [
  { value: 'new', text: 'Idle', color: null },
  { value: 'progress', text: 'Progress', color: 'primary' },
  { value: 'finished', text: 'Finished', color: 'success' },
  { value: 'blocked', text: 'Blocked', color: 'error' },
  { value: 'canceled', text: 'Canceled', color: 'grey' },
];

const markerList = [
  { text: 'Circle', value: 'circle', icon: 'mdi-circle-outline', },
  { text: 'Triangle', value: 'triangle', icon: 'mdi-triangle-outline', },
  { text: 'Square', value: 'square', icon: 'mdi-square-outline', },
];

const reviewedByList = [
  { value: 'pass by me', text: 'pass by me' },
  { value: 'pending by me', text: 'pending by me' },
  { value: 'halt by me', text: 'halt by me' },
  { value: 'skip by me', text: 'skip by me' },
  { value: 'reviewed by me', text: 'reviewed by me' },
  { value: 'not reviewed by me', text: 'not reviewed by me' },
  { value: 'reviewed by anyone', text: 'reviewed by anyone' },
  { value: 'not reviewed by anyone', text: 'not reviewed by anyone' },
  { value: 'reviewed by ai', text: 'reviewed by ai' },
  { value: 'not reviewed by ai', text: 'not reviewed by ai' },
  { value: 'reviewed by someone else', text: 'reviewed by someone else' },
  { value: 'not reviewed by someone else', text: 'not reviewed by someone else' },
  { value: 'reviewed by two or more people', text: 'reviewed by two or more people' },
  { value: 'not reviewed by two or more people', text: 'not reviewed by two or more people' },
  { value: 'reviewed by leader', text: 'reviewed by leader' },
  { value: 'not reviewed by leader', text: 'not reviewed by leader' },
  { value: 'reviewed by arbitrator', text: 'reviewed by arbitrator' },
  { value: 'not reviewed by arbitrator', text: 'not reviewed by arbitrator' },
  { value: 'reviewed by researcher', text: 'reviewed by researcher' },
  { value: 'not reviewed by researcher', text: 'not reviewed by researcher' },
  { value: 'reviewed by primary researcher', text: 'reviewed by primary researcher' },
  { value: 'not reviewed by primary researcher', text: 'not reviewed by primary researcher' },
  { value: 'reviewed by secondary researcher', text: 'reviewed by secondary researcher' },
  { value: 'not reviewed by secondary researcher', text: 'not reviewed by secondary researcher' },
];

const orderList = [
  { value: 'authors', text: 'Authors' },
  { value: 'abstract', text: 'Abstract' },
  { value: 'affiliations', text: 'Affiliations' },
  { value: 'doi', text: 'DOI' },
  { value: 'id', text: 'Record ID' },
  { value: 'pid', text: 'Project Record ID' },
  { value: 'references', text: 'Reference' },
  { value: 'screeningStatus', text: 'Screening Status' },
  { value: 'finalStatus', text: 'Data Extraction Status' },
  { value: 'indepthStatus', text: 'In-depth Status' },
  { value: 'language', text: 'Language' },
  { value: 'ovid', text: 'Ovid' },
  { value: 'RAND()', text: 'Random' },
  { value: 'title', text: 'Title' },
  { value: 'uid', text: 'Uid' },
  { value: 'url', text: 'URL' },
  { value: 'year', text: 'Year' }
];

const projectUserTypeList: Array<{
  value: string,
  text: string,
}> = [
  { value: 'leader', text: 'Leader' },
  { value: 'arbitrator', text: 'Arbitrator' },
  { value: 'researcher', text: 'Primary Researcher' },
  { value: 'secondary-researcher', text: 'Secondary Researcher' },
  { value: 'ai-manager', text: 'AI Manager' },
];

const keyedUserTypeList: {[key: string]: string} = {};
for (const userType of projectUserTypeList) {
  keyedUserTypeList[userType.value] = userType.text;
}
keyedUserTypeList.primary = 'Primary Researcher';
keyedUserTypeList.secondary = 'Secondary Researcher';

const gptModelList: Array<{
  value: string,
  description: string,
  text: string,
  replacement?: string,
}> = [
  {
    'value': 'gpt-4.5-preview',
    'text': 'GPT 4.5 Preview',
    'description': 'Preview version of GPT-4.5 offering enhanced features and performance improvements.'
  },
  {
    'value': 'gpt-4o',
    'text': 'GPT 4o',
    'description': 'Latest GPT-4 model with improved performance for complex tasks, including handling multimodal inputs such as text, audio, and vision.'
  },
  {
    'value': 'gpt-4o-mini',
    'text': 'GPT 4o Mini',
    'description': 'A smaller, more resource-efficient variant of the GPT 4o model with balanced performance and speed.'
  },
  {
    'value': 'o1',
    'text': 'o1',
    'description': 'A versatile model optimized for a range of natural language tasks, offering a good balance of quality and speed.'
  },
  {
    'value': 'o1-pro',
    'text': 'o1 Pro',
    'description': 'An advanced version of the O 1 model offering professional-level features and higher performance.'
  },
  {
    'value': 'o3-mini',
    'text': 'o3 Mini',
    'description': 'A miniaturized O 3 model that delivers solid performance in a smaller, more efficient package.'
  },
  {
    'value': 'o1-mini',
    'text': 'o1 Mini',
    'description': 'A streamlined, lower-resource alternative to O 1, suitable for use cases with efficiency constraints.'
  }
];

const gptBaseModelList: Array<{value: string, text:string}> = [
  { value: 'gpt-4', text: 'GPT 4' },
  { value: 'gpt-3.5-turbo', text: 'GPT 3.5 Turbo' },
];

const tutorialItems: Array<TutorialItem> = [
  // { type: 'general', key: 'general1', youtube: '5e-oScNiseE', label: 'General 1', body: 'Open wound scalp-compl', thumbnail: 'http://dummyimage.com/210x100.png/ff4444/ffffff', },
  { type: 'dev', key: 'dev1', youtube: '5e-oScNiseE', label: 'Developer 1', body: 'Open wound scalp-compl', thumbnail: 'http://dummyimage.com/210x100.png/ff4444/ffffff', },
]

const objectiveList = [
  { label: 'Main Objectives', key: 'mainObjective', },
  { label: 'Specific Objectives', key: 'specificObjective', },
];

const picotList = [
  { label: 'Population', key: 'picotPopulation', hint: 'Clearly define the patient characteristics, such as age range, gender, specific diagnosis and any relevant comorbidities. Example: "Adult patients aged 18-65 with a confirmed diagnosis of Relapsing-Remitting Multiple Sclerosis (RRMS), excluding patients with secondary progressive or primary progressive MS"', },
  { label: 'Intervention', key: 'picotIntervention', hint: 'Specify the type of interventions to be included, such as specific medications, therapeutic procedures, or lifestyle interventions. Example: "Interventions including first-line immunomodulatory drugs for MS, such as interferon-beta or glatiramer acetate" or "Any or no treatment"', },
  { label: 'Comparator', key: 'picotComparator', hint: 'Describe the comparators clearly, like standard care, placebo, another active treatment, or no treatment. Example: "Comparisons to be made against placebo, no treatment, or alternative therapeutic agents like fingolimod or natalizumab."', },
  { label: 'Outcomes', key: 'picotOutcomes', hint: 'List specific outcomes of interest, including primary outcomes (e.g., relapse rate, disability progression) and secondary outcomes (e.g., quality of life, adverse effects). Example: "Primary outcomes include relapse rate and MRI lesion activity. Secondary outcomes encompass patient-reported quality of life, fatigue levels, and adverse drug reactions."', },
  { label: 'Timing', key: 'picotTiming', hint: '', },
  { label: 'Setting', key: 'picotSetting', hint: 'Specify the setting or type of study design, like randomized controlled trials, observational studies, multicenter studies. Example: "Only randomized controlled trials and longitudinal observational studies conducted in clinical settings will be considered."', },
];

const criteriaList = [
  { label: 'Inclusion Criteria', key: 'inclusionCriteria', },
  { label: 'Exclusion Criteria', key: 'exclusionCriteria', },
];

// Sort lists
fieldList.sort((a, b) => a.text > b.text ? 1 : -1);
contactTypeList.sort((a, b) => a.text > b.text ? 1 : -1);
sourceTypeList.sort((a, b) => a.text > b.text ? 1 : -1);
recordFieldList.sort((a, b) => a.text > b.text ? 1 : -1);
taskActionList.sort((a, b) => a.text > b.text ? 1 : -1);
projectTypeList.sort((a, b) => a.text > b.text ? 1 : -1);
orderList.sort((a, b) => a.text > b.text ? 1 : -1);

export {
  contactTypeList,
  logicList,
  fieldList,
  taskStatusList,
  taskActionList,
  sourceTypeList,
  recordFieldList,
  recordRelatedFieldList,
  officialStatusFieldList,
  statusReasonFieldList,
  operatorList,
  orderList,
  reviewedByList,
  officialQueryStatusList,
  statusList,
  stageList,
  projectUserTypeList,
  keyedUserTypeList,
  projectTypeList,
  projectStatusList,
  gptModelList,
  gptBaseModelList,
  markerList,
  tutorialItems,
  TutorialItem,
  objectiveList,
  picotList,
  criteriaList,
  alphabetList,
};
