import AuthService, {GetView, RefreshView} from '@/modules/sdk/services/auth.service'
import store from 'store2'
import {AxiosResponse} from 'axios'
import {ZemitData} from './service'

export type NestedArrayOr<T> = T | Array<NestedArrayOr<T>>;

export default class Identity
{
  static identity?: GetView
  static refreshResponse: null | Promise<AxiosResponse<ZemitData<RefreshView>>> = null

  static setIdentity(identity: GetView) {
    this.identity = identity
    store.local.set('identity', this.identity)
    return this.identity
  }

  static getIdentity() {
    if (!this.identity) {
      this.identity = store.local.get('identity', undefined)
    }
    return this.identity
  }

  static getCurrentUserId(): number | null {
    return this.getIdentity()?.user?.id;
  }

  static removeIdentity() {
    delete this.identity
    store.local.remove('identity')
  }

  /**
   * Check if identity is currently logged in
   */
  static isLoggedIn() {
    return this.getIdentity()?.loggedIn
  }

  /**
   *
   * @param roleList
   * @param or
   * @param inherit
   */
  static hasRole(roleList: NestedArrayOr<string> = [], or = false, inherit = true) {
    const filteredRoleList = (Array.isArray(roleList) ? roleList : [roleList]).filter(role => !Identity.getIdentity()?.loggedInAs || role !== 'dev')
    return this.has(filteredRoleList, Object.keys(this.identity?.roleList || {}), or)
  }

  /**
   * Check if the needles meet the haystack using nested arrays
   * Reversing ANDs and ORs within each nested subarray
   *
   * @param needles
   * @param haystack
   * @param or
   */
  static has(needles: NestedArrayOr<string> = [], haystack: Array<string> = [], or = false): boolean {
    if (!Array.isArray(needles)) {
      needles = [needles]
    }

    const result: Array<boolean> = []
    for (const needle of [...needles]) {
      if (Array.isArray(needle)) {
        result.push(this.has(needle, haystack, !or))
      }
      else {
        result.push(haystack.includes(needle))
      }
    }

    return or
           ? !result.includes(false)
           : result.includes(true)
  }

  static hasPermission() {

  }

  static refreshPromise = () => {
    if (!Identity.refreshResponse) {
      const refreshToken = Identity.getIdentity()?.refreshToken
      Identity.refreshResponse = new Promise((resolve, reject) =>
        AuthService.getInstance().refresh({refreshToken})
          .then(response => {
            Identity.setIdentity({
              ...Identity.getIdentity(),
              ...response.data.view,
            })
            resolve(response)
          })
          .catch(reason => reject(reason))
          .finally(() => Identity.refreshResponse = null)
      )
    }
    return Identity.refreshResponse
  }
}
